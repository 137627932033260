<template>
    <loading-overlay v-if="!loaded" :state="!loaded"></loading-overlay>
    <b-container v-else class="pb-5">
        <h1 class="page-title mb-3 text-left">
            My Transactions
        </h1>

        <el-table
            class="pt-4"
            :data="tableData"
            :default-sort="{ prop: 'firstName' }"
            stripe
        >
            <el-table-column prop="createdOn" label="Created on" width="300" sortable />
            <el-table-column prop="expiresOn" label="Expires on" width="300" sortable />
            <el-table-column
                prop="remainingDays"
                label="Remaining days"
                width="300"
                sortable
            />
            <el-table-column prop="price" label="Price" width="150" />
            <el-table-column prop="type" label="Type" width="150" />
        </el-table>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { Table, TableColumn, Switch, Input } from 'element-ui'
import moment from 'moment'
import UserService from '@serv/UserService'
import LoadingOverlay from '@comp/LoadingOverlay'

export default {
    name: 'Transactions',
    metaInfo: {
        title: 'Transactions list'
    },
    components: {
        LoadingOverlay,
        [Input.name]: Input,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Switch.name]: Switch
    },
    data() {
        return {
            loaded: false,
            tableData: [],
            search: ''
        }
    },
    props: {},
    computed: {
        ...mapGetters(['user', 'plans'])
    },
    methods: {
        formatDate(date) {
            return moment(date).format('dddd, MMMM Do YYYY')
        },
        calculateDateDifference(dateEnd) {
            let today = moment()
            let expired = moment(dateEnd)
            return today.diff(expired, 'days')
        }
    },
    beforeMount() {
        UserService.getUserProducts(this.user.id).then(res => {
            let results = res
            results.forEach(transaction => {
                const expired = transaction.expiresOn
                const remainingDays = this.calculateDateDifference(expired)

                transaction.createdOn = this.formatDate(transaction.createdOn)
                transaction.expiresOn = this.formatDate(transaction.expiresOn)
                transaction.remainingDays =
                    remainingDays >= 0 ? 'Expired' : Math.abs(remainingDays)

                transaction.price = `$ ${this.plans.find(
                    s => s.name == transaction.type
                ).price.unitAmount / 100} `
            })

            this.tableData = results
            this.loaded = true
        })
    },
    mounted() {
        UserService.getUserProducts(this.user.id)
        this.$bus.$on('api-request', payload => {
            this.loaded = !payload
        })
    },
    watch: {}
}
</script>

<style lang="scss">
.list-container {
    .list {
        padding: 30px 0;

        .container {
            max-width: 1130px;
        }
    }

    @media screen and (min-width: 860px) {
        .list {
            padding: 45px 0 30px;
        }
    }

    @media screen and (min-width: 1200px) {
        .list {
            padding: 65px 0 45px;
        }
    }
}
</style>
