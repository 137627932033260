import SubcategorySearchItem from '@models/SubcategorySearchItem'
import Category from '@models/Category'
import axios from '@serv/AxiosService'
import store from '@store'

class CategoryService {
    getCategories() {
        return axios
            .get(`/workforce/category/`)
            .then(res => {
                var data = res.data.map(cat => new Category(cat))

                store.commit('setJobTypes', data)
                store.commit('setWorkforceTypes', data)
                store.commit('setCategories', data)
                return data
            })
            .catch(error => {
                throw error
            })
    }
    getSubcategoryOf(categoryId) {
        return axios
            .get(`/workforce/category/${categoryId}/subcategories/`)
            .then(res => {
                var data = res.data
                    .map(cat => new Category(cat))
                    .sort((a, b) => a.name.localeCompare(b.name))
                return data
            })
            .catch(error => {
                throw error
            })
    }
    uploadCategories(categoriesFile) {
        //If is a File Object, then send it.
        if (!categoriesFile || !(categoriesFile instanceof File)) {
            return Promise.reject(false)
        } else {
            var url = `workforce/upload-categories/`
            var formData = new FormData()
            formData.append('categoriesFile', categoriesFile)
            return axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => res.data)
                .catch(error => {
                    throw error
                })
        }
    }
    searchCategory(searchQuery, controllerSignal) {
        var name = searchQuery.toLowerCase().trim()
        return axios
            .get(`/workforce/search-category/`, {
                signal: controllerSignal,
                params: { name: name }
            })
            .then(res => {
                var data = res.data
                    .map(cat => new SubcategorySearchItem(cat))
                    .sort((a, b) => {
                        var aName = a.name.toLowerCase()
                        var bName = b.name.toLowerCase()
                        var aSearchPosition = aName.indexOf(name)
                        var bSearchPosition = bName.indexOf(name)
                        if (aSearchPosition !== bSearchPosition) {
                            return aSearchPosition - bSearchPosition
                        }
                        return aName.localeCompare(bName)
                    })
                return data
            })
            .catch(error => {
                if (controllerSignal.aborted) {
                    throw {
                        abortedRequest: true,
                        message: 'Search request aborted to start a new one.'
                    }
                } else {
                    throw error
                }
            })
    }
    deleteCategory(categoryId, isSubcategory = false) {
        if (!isSubcategory) {
            return axios.delete(`/workforce/category/${categoryId}/`).catch(error => {
                throw error
            })
        } else {
            return axios
                .delete(`/workforce/subcategory/${categoryId}/`)
                .catch(error => {
                    throw error
                })
        }
    }
    addCategory(data) {
        return axios
            .post(`/workforce/category/`, data)
            .then(res => new Category(res.data))
            .catch(error => {
                throw error
            })
    }
    addSubcategory(data) {
        return axios
            .post(`/workforce/subcategory/`, data)
            .then(res => new Category(res.data))
            .catch(error => {
                throw error
            })
    }
    updateCategory(data) {
        var categoryId = data.id
        var isSubcategory = !!data.parent
        if (!isSubcategory) {
            return axios
                .patch(`/workforce/category/${categoryId}/`, data)
                .catch(error => {
                    throw error
                })
        } else {
            return axios
                .patch(`/workforce/subcategory/${categoryId}/`, data)
                .catch(error => {
                    throw error
                })
        }
    }
}
export default new CategoryService()
