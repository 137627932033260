<template>
    <div class="schedule-container" v-if="ready">
        <b-list-group class="py-3 px-1 shadow" v-if="form.length">
            <b-list-group-item class="mt-md-1 border-top-0 border-end-0 border-start-0">
                <strong>{{ label }}</strong>
            </b-list-group-item>
            <b-form-group
                v-for="(day, i) in form"
                :key="i"
                label-class="pb-0 mb-2 mb-sm-0 fw-bold"
                class="list-group-item border-end-0 border-start-0 d-flex align-items-center justify-content-between time-group"
                :id="`times-available-input-group-${i}`"
                :class="{
                    'mb-md-1 border-bottom-0': form.length - 1 === i,
                    'view-mode': view
                }"
                :label="form[i].weekday"
            >
                <div
                    v-if="form[i].open"
                    class="d-flex align-items-center time-group-inner position-relative w-100"
                    :class="{ 'pe-4': !view }"
                >
                    <el-select
                        v-model="form[i].startTime"
                        class="mx-0"
                        :disabled="view"
                        :placeholder="view ? '' : 'Start time'"
                        size="small"
                    >
                        <el-option
                            v-for="item in timeIntervalOptions()"
                            :key="`startTimeInterval_${i}_${item.value}`"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                    <span v-if="!view" class="mx-1">-</span>
                    <el-select
                        v-model="form[i].endTime"
                        class="mx-0"
                        :disabled="view"
                        :placeholder="view ? '' : 'End time'"
                        size="small"
                    >
                        <el-option
                            v-for="item in timeIntervalOptions(form[i].startTime)"
                            :key="`endTimeInterval_${i}_${item.value}`"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"
                        />
                    </el-select>
                    <b-button
                        v-if="form[i].open && !view"
                        @click="
                            ;(form[i].open = false),
                                (form[i].startTime = undefined),
                                (form[i].endTime = undefined)
                        "
                        variant="link"
                        class="p-0 text-danger position-absolute end-0 shadow-none close"
                    >
                        <b-icon icon="x"></b-icon>
                    </b-button>
                </div>

                <b-button
                    v-if="!form[i].open && !view"
                    @click="form[i].open = true"
                    variant="link"
                    class="w-100 p-0 text-info text-end shadow-none"
                    >Add Hours</b-button
                >
            </b-form-group>
        </b-list-group>
    </div>
</template>

<script>
import { Select, Option } from 'element-ui'
export default {
    name: 'Schedule',
    components: {
        [Select.name]: Select,
        [Option.name]: Option
    },
    data() {
        return {
            days: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            ready: false,
            form: []
        }
    },
    props: {
        label: {
            type: String,
            default: 'Times Available'
        },
        value: {
            type: [Array],
            description: 'Input value'
        },
        view: {
            type: Boolean
        },
        edit: {
            type: Boolean
        },
        create: {
            type: Boolean
        }
    },
    methods: {
        buildView() {
            this.days.forEach(day => {
                const val = this.value.find(
                    ({ weekday }) => day.toLowerCase() === weekday.toLowerCase()
                )
                if (val && this.view) {
                    if (val.startTime !== null && val.endTime && val.open) {
                        this.form.push(val)
                    }
                } else {
                    if (val) {
                        this.form.push(val)
                    }
                }
            })
        },
        timeIntervalOptions(minTime = '00:00') {
            if (!minTime || minTime === null) {
                minTime = '00:00'
            }
            var options = []
            function getZeroedNumberText(n) {
                return n < 10 ? `0${n}` : `${n}`
            }
            function parseTimeText(timeText) {
                const time = timeText.split(':')
                let now = new Date()
                return new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate(),
                    ...time
                )
            }
            function isGEQTime(timeTextA, timeTextB) {
                const timeA = parseTimeText(timeTextA)
                const timeB = parseTimeText(timeTextB)
                return timeA >= timeB
            }
            for (let i = 0; i < 24; i++) {
                const hour = i <= 12 ? i : i % 12
                const hourText = getZeroedNumberText(i === 0 ? 12 : hour)
                const fullHourText = getZeroedNumberText(i)
                const period = i < 12 ? 'AM' : 'PM'
                const startFullHourText = `${fullHourText}:00`
                const halfFullHourText = `${fullHourText}:30`
                var startHourOption = {
                    label: `${hourText}:00 ${period}`,
                    value: startFullHourText,
                    disabled: isGEQTime(minTime, startFullHourText)
                }
                var halfHourOption = {
                    label: `${hourText}:30 ${period}`,
                    value: halfFullHourText,
                    disabled: isGEQTime(minTime, halfFullHourText)
                }
                options.push(startHourOption, halfHourOption)
            }
            return options
        }
    },
    mounted() {
        if (this.create) {
            this.days.forEach((day, i) => {
                this.$set(this.form, i, {
                    open: true,
                    weekday: day,
                    startTime: '',
                    endTime: ''
                })
            })
        }
        if (this.edit || this.view) {
            this.buildView()
        }
        this.ready = true
    },
    watch: {
        form() {
            if (this.create || this.edit) {
                this.$emit('input', this.form)
            }
        },
        value() {
            if (this.view) {
                this.buildView()
            }
        }
    }
}
</script>
<style lang="scss" scope>
.time-group {
    &-inner {
        margin-left: auto;
    }
    > div {
        width: 100%;
    }
    .time-group-inner {
        max-width: 235px;
    }
    legend {
        max-width: max-content;
    }

    .el-input__inner {
        max-width: 95px;
        height: auto;
        padding: 5px 0 !important;
        line-height: 1.2;
        text-align: center;
    }
    .el-input__prefix {
        display: none;
    }
    .el-input__suffix {
        display: none;
    }
    .col-form-label {
        font-family: inherit !important;
        font-size: inherit !important;
    }
    .el-date-editor.el-input {
        width: 95px;
    }

    &.view-mode {
        .el-input__inner {
            color: inherit !important;
            background-color: transparent !important;
            padding: 0 !important;
            border: 0;
            font-size: 1rem;
        }

        .el-date-editor.el-input {
            width: 65px;
        }
    }

    @media screen and (max-width: 420px) {
        &-inner,
        .el-input__inner,
        .el-date-editor.el-input {
            width: 100%;
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 420px) {
    .list-group-item {
        display: block !important;
    }
}
</style>
