import axios from '@serv/AxiosService'
import store from '@store'

class PreferedAreaService {
    createPreferedArea(model) {
        var data = { ...model }
        return axios({
            method: 'post',
            url: `workforce/prefered-area/`,
            data: data
        })
            .then(res => {
                var data = res.data
                store.commit('pushPreferedArea', data)
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    getPreferedArea() {
        return axios({
            method: 'get',
            url: `workforce/prefered-area/`
        })
            .then(res => {
                var data = res.data
                store.commit('setPreferedArea', data)
                return data
            })
            .catch(error => {
                throw error
            })
    }
}

export default new PreferedAreaService()
