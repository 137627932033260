<template>
    <b-container class="upload-categories-container mb-5">
        <b-row>
            <b-col cols="12 d-flex justify-content-md-between mb-4 ">
                <h3 class="h4 text-gray-1000 fw-bold">Upload Categories</h3>
                <button class="btn btn-primary" @click="openAddCategory">
                    Add Category
                </button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <upload
                    class="upload-resume"
                    :auto-upload="false"
                    :on-change="onChange"
                    :show-file-list="false"
                    action=""
                    accept="text/csv"
                    ref="csv-uploader"
                    drag
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        Drop file here or <em>Click to upload</em>
                    </div>
                    <div slot="tip" class="el-upload__tip">
                        Accepted file types: .csv
                    </div>
                </upload>
                <b-button
                    v-if="this.innerValue"
                    :disabled="!this.innerValue || this.disabled"
                    class="btn-primary mt-3 py-3 text-capitalize w-100"
                    @click="upload"
                    block
                    >{{ this.uploading ? 'Uploading' : 'Upload' }}
                    {{ fileName }}</b-button
                >
            </b-col>
            <b-col cols="12">
                <el-input
                    class="mt-4"
                    placeholder="Search categories by name"
                    type="search"
                    v-model="search"
                />
                <h6 class="mt-4" v-if="currentTableDiff.added.length">
                    <b>
                        {{ currentTableDiff.added.length }} new
                        {{
                            currentTableDiff.added.length > 1
                                ? 'categories'
                                : 'category'
                        }}
                        added successfully
                    </b>
                </h6>
                <div>
                    <b-overlay
                        :show="isLoadingCategories || isSearchingCategories"
                        class="align-items-center"
                        rounded="sm"
                        spinner-type="grow"
                        spinner-small
                    >
                        <el-table
                            class="pt-4 category-table"
                            row-key="id"
                            :row-class-name="rowClassName"
                            @row-click="expandRow"
                            :expand-row-keys="expandRowKeys"
                            @expand-change="handleExpandChange"
                            :data="categoryPage"
                            :default-sort="{ prop: 'name' }"
                            ref="categoriesTable"
                            :cell-class-name="cellClassName"
                            stripe
                        >
                            <el-table-column type="expand">
                                <template slot-scope="props">
                                    <el-tree
                                        node-key="id"
                                        v-if="props.row.subcategories"
                                        :data="props.row.subcategories"
                                        :props="{
                                            label: 'name',
                                            children: 'subcategories'
                                        }"
                                        :expand-on-click-node="false"
                                    >
                                        <div
                                            class="subcategory-item py-2 px-4 d-flex align-items-lg-center flex-lg-row flex-column flex-wrap flex-lg-nowrap justify-content-lg-between w-100"
                                            slot-scope="{ node, data }"
                                        >
                                            <div
                                                class="edit-form-control-container edit-subcategory me-lg-3"
                                                v-if="data.editing"
                                            >
                                                <t-input
                                                    alternative
                                                    class="edit-category"
                                                    label=""
                                                    placeholder="Enter Category Name"
                                                    type="text"
                                                    @keyup.enter="
                                                        () => updateCategoryName(data)
                                                    "
                                                    :ref="`edit_category_${data.id}`"
                                                    :input-ref="
                                                        `edit_category_input_${data.id}`
                                                    "
                                                    v-model="currentEditText"
                                                />
                                                <a
                                                    v-if="hasEditText"
                                                    href="javascript:;"
                                                    @click="clearEdit(data)"
                                                    class="clear-edit"
                                                >
                                                    <img
                                                        :src="
                                                            require('@assets/svg/times.svg')
                                                        "
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div v-if="!data.editing">
                                                <span
                                                    v-if="inSearchView()"
                                                    v-html="highlight(node.label)"
                                                ></span>
                                                <span v-else>
                                                    {{ node.label }}
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex align-items-center mt-lg-0 mt-2"
                                            >
                                                <b-button
                                                    v-if="!data.editing"
                                                    class="me-2"
                                                    variant="outline-primary"
                                                    size="mini"
                                                    @click="
                                                        () => callAction('Edit', data)
                                                    "
                                                >
                                                    <b-icon
                                                        icon="pencil-square"
                                                        class="me-1"
                                                    ></b-icon
                                                    >Edit
                                                </b-button>
                                                <b-button
                                                    v-else
                                                    class="me-2"
                                                    variant="primary"
                                                    size="mini"
                                                    :disabled="!hasEditText"
                                                    @click="
                                                        () => updateCategoryName(data)
                                                    "
                                                >
                                                    <b-icon
                                                        icon="check-circle"
                                                        class="me-1"
                                                    ></b-icon
                                                    >Save
                                                </b-button>
                                                <b-button
                                                    v-if="data.editing"
                                                    class="m-0"
                                                    variant="outline-primary"
                                                    size="mini"
                                                    @click="() => cancelEdit(data)"
                                                >
                                                    <b-icon
                                                        icon="x-circle"
                                                        class="me-1"
                                                    ></b-icon
                                                    >Cancel
                                                </b-button>

                                                <b-button
                                                    v-if="!data.editing"
                                                    class="m-0"
                                                    variant="danger"
                                                    size="mini"
                                                    @click="
                                                        () => callAction('Delete', data)
                                                    "
                                                >
                                                    <b-icon
                                                        icon="trash"
                                                        class="me-1"
                                                    ></b-icon
                                                    >Delete
                                                </b-button>
                                            </div>
                                        </div>
                                    </el-tree>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="Category Name"
                                sortable
                                :width="665"
                            >
                                <template slot-scope="props">
                                    <span class="title">
                                        <div
                                            class="edit-form-control-container"
                                            v-if="props.row.editing"
                                        >
                                            <t-input
                                                alternative
                                                class="edit-category"
                                                label=""
                                                placeholder="Enter Category Name"
                                                type="text"
                                                @keyup.enter="
                                                    () => updateCategoryName(props.row)
                                                "
                                                :ref="`edit_category_${props.row.id}`"
                                                :input-ref="
                                                    `edit_category_input_${props.row.id}`
                                                "
                                                v-model="currentEditText"
                                            />
                                            <a
                                                v-if="hasEditText"
                                                href="javascript:;"
                                                @click="clearEdit(props.row)"
                                                class="clear-edit"
                                            >
                                                <img
                                                    :src="
                                                        require('@assets/svg/times.svg')
                                                    "
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                        <span v-else>
                                            <b
                                                v-if="inSearchView()"
                                                v-html="highlight(props.row.name)"
                                            ></b>
                                            <b v-else>{{ props.row.name }}</b>
                                            <span
                                                span
                                                class="sub-title"
                                                v-if="isNewCategory(props.row)"
                                            >
                                                (new)</span
                                            >
                                            <span
                                                class="sub-title"
                                                v-if="props.row.loading"
                                            >
                                                - loading subcategories...</span
                                            >
                                            <span
                                                class="sub-title"
                                                v-if="
                                                    props.row.loaded &&
                                                        props.row.subcategories
                                                            .length &&
                                                        !props.row.loading
                                                "
                                            >
                                                -
                                                {{ props.row.subcategories.length }}
                                                subcategories
                                                <span v-if="inSearchView()">
                                                    matching the search</span
                                                ></span
                                            >
                                            <span
                                                class="sub-title"
                                                v-if="
                                                    props.row.loaded &&
                                                        !props.row.subcategories
                                                            .length &&
                                                        !props.row.loading
                                                "
                                            >
                                                - No subcategories to show</span
                                            >
                                        </span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Actions">
                                <template slot-scope="scope">
                                    <b-button
                                        variant="outline-primary"
                                        v-if="!scope.row.editing"
                                        class="me-2 category-list-action"
                                        @click="callAction('Edit', scope.row)"
                                    >
                                        <b-icon
                                            icon="pencil-square"
                                            class="me-1"
                                        ></b-icon>
                                        Edit</b-button
                                    >
                                    <b-button
                                        variant="primary"
                                        v-else
                                        class="me-2 category-list-action"
                                        @click="updateCategoryName(scope.row)"
                                        :disabled="!hasEditText"
                                    >
                                        <b-icon
                                            icon="check-circle"
                                            class="me-1"
                                        ></b-icon>
                                        Save
                                    </b-button>
                                    <b-button
                                        variant="outline-primary"
                                        v-if="scope.row.editing"
                                        class="m-0 category-list-action"
                                        @click="cancelEdit(scope.row)"
                                        ><b-icon icon="x-circle" class="me-1"></b-icon
                                        >Cancel</b-button
                                    >
                                    <b-button
                                        v-else
                                        variant="danger"
                                        class="category-list-action m-0"
                                        @click="callAction('Delete', scope.row)"
                                        ><b-icon icon="trash" class="me-1"></b-icon
                                        >Delete</b-button
                                    >
                                </template>
                            </el-table-column>
                        </el-table>

                        <div
                            class="d-flex justify-content-center w-100 mt-3"
                            v-if="totalItemsCount > pageSize"
                        >
                            <el-pagination
                                small
                                :page-size="pageSize"
                                :pager-count="5"
                                layout="prev, pager, next"
                                @current-change="onCurrentPageChange"
                                :total="totalItemsCount"
                            >
                            </el-pagination>
                        </div>
                    </b-overlay>
                </div>
                <b-modal
                    size="lg"
                    class="centered"
                    id="add-category-modal"
                    return-focus="body"
                    :ok-disabled="!canSubmitAddCategory"
                    @ok.prevent="onAddCategoryFormSubmit"
                    ok-title="Save"
                >
                    <template #modal-title>Add Category</template>
                    <add-category-form
                        ref="addCategoryForm"
                        @submit-success="onAddCategorySubmitSuccess"
                        @validated-form="onAddCategoryValidated"
                    ></add-category-form>
                </b-modal>
                <b-modal
                    size="lg"
                    class="centered"
                    id="delete-category-modal"
                    return-focus="body"
                    @ok="deleteCategory()"
                    ok-title="Delete"
                >
                    <template #modal-title>Delete Category?</template>
                    <div v-if="currentCategory">
                        <p class="mb-3" style="line-height: 1.5;">
                            Are you sure you want to delete
                            <b>{{ currentCategory.name }}</b>
                            {{ categoryOrSubcategoryText }}?
                        </p>
                        <p
                            class="mb-3"
                            style="line-height: 1.5;"
                            v-if="!currentCategory.parent"
                        >
                            <b>{{ currentCategory.name }}</b> subcategories will also be
                            deleted.
                        </p>
                        <p style="line-height: 1.5;">
                            <b class="text-uppercase">This action cannot be undone.</b>
                        </p>
                    </div>
                </b-modal>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { Upload, MessageBox } from 'element-ui'
import NotifyService from '@serv/NotifyService'
import { debounce } from '@util/helpers'
import CategoryService from '@serv/CategoryService'
import { Table, TableColumn, Input, Pagination, Tree, Button } from 'element-ui'
import { highlight } from '@util/helpers'
import Vue from 'vue'
import AddCategoryForm from './AddCategoryForm.vue'

export default {
    name: 'CategoryImporterView',
    data() {
        return {
            innerValue: undefined,
            disabled: true,
            uploading: false,
            currentCategory: undefined,
            currentEditText: '',
            search: '',
            currentPage: 1,
            pageSize: 100,
            isLoadingCategories: false,
            isSearchingCategories: false,
            categorySearchResults: [],
            expandRowKeys: [],
            categoriesTable: [],
            filteredTable: [],
            currentTableDiff: {
                added: [],
                removed: []
            },
            canSubmitAddCategory: false
        }
    },
    components: {
        Upload,
        [Button.name]: Button,
        [Tree.name]: Tree,
        [Input.name]: Input,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Pagination.name]: Pagination,
        AddCategoryForm
    },
    computed: {
        ...mapGetters(['categories']),
        fileName() {
            if (!this.innerValue) {
                return ''
            }
            return this.innerValue.name
        },
        totalItemsCount() {
            var count
            if (this.inSearchView()) {
                count = this.categorySearchResults.length
            } else {
                count = this.categoriesTable.length
            }
            return count
        },
        categoryPage() {
            var data
            if (this.inSearchView()) {
                data = this.categorySearchResults
            } else {
                data = this.categoriesTable
            }
            var page = this.getPage(this.currentPage, data)
            //When the last item from the current page is deleted,
            //go to the previous page.
            if (page.length === 0 && this.currentPage > 1) {
                page = this.getPage(this.currentPage - 1, data)
            }
            return page
        },
        hasEditText() {
            return !!this.currentEditText.trim().length
        },
        hasSearchText() {
            return !!this.search.trim().length
        },
        cleanSearch() {
            return this.search.trim()
        },
        categoryOrSubcategoryText() {
            return this.currentCategory.parent ? 'subcategory' : 'category'
        }
    },
    methods: {
        onChange(file) {
            if (0 > file.raw.type.indexOf('text/csv')) {
                NotifyService.notify(this, 'Invalid file type', 'danger')
                this.handleRemove(file, this.innerValue)
            } else {
                this.innerValue = file.raw
                this.disabled = false
                this.$emit('input', this.innerValue)
            }
        },
        searchCategories() {
            if (!this.cleanSearch) {
                return
            }
            this.debaunceCategorySearch(this.cleanSearch)
        },
        upload() {
            if (!this.innerValue) {
                NotifyService.notify(this, 'Not file to upload', 'danger')
                return
            }
            this.disabled = true
            this.uploading = true
            CategoryService.uploadCategories(this.innerValue)
                .then(() => {
                    NotifyService.notify(this, 'File successfully uploaded.', 'success')
                    this.updateTable()
                })
                .catch(() => {
                    NotifyService.notify(
                        this,
                        `We could not upload the file successfully.`,
                        'danger'
                    )
                })
                .finally(() => {
                    this.disabled = false
                    this.uploading = false
                    this.innerValue = undefined
                })
        },
        handleRemove(file) {
            MessageBox.confirm(`Cancel the upload of this file? ${file.name}`).then(
                rs => {
                    if ('confirm' !== rs) return
                    ;(this.innerValue = undefined), this.$emit('input', this.innerValue)
                }
            )
        },
        rowClassName() {
            return 'expandable d-lg-table-row flex-wrap d-flex'
        },
        cellClassName() {
            var clname = 'd-lg-table-cell d-block'
            return clname
        },
        getTableDiff(table1, table2) {
            function getDiff(array1, array2) {
                return array1.filter(object1 => {
                    return !array2.some(object2 => {
                        return object1.id === object2.id
                    })
                })
            }
            return {
                added: getDiff(table2, table1),
                removed: getDiff(table1, table2)
            }
        },
        handleExpandChange(row) {
            const id = row.id
            if (!this.inSearchView()) {
                const lastId = this.expandRowKeys[0]
                this.expandRowKeys = id === lastId ? [] : [id]
            } else {
                var expanded = this.expandRowKeys.includes(id)
                if (expanded) {
                    this.expandRowKeys = this.expandRowKeys.filter(
                        rowId => id !== rowId
                    )
                } else {
                    this.expandRowKeys.push(id)
                }
            }
        },
        isNewCategory(category) {
            return this.currentTableDiff.added.find(({ id }) => category.id === id)
        },
        /*
        findCategoryById(categoryId) {
            function find(list, categoryId) {
                var category = list.find(category => category.id === categoryId)
                if (category) {
                    return category
                } else if (list.subcategories && list.subcategories.length) {
                    return find(categoryId, list.subcategories)
                }
            }
            return find(this.categoriesTable, categoryId)
        },
        */
        updateTable(closeRows = false) {
            this.isLoadingCategories = true
            CategoryService.getCategories().finally(() => {
                this.isLoadingCategories = false
                if (closeRows) {
                    this.expandRowKeys = []
                }
            })
        },
        expandRow(row, column, e) {
            e.preventDefault()
            e.stopPropagation()
            if (e.target.tagName.toLowerCase() !== 'td' || column.label === 'Actions') {
                return
            }
            var subcategories = row.subcategories
            if (row.loading) {
                return
            }
            if (!subcategories.length && !row.loaded) {
                row.loading = true
                CategoryService.getSubcategoryOf(row.id)
                    .then(subcategories => {
                        row.subcategories = subcategories.map(subcategory => {
                            subcategory.editing = false
                            return subcategory
                        })
                    })
                    .finally(() => {
                        row.loading = false
                        row.loaded = true
                        if (row.subcategories.length) {
                            this.$refs.categoriesTable.toggleRowExpansion(row)
                        }
                    })
            } else {
                this.$refs.categoriesTable.toggleRowExpansion(row)
            }
        },
        deleteCategory() {
            const category = this.currentCategory
            var deletedWhatText = this.categoryOrSubcategoryText
            CategoryService.deleteCategory(category.id, !!category.parent)
                .then(() => {
                    NotifyService.notify(
                        this,
                        `The ${deletedWhatText} deleted successfully.`,
                        'success'
                    )
                    if (!category.parent) {
                        this.updateTable()
                    } else {
                        var tables = [this.categoriesTable]
                        if (this.inSearchView() && this.categorySearchResults.length) {
                            tables.push(this.categorySearchResults)
                        }
                        tables.forEach(table => {
                            const index = table.findIndex(
                                ({ id }) => category.parent === id
                            )
                            const parent = table[index]
                            parent.subcategories = parent.subcategories.filter(
                                ({ id }) => id !== category.id
                            )
                            Vue.set(table, index, parent)
                        })
                    }
                })
                .catch(() => {
                    NotifyService.notify(
                        this,
                        'The category could not be deleted.',
                        'danger'
                    )
                })
            this.currentCategory = undefined
        },
        callAction(action, category) {
            var lastCategory = this.currentCategory
            this.currentCategory = category
            if (action === 'Edit') {
                if (lastCategory) {
                    lastCategory.editing = false
                }
                this.currentCategory.editing = true
                this.currentEditText = this.currentCategory.name
                this.$nextTick(() => {
                    this.$refs[`edit_category_${this.currentCategory.id}`].$refs[
                        `edit_category_input_${this.currentCategory.id}`
                    ].focus()
                })
            } else if (action === 'Delete') {
                this.$bvModal.show('delete-category-modal')
            }
        },
        getPage(page, data) {
            var offset = (page - 1) * this.pageSize
            var limit = page * this.pageSize
            var pageItems = data.slice(offset, limit)
            this.currentPage = page
            return pageItems
        },
        onCurrentPageChange(currentPage) {
            this.currentPage = currentPage
        },
        highlight(text) {
            return highlight(text, this.cleanSearch)
        },
        updateCategoryName() {
            var name = this.currentEditText.trim()
            var category = this.currentCategory
            category.editing = false

            if (name === category.name) {
                return
            }

            var updatedWhatText = this.categoryOrSubcategoryText

            var oldName = category.name
            category.name = name

            CategoryService.updateCategory(category)
                .then(() => {
                    NotifyService.notify(
                        this,
                        `The ${updatedWhatText} was successfully updated.`,
                        'success'
                    )
                })
                .catch(() => {
                    category.name = oldName
                    NotifyService.notify(
                        this,
                        `A ${updatedWhatText} with this name already exists.`,
                        'danger'
                    )
                })

            this.currentCategory = undefined
        },
        clearEdit() {
            this.currentEditText = ''
        },
        cancelEdit(category) {
            category.editing = false
            this.clearEdit()
        },
        categoryListToTable(categoriesList) {
            if (!categoriesList.length) {
                return []
            }
            var tableCategories = categoriesList.reduce((table, category) => {
                const { parent, parentName } = category
                if (!table.has(parent)) {
                    table.set(parent, {
                        id: parent,
                        name: parentName,
                        loaded: true,
                        loading: false,
                        editing: false,
                        subcategories: []
                    })
                }
                table.get(parent).subcategories.push({
                    ...category,
                    editing: false
                })
                return table
            }, new Map())

            return Array.from(tableCategories.values())
        },
        inSearchView() {
            return !!this.search.trim().length && !this.isSearchingCategories
        },
        openAddCategory() {
            this.$bvModal.show('add-category-modal')
        },
        onAddCategoryValidated(isValid) {
            this.canSubmitAddCategory = isValid
        },
        onAddCategorySubmitSuccess() {
            this.$bvModal.hide('add-category-modal')
        },
        onAddCategoryFormSubmit() {
            this.$refs.addCategoryForm.submit().then(() => {
                this.updateTable(true)
            })
        }
    },
    created() {
        this.categorySearchAbortController = undefined
        this.debaunceCategorySearch = debounce(value => {
            this.isSearchingCategories = true
            if (this.categorySearchAbortController !== undefined) {
                this.categorySearchAbortController.abort()
            }
            var abortController = new AbortController()
            this.categorySearchAbortController = abortController
            return CategoryService.searchCategory(value, abortController.signal)
                .then(results => {
                    this.categorySearchResults = this.categoryListToTable(results)
                    this.expandRowKeys = this.categorySearchResults.map(({ id }) => id)
                    this.isSearchingCategories = false
                })
                .catch(error => {
                    if (!error.abortedRequest) {
                        this.isSearchingCategories = false
                    }
                })
        })
    },
    watch: {
        cleanSearch(newSearch) {
            if (newSearch && newSearch.length > 2) {
                this.$refs.categoriesTable.clearSort()
                this.searchCategories()
            } else if (!newSearch) {
                this.$refs.categoriesTable.sort({ prop: 'name' })
                this.categorySearchResults = []
                this.expandRowKeys = []
            }
        },
        categories: {
            immediate: true,
            handler(newTable, oldTable) {
                if (oldTable) {
                    this.currentTableDiff = this.getTableDiff(oldTable, newTable)
                }
                this.categoriesTable = newTable.map(cat => {
                    return {
                        ...cat,
                        loaded: false,
                        loading: false,
                        editing: false,
                        subcategories: []
                    }
                })
                if (this.hasSearchText) {
                    this.searchCategories()
                }
                var added = this.currentTableDiff.added
                if (added.length) {
                    var message = `${added.length} new`
                    message += added.length > 1 ? ' categories' : ' category'
                    message += ' added successfully'
                    NotifyService.notify(this, message, 'success')
                }
            }
        }
    }
}
</script>
<style lang="scss">
@media screen and (max-width: 991px) {
    .upload-categories-container .el-table__header,
    .upload-categories-container .el-table__body {
        width: 100% !important;
    }
    .upload-categories-container colgroup {
        display: flex;
    }
    .upload-categories-container colgroup col {
        width: auto;
    }
    .upload-categories-container .el-table__header-wrapper tr th:nth-child(1),
    .upload-categories-container .el-table__header-wrapper tr th:nth-child(3) {
        display: none;
    }
    .upload-categories-container .el-table__body-wrapper tr td:nth-child(1) {
        width: 10%;
    }
    .upload-categories-container .el-table__body-wrapper tr td:nth-child(2) {
        width: 90%;
    }
    .upload-categories-container .el-table__body-wrapper tr td:nth-child(3) {
        width: 100%;
    }
    .upload-categories-container .el-upload,
    .upload-categories-container .el-upload-dragger {
        width: 100%;
    }
}
@media screen and (min-width: 1200px) {
    .container.upload-categories-container {
        max-width: 960px;
    }
    .upload-categories-container .el-upload,
    .upload-categories-container .el-upload-dragger {
        width: 100%;
    }
}
.upload-categories-container .el-table__expand-column.el-table__cell {
    cursor: default;
    pointer-events: none;
}
.upload-categories-container .el-table__row.expandable {
    cursor: pointer;
    position: relative;
}
.upload-categories-container
    .el-table__row.expandable
    td:not(.el-table__expand-column.el-table__cell) {
}
.upload-categories-container
    .el-table__row.expandable
    td:not(.el-table__expand-column.el-table__cell)::before {
    content: '';
    display: block;
    left: -48px;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    position: absolute;
}

.upload-categories-container .el-tree-node__content {
    cursor: default;
}
.edit-category,
.category-list-action {
    position: relative;
    z-index: 10;
}
.category-table .highlighted-text {
    color: #10377a !important;
    font-weight: 900 !important;
    text-decoration: underline;
}
.is-leaf.el-tree-node__expand-icon {
    display: none;
}
.el-tree-node__content {
    height: auto;
}

.edit-form-control-container {
    position: relative;
    width: 100%;
    .clear-edit {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        z-index: 20;
    }
    input[type='text'] {
        padding: 6px 16px;
        min-height: 100%;
        box-shadow: none !important;
        border-radius: 4px;
        border: 1px solid #eee;
        width: 100%;
    }
}
.category-list-action {
}
</style>
