<template>
    <div class="multi-step">
        <b-container>
            <b-row>
                <b-col cols="12" lg="10" offset-lg="1">
                    <b-link @click="redirect(backLocation())" class="text-gray-600">
                        <b-icon icon="arrow-left" class="me-1"></b-icon> Back to
                        {{ backText() }}
                    </b-link>
                    <b-overlay
                        :show="isProcessing"
                        rounded="sm"
                        spinner-type="grow"
                        spinner-small
                    >
                        <form-wizard
                            :title="''"
                            subtitle=""
                            ref="wizard"
                            shape="circle"
                            color="#10377A"
                            step-size="xs"
                        >
                            <!-- -------TAB ONE ---------------------------------------------------------------------------------------------------  -->
                            <tab-content
                                ref="descriptionTab"
                                title="Description"
                                class="pt-2 pt-lg-3 my-4 mb-lg-5"
                                :before-change="
                                    () => beforeTabSwitch($refs.descriptionValidator)
                                "
                            >
                                <h3 class="h4 text-gray-1000 mb-4 fw-bold">
                                    Describe Your Space
                                </h3>

                                <validation-observer
                                    ref="descriptionValidator"
                                    tag="form"
                                >
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <b-form-group class="mb-3 mb-lg-4">
                                                <template #label>
                                                    Property Type
                                                    <small class="text-danger">*</small>
                                                </template>

                                                <validation-provider
                                                    rules="required"
                                                    name="type"
                                                    v-bind="$attrs"
                                                    v-slot="{ errors }"
                                                >
                                                    <v-select
                                                        :value="selectedTypeOption"
                                                        @input="
                                                            option => {
                                                                checkChangeType(
                                                                    !option
                                                                        ? null
                                                                        : option.value
                                                                )
                                                            }
                                                        "
                                                        :required="true"
                                                        :options="typeOptions"
                                                        :class="{
                                                            'is-invalid': errors.length
                                                        }"
                                                    ></v-select>
                                                    <div
                                                        v-if="0 < errors.length"
                                                        class="invalid-feedback"
                                                    >
                                                        {{ errors[0] }}
                                                    </div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <t-input
                                                alternative
                                                class="mb-3 mb-lg-4"
                                                name="contactName"
                                                :rules="{ required: true }"
                                                label="Contact Name"
                                                placeholder="Name"
                                                type="text"
                                                v-model="model.contactName"
                                            />
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <b-form-group class="mb-3 mb-lg-4">
                                                <template #label>
                                                    Description
                                                    <small class="text-danger">*</small>
                                                </template>

                                                <validation-provider
                                                    rules="required"
                                                    name="description"
                                                    v-bind="$attrs"
                                                    v-slot="{ errors, validated }"
                                                >
                                                    <b-textarea
                                                        class="text-area"
                                                        :class="{
                                                            'is-invalid':
                                                                errors.length &&
                                                                validated
                                                        }"
                                                        v-model="model.description"
                                                        rows="10"
                                                        cols="50"
                                                    ></b-textarea>

                                                    <div
                                                        v-if="0 < errors.length"
                                                        class="invalid-feedback"
                                                    >
                                                        {{ errors[0] }}
                                                    </div>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" md="6">
                                            <t-input
                                                alternative
                                                class="mb-3 mb-lg-4"
                                                name="contactEmail"
                                                :rules="{ email: true }"
                                                label="Contact Email"
                                                placeholder="Email"
                                                type="text"
                                                v-model="model.contactEmail"
                                            />
                                            <t-input
                                                alternative
                                                class="mb-3 mb-lg-4"
                                                name="contactPhone"
                                                label="Contact Phone"
                                                placeholder="Phone"
                                                type="text"
                                                v-model="model.contactPhone"
                                            />
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ImagesUploader v-model="fileList" />
                                        </b-col>
                                        <b-col cols="12" md="6"> </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>

                            <!-- -------TAB TWO ---------------------------------------------------------------------------------------------------  -->
                            <tab-content
                                ref="locationTab"
                                title="Location"
                                class="pt-2 pt-lg-3 my-4 mb-lg-5"
                                :before-change="
                                    () => beforeTabSwitch($refs.locationValidator)
                                "
                            >
                                <h3 class="h4 text-gray-1000 mb-4 fw-bold">
                                    Where is the Space Located? (Optional)
                                </h3>

                                <validation-observer ref="locationValidator">
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <b-form-group
                                                id="input-group-0"
                                                label="Address"
                                                class="pb-3"
                                            >
                                                <b-icon
                                                    class="clear-icon"
                                                    @click="clearPlace()"
                                                    icon="x"
                                                    font-scale="1.6"
                                                ></b-icon>
                                                <gmap-autocomplete
                                                    ref="streetAddress"
                                                    class="search form-control"
                                                    placeholder="Street address"
                                                    :value="
                                                        model.formattedStreetAddress
                                                    "
                                                    :selectFirstOnEnter="true"
                                                    @place_changed="setPlace"
                                                >
                                                </gmap-autocomplete>
                                            </b-form-group>
                                        </b-col>

                                        <b-col cols="12" md="6">
                                            <t-input
                                                alternative
                                                class="mb-3 mb-lg-4"
                                                name="unitNumber"
                                                label="Unit or Apartment #"
                                                :rules="{ max: 7 }"
                                                type="text"
                                                v-model="model.unitNumber"
                                            />
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <t-input
                                                alternative
                                                class="mb-3 mb-lg-4"
                                                rules="url"
                                                name="website"
                                                label="Link to Building Website"
                                                placeholder="e.g www.mywebsite.com"
                                                type="text"
                                                v-model="model.website"
                                            />
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>

                            <!-- -------TAB THREE ---------------------------------------------------------------------------------------------------  -->
                            <tab-content
                                ref="spaceTab"
                                title="Space"
                                class="pt-2 pt-lg-3 my-4 mb-lg-5"
                            >
                                <b-row>
                                    <b-col cols="12" lg="6" xl="7" class="mb-4 mb-lg-0">
                                        <h3 class="h4 text-gray-1000 mb-4 fw-bold">
                                            More About the {{ spaceText }} (Optional)
                                        </h3>

                                        <b-form-group
                                            id="input-group-3"
                                            :label="
                                                `Are you granting usage of the whole ${spaceText.toLowerCase()} or only a portion?`
                                            "
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.shareScopeType"
                                                label="Whole"
                                                class="text-gray-1000"
                                                >Whole {{ spaceText }}</el-radio
                                            >
                                            <el-radio
                                                v-model="model.shareScopeType"
                                                label="Portion"
                                                class="text-gray-1000"
                                                >Portion of {{ spaceText }}</el-radio
                                            >
                                        </b-form-group>

                                        <b-form-group
                                            id="input-group-4"
                                            :label="
                                                `Is the ${spaceText.toLowerCase()} handicap accessible?`
                                            "
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasHandicapAccess"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasHandicapAccess"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>

                                        <b-form-group
                                            id="input-group-5"
                                            label="Is there access to a break room/kitchen?"
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasOfficeKitchen"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasOfficeKitchen"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>
                                        <b-form-group
                                            id="input-group-5"
                                            label="Is there parking available on site?"
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasParking"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasParking"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>
                                        <b-form-group
                                            id="input-group-5"
                                            :label="
                                                `Is there internet available in the ${spaceText.toLowerCase()}?`
                                            "
                                            class="mb-3 mb-lg-4"
                                        >
                                            <v-select
                                                v-model="model.availableInternetType"
                                                label="name"
                                                :options="[
                                                    'Yes',
                                                    'No',
                                                    'Ethernet Only'
                                                ]"
                                            ></v-select>
                                        </b-form-group>
                                        <!-- Bathroom section -->
                                        <b-form-group
                                            id="input-group-7"
                                            :label="
                                                `Are there restrooms available for use in the ${spaceText.toLowerCase()}?`
                                            "
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasBathroom"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasBathroom"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>
                                        <b-form-group
                                            v-if="
                                                !!model.hasBathroom &&
                                                    model.type !== 'Other'
                                            "
                                            id="input-group-8"
                                            label="Is the restroom handicap accessible?"
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasBathroomHandicap"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasBathroomHandicap"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>
                                        <b-form-group
                                            v-if="
                                                !model.hasBathroom &&
                                                    model.type !== 'Other'
                                            "
                                            id="input-group-9"
                                            label="Are there public restrooms available in a common area?"
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasBathroomPublic"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasBathroomPublic"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>
                                        <b-form-group
                                            id="input-group-10"
                                            label="Do the restrooms have a baby changing station?"
                                            class="mb-3 mb-lg-4"
                                        >
                                            <el-radio
                                                v-model="model.hasBathroomBabyChange"
                                                :label="true"
                                                class="text-gray-1000"
                                                >Yes</el-radio
                                            >
                                            <el-radio
                                                v-model="model.hasBathroomBabyChange"
                                                :label="false"
                                                class="text-gray-1000"
                                                >No</el-radio
                                            >
                                        </b-form-group>
                                        <b-form-group
                                            id="input-group-7"
                                            :label="
                                                `Please provide information about the building your ${spaceText.toLowerCase()} is located in`
                                            "
                                            class="mt-3 mb-3 mb-lg-4"
                                        >
                                            <b-textarea
                                                v-model="model.additionalInfo"
                                                placeholder="Building services, amenities, etc."
                                                rows="4"
                                                cols="50"
                                            ></b-textarea>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" lg="6" xl="5">
                                        <schedule
                                            :edit="edit"
                                            :create="create"
                                            v-model="model.timeslots"
                                            class="ms-xl-4"
                                        />
                                    </b-col>
                                </b-row>
                            </tab-content>

                            <!-- -------TAB FOUR ---------------------------------------------------------------------------------------------------  -->
                            <tab-content
                                ref="rateTab"
                                title="Rate / Sharing"
                                class="pt-2 pt-lg-3 my-4 mb-lg-5"
                            >
                                <validation-observer ref="rateValidator">
                                    <h3 class="h4 text-gray-1000 mb-4 fw-bold">
                                        Rate and Sharing Information (Optional)
                                    </h3>
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <b-form-group
                                                id="input-group-8"
                                                label="Compensation Status?"
                                                class="mb-3 mb-lg-4"
                                            >
                                                <el-radio
                                                    v-model="model.compRateType"
                                                    label="Negotiable"
                                                    class="text-gray-1000"
                                                    >Rate is Negotiable</el-radio
                                                >
                                                <el-radio
                                                    v-model="model.compRateType"
                                                    label="Own"
                                                    class="text-gray-1000"
                                                    >Enter My Own Rate</el-radio
                                                >
                                                <el-radio
                                                    v-model="model.compRateType"
                                                    label="Fill"
                                                    class="text-gray-1000"
                                                    >Fill in the following searchable
                                                    fields</el-radio
                                                >
                                            </b-form-group>
                                        </b-col>
                                        <b-col
                                            v-if="'Own' == model.compRateType"
                                            cols="12"
                                            md="6"
                                        >
                                            <label class="form-control-label"
                                                >Compensation Rate? (1000/month)</label
                                            >
                                            <b-input-group class="mb-3 mb-lg-4">
                                                <b-input-group-prepend>
                                                    $
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    v-model="model.compRateFree"
                                                    class="border-left-0"
                                                    :style="{ 'padding-left': '20px' }"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                        </b-col>
                                        <template v-if="'Fill' === model.compRateType">
                                            <b-col cols="12" md="6">
                                                <label class="form-control-label"
                                                    >Compensation Rate? (1000)</label
                                                >
                                                <div class="mb-3 mb-lg-4">
                                                    <b-input-group-prepend>
                                                        $
                                                    </b-input-group-prepend>
                                                    <t-input
                                                        alternative
                                                        v-model="model.compRatePrice"
                                                        class="mb-3 mb-lg-4"
                                                        type="text"
                                                        name="compensation rate"
                                                        rules="numeric"
                                                    >
                                                    </t-input>
                                                </div>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group class="mb-3 mb-lg-4">
                                                    <template #label>
                                                        Compensation Rate period (eg:
                                                        hours)
                                                    </template>

                                                    <v-select
                                                        v-model="
                                                            model.compRatePeriodType
                                                        "
                                                        label="name"
                                                        :options="[
                                                            'Hour',
                                                            'Day',
                                                            'Week',
                                                            'Month'
                                                        ]"
                                                    ></v-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <t-input
                                                    alternative
                                                    label="Minimum # of Hours Required to Share the Space"
                                                    class="mb-3 mb-lg-4"
                                                    name="minHours"
                                                    type="text"
                                                    :rules="{ numeric: true }"
                                                    v-model="model.minHours"
                                                />
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group class="mb-3 mb-lg-4">
                                                    <template #label>
                                                        Minimum Commitment Period
                                                    </template>

                                                    <v-select
                                                        v-model="model.minHoursType"
                                                        label="name"
                                                        :options="[
                                                            'Day',
                                                            'Week',
                                                            'Month',
                                                            'Year'
                                                        ]"
                                                    ></v-select>
                                                </b-form-group>
                                            </b-col>
                                        </template>
                                        <b-col cols="12" md="6">
                                            <t-slider
                                                v-if="showOccupantsSlider"
                                                name="occupants"
                                                :min="0"
                                                :max="50"
                                                label="Number of occupants already in the space"
                                                v-model="model.occupants"
                                                class="mb-3 mb-lg-4 d-block"
                                            />
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <b-form-group
                                                id="input-group-10"
                                                label="Staff available for sharing?"
                                                class="mb-3 mb-lg-4"
                                            >
                                                <el-radio
                                                    v-model="model.hasSharedStaff"
                                                    :label="true"
                                                    class="text-gray-1000"
                                                    >Yes</el-radio
                                                >
                                                <el-radio
                                                    v-model="model.hasSharedStaff"
                                                    :label="false"
                                                    class="text-gray-1000"
                                                    >No</el-radio
                                                >
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>

                            <!-- -------TAB FOUR ---------------------------------------------------------------------------------------------------  -->
                            <tab-content
                                ref="extraTab"
                                title="Extra"
                                class="pt-2 pt-lg-3 my-4 mb-lg-5"
                                v-if="model.type"
                            >
                                <validation-observer ref="extraValidator">
                                    <h3 class="h4 text-gray-1000 mb-4 fw-bold">
                                        Extra information (Optional)
                                    </h3>
                                    <b
                                        v-if="showClarificationText"
                                        class="form-control-label mb-4"
                                    >
                                        Are there any other professionals who use this
                                        space on non listed days?
                                    </b>
                                    <b-row v-if="typeMap.extraFieldsDefinition">
                                        <b-col
                                            v-for="field in filterTypeProperties(
                                                typeMap.extraFieldsDefinition.properties
                                            )"
                                            :key="field.name"
                                            cols="12"
                                            sm="6"
                                        >
                                            <div v-if="field.widget == 'slider'">
                                                <t-slider
                                                    :name="field.name"
                                                    :min="0"
                                                    :max="20"
                                                    :label="field.label"
                                                    v-model="extraFields[field.name]"
                                                    class="mb-3 mb-lg-4 d-block"
                                                />
                                            </div>
                                            <div v-if="field.widget == 'dropdown'">
                                                <b-form-group class="mb-3 mb-lg-4">
                                                    <template #label>
                                                        {{ field.label }}
                                                    </template>
                                                    <v-select
                                                        :multiple="
                                                            0 >
                                                                [
                                                                    'totalSpaceUnit',
                                                                    'rentableSpaceUnit',
                                                                    'rentableSpaceSize'
                                                                ].indexOf(field.name)
                                                        "
                                                        class="mb-3 mb-lg-4 d-block"
                                                        v-model="
                                                            extraFields[field.name]
                                                        "
                                                        :options="field.choices"
                                                    ></v-select>
                                                </b-form-group>
                                            </div>
                                            <div
                                                v-if="
                                                    field.widget ==
                                                        'single-select-dropdown'
                                                "
                                            >
                                                <b-form-group class="mb-3 mb-lg-4">
                                                    <template #label>
                                                        {{ field.label }}
                                                    </template>
                                                    <v-select
                                                        class="mb-3 mb-lg-4 d-block"
                                                        v-model="
                                                            extraFields[field.name]
                                                        "
                                                        :options="field.choices"
                                                    ></v-select>
                                                </b-form-group>
                                            </div>
                                            <div
                                                v-if="
                                                    field.widget == 'textarea' ||
                                                        field.widget == 'input'
                                                "
                                            >
                                                <t-input
                                                    class="mb-3 mb-lg-4"
                                                    :name="field.name"
                                                    :label="field.label"
                                                    :type="
                                                        'number' === field.type
                                                            ? 'number'
                                                            : 'text'
                                                    "
                                                    :min="
                                                        'number' === field.type
                                                            ? 0
                                                            : null
                                                    "
                                                    v-model="extraFields[field.name]"
                                                />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </validation-observer>
                            </tab-content>

                            <template slot="footer" scope="props">
                                <div class="wizard-footer-left">
                                    <wizard-button
                                        :disabled="!wizardValid || isProcessing"
                                        :style="{
                                            'background-color': 'rgb(251, 99, 65)',
                                            color: 'white'
                                        }"
                                        @click.native="checkCredit(saveOrPublish)"
                                        class="finish-button wizard-footer-right filled"
                                        >{{ saveOrPublish }}</wizard-button
                                    >
                                    <wizard-button
                                        @click.native="previewListing()"
                                        class="wizard-footer-right"
                                        :disabled="isProcessing"
                                        :style="props.fillButtonStyle"
                                        >Preview listing</wizard-button
                                    >
                                    <wizard-button
                                        v-if="
                                            props.activeTabIndex > 0 &&
                                                model.status !== 'Published'
                                        "
                                        @click.native="saveProperty(true)"
                                        :disabled="!wizardValid || isProcessing"
                                        :style="props.fillButtonStyle"
                                        >Save Draft</wizard-button
                                    >
                                </div>
                                <div class="wizard-footer-right">
                                    <wizard-button
                                        v-if="props.activeTabIndex > 0"
                                        @click.native="props.prevTab()"
                                        :disabled="isProcessing"
                                        :style="props.fillButtonStyle"
                                        >Previous</wizard-button
                                    >
                                    <wizard-button
                                        v-if="!props.isLastStep"
                                        @click.native="props.nextTab()"
                                        class="wizard-footer-right"
                                        :disabled="isProcessing"
                                        :style="props.fillButtonStyle"
                                        >Next</wizard-button
                                    >
                                </div>
                            </template>
                        </form-wizard>
                    </b-overlay>
                </b-col>
            </b-row>
            <b-modal class="centered" id="preview-modal-multi" :hide-footer="true">
                <template #modal-title>PREVIEW</template>
                <detail :form="model" :images="model.images" />
            </b-modal>
        </b-container>
        <publish-modal :scope="this" :user="user" />
        <runout-modal :multi="true" :scope="this" />
        <type-modal :scope="this" />
        <checkout />
    </div>
</template>
<script>
import { Radio } from 'element-ui'
import Schedule from '@comp/Schedule'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import PropertyService from '@serv/PropertyService'
import { mapGetters } from 'vuex'
import ImagesUploader from '@comp/ImagesUploader'
import Detail from '@comp/Detail'
import Redirect from '@serv/Redirect'
import Checkout from '@comp/Checkout'
import NotifyService from '@serv/NotifyService'
import RunoutModal from '@comp/modal/RunoutModal'
import PublishModal from '@comp/modal/PublishModal'
import TypeModal from '@comp/modal/TypeModal'
import FormMixin from '@mixins/FormMixin'
import PlaceMixin from '@mixins/PlaceMixin'

locale.use(lang)

export default {
    name: 'MultiStep',
    components: {
        ImagesUploader,
        Schedule,
        RunoutModal,
        PublishModal,
        TypeModal,
        [Radio.name]: Radio,
        Detail,
        Checkout
    },
    mixins: [FormMixin, PlaceMixin],
    data() {
        return {
            wizardTouched: false,
            wizardValid: false,
            tempTypeValue: '',
            isProcessing: false,
            model: {
                city: undefined,
                state: undefined,
                zipcode: undefined,
                compRateType: 'Negotiable',
                compRateCurrency: 'USD'
            },
            fileList: [],
            extraFields: {},
            typeMap: {},
            countries: [],
            edit: false
        }
    },
    props: {
        form: {
            type: Object
        },
        create: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters([
            'user',
            'productType',
            'types',
            'fieldTypes',
            'typeOptions',
            'typeOption'
        ]),
        saveOrPublish() {
            return this.edit && this.model.status == 'Published' ? 'Save' : 'Publish'
        },
        selectedTypeOption() {
            return this.typeOption(this.model.type)
        },
        showOccupantsSlider() {
            return this.model.shareScopeType !== 'Whole'
        },
        spaceText() {
            if (['Accounting Office', 'Legal Office'].includes(this.model.type)) {
                return 'Office'
            }
            return 'Space'
        },
        showClarificationText() {
            return [
                'Accounting Office',
                'Dental',
                'Legal Office',
                'Medical',
                'Stylish Salon',
                'Surgical',
                'Other'].includes(this.model.type)
        }
    },
    methods: {
        checkChangeType(value) {
            if (this.edit && value !== this.model.type) {
                this.tempTypeValue = value
                this.$bvModal.show('type-modal')
            } else {
                this.model = { ...this.model, type: value }
            }
        },
        changeType() {
            this.model = { ...this.model, type: this.tempTypeValue, extra: null }
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        checkCredit(string) {
            if (string == 'Publish') {
                if (this.user.listingAllowed < 1 && !this.user.isSuperuser) {
                    this.$bvModal.show('runout-modal-0')
                    return false
                }

                if (this.user.listingAllowed >= 1 || this.user.isSuperuser) {
                    this.$bvModal.show('publish-modal-0')
                }
            } else {
                this.saveProperty()
            }
        },
        updateProperty() {
            PropertyService.updateProperty(this.model).then(() => {
                Redirect.gotoName(this.backLocation())
                NotifyService.notify(this, 'Property updated successfully', 'success')
            })
        },
        backText() {
            if (this.$route.params.adminRedirectToUsers && this.user.isSuperuser) {
                return 'users'
            } else {
                return 'listings'
            }
        },
        backLocation() {
            if (this.$route.params.adminRedirectToUsers && this.user.isSuperuser) {
                return 'ListUser'
            } else {
                return 'Listings'
            }
        },
        publishProperty() {
            this.model.ownerId = this.user.id

            if (this.create) {
                this.model.timeslots.forEach((day, i) => {
                    if (day && day.startTime.length == 0) {
                        delete this.model.timeslots[i].startTime
                    }
                    if (day && day.endTime.length == 0) {
                        delete this.model.timeslots[i].endTime
                    }
                })
            }
            if (this.create) {
                PropertyService.createProperty(this.model, null, false).then(() => {
                    NotifyService.notify(
                        this,
                        'Property listed successfully',
                        'success'
                    )
                })
            } else {
                PropertyService.changeListingStatus(true, this.model).then(() => {
                    Redirect.gotoName('Listings')
                    NotifyService.notify(
                        this,
                        'Property updated successfully',
                        'success'
                    )

                    this.$store.commit('removeListingAllowed', this.user)
                })
            }
        },
        clearTimeslots(timeslots) {
            return timeslots.forEach((day, i) => {
                if (day.startTime && !day.startTime.length) {
                    delete this.model.timeslots[i].startTime
                }
                if (day.endTime && !day.endTime.length) {
                    delete this.model.timeslots[i].endTime
                }
            })
        },
        formatImages() {
            return this.fileList.filter(file => file.image || file.url)
        },
        clearPlace() {
            this.$refs.streetAddress.$el.value = ''
            this.model.streetAddress = ''
            this.model.formattedStreetAddress = ''
            this.model.city = ''
            this.model.country = ''
            this.model.zipcode = ''
            this.model.state = ''
        },
        saveProperty(sessionRequest) {
            if (this.model.timeslots)
                this.model.timeslots.forEach((day, i) => {
                    if (this.edit) {
                        this.clearTimeslots(this.model.timeslots)
                    } else {
                        if (day && !day.startTime.length) {
                            delete this.model.timeslots[i].startTime
                        }
                        if (day && !day.endTime.length) {
                            delete this.model.timeslots[i].endTime
                        }
                    }
                })

            if (this.edit) {
                this.updateProperty()
            } else {
                PropertyService.createProperty(this.model, sessionRequest, true).then(
                    () => {
                        NotifyService.notify(
                            this,
                            'Your draft has been saved successfully',
                            'success'
                        )
                    }
                )
            }
        },
        beforeTabSwitch(validator = null) {
            if (validator && !this.$errorService.errors) {
                return new Promise((resolve, reject) => {
                    validator.validate().then(success => {
                        if (!success)
                            reject(
                                'This is a custom validation error message. Click next again to get rid of the validation'
                            )
                        else resolve(true)
                    })
                })
            }

            return true
        },
        previewListing() {
            this.$bvModal.show('preview-modal-multi')
        },
        filterTypeProperties(properties) {
            let filteredProps = properties

            if (this.model.type === 'Other') {
                filteredProps = _.omit(properties, [
                    'rentableSpaceUnit',
                    'totalSpaceUnit'
                ])
                if (this.model.shareScopeType === 'Whole') {
                    filteredProps = _.omit(filteredProps, ['rentableSpaceSize'])
                } else if (this.model.shareScopeType === 'Portion') {
                    filteredProps = _.omit(filteredProps, ['totalSpaceSize'])
                }
            }
            return filteredProps
        },
        manualValidation() {
            this.wizardTouched = true
            setTimeout(() => {
                const tabValidators = [
                    'descriptionValidator',
                    'locationValidator',
                    'rateValidator',
                    'extraValidator'
                ]
                tabValidators.forEach(ref => {
                    if (this.$refs[ref] && !this.$refs[ref].flags.valid) {
                        this.wizardValid = false
                    }
                })

                let allRefState = []
                Object.keys(this.$refs).forEach(ref => {
                    if (tabValidators.includes(ref)) {
                        if (this.$refs[ref]) {
                            allRefState.push(this.$refs[ref].flags.valid)
                        }
                    }
                })

                const allTrueRef = allRefState.every(ref => ref === true)

                if (allTrueRef && this.wizardTouched) this.wizardValid = true
            }, 400)
        }
    },
    beforeMount() {
        PropertyService.getCountries().then(countries => {
            this.countries = countries
        })
        if (this.form) {
            const listingModel = {}
            Object.keys(this.form).forEach(item => {
                listingModel[item] = this.form[item]
            })

            this.model = listingModel
            this.model.formattedStreetAddress = this.model.streetAddress

            if (this.model.coords == null) this.model.coords = undefined
            if (this.model.extra) this.extraFields = this.model.extra

            this.form.images.forEach(picture => {
                picture.url = `http://${picture.image}`
            })
            this.fileList = this.form.images
            this.edit = true
        }
    },
    mounted() {
        this.$bus.$on('api-request', payload => {
            this.isProcessing = payload
        })

        if (this.model.type) {
            this.typeMap = this.fieldTypes.find(type => type.name === this.model.type)
        }
        if (this.edit && this.$refs.wizard) {
            this.$refs.wizard.activateAll()
            this.wizardTouched = true
        }

        if (0 > this.model.streetAddress.indexOf(',')) {
            this.model.formattedStreetAddress += this.model.city
                ? `, ${this.model.city}`
                : ''
            this.model.formattedStreetAddress += this.model.state
                ? `, ${this.model.state}`
                : ''
            this.model.formattedStreetAddress += this.model.zipcode
                ? ` ${this.model.zipcode}`
                : ''
            this.model.formattedStreetAddress += this.model.country
                ? `, ${this.model.country}`
                : ''
        }

        this.$nextTick(() => {
            if (this.$refs.streetAddress && this.model.formattedStreetAddress)
                this.$refs.streetAddress.$emit(
                    'place_changed',
                    this.model.formattedStreetAddress
                )

            if (this.form && this.form.streetAddress) {
                if (this.$refs.addressGmap.value) {
                    this.$refs.addressGmap.value = this.form.streetAddress
                }

                this.$refs.locationValidator.validate()
            }
        })
    },
    watch: {
        model: {
            handler() {
                this.manualValidation()
            },
            deep: true
        },
        'model.type': function() {
            if (this.model.type) {
                this.typeMap = this.fieldTypes.find(
                    type => type.name === this.model.type
                )
            } else {
                this.typeMap = {}
            }
            this.manualValidation()
        },
        extraFields() {
            this.model.extra = this.extraFields
            Object.entries(this.extraFields).forEach(item => {
                if (
                    'number' ===
                    this.typeMap.extraFieldsDefinition.properties[item[0]].type
                )
                    this.model.extra[item[0]] = parseFloat(item[1])
                else this.model.extra[item[0]] = item[1]
            })
        },
        fileList() {
            this.model.images = this.formatImages()
        },
        showOccupantsSlider(show) {
            //Reset the number of occupants if
            // we hide the control slider
            if (!show) {
                this.model.occupants = 0
            }
        },
        '$errorService.errors'(newVal) {
            if (!newVal.length) return false

            this.$nextTick(() => {
                Object.keys(this.$refs)
                    .filter(key => this.$refs[key].tabId)
                    .forEach(key => {
                        const tab = $(this.$refs[key].$el)

                        if (tab.find('.is-invalid').length) {
                            this.$refs[key].checked = true
                            this.$refs[key].validationError = true
                        }
                    })
            })
        }
    }
}
</script>

<style lang="scss">
.multi-step {
    .el-radio__input.is-checked + .el-radio__label {
        color: #367088 !important;
    }
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #367088;
        background: #367088;
    }
    .is-invalid {
        border-color: #dc3545 !important;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url('data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg viewBox=0 0 12 12 width=12 height=12 fill=none stroke=%23dc3545%3e%3ccircle cx=6 cy=6 r=4.5/%3e%3cpath stroke-linejoin=round d=M5.8 3.6h.4L6 6.5z/%3e%3ccircle cx=6 cy=8.2 r=.6 fill=%23dc3545 stroke=none/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
}

.input-group {
    &-prepend {
        position: absolute;
        display: flex;
        z-index: 1000;
        padding-top: 9px;
        color: #8898aa;
        padding-left: 9px;
    }
    .vs__dropdown {
        &-toggle {
            min-width: 65px;
            border-right: 0 !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
.clear-icon {
    cursor: pointer;
    font-size: 160%;
    position: absolute;
    right: 20px;
    top: 37px;
    display: flex;
    float: right;
    z-index: 1000;
}

#preview-modal-multi .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: contents;
    position: fixed;
    overflow-y: scroll;
    z-index: 100000;
}
</style>
