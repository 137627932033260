const state = {
    categoryNames: [],
    categories: []
}

const getters = {
    categoryNames: state => {
        return state.categoryNames
    },
    categories: state => {
        return state.categories
    },
    categoryByName: state => name => {
        return state.categoryNames.find(
            catName => catName.trim().toLowerCase() === name.trim().toLowerCase()
        )
    }
}

const actions = {}

const mutations = {
    setCategories: (state, categories) => {
        categories = categories.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
        state.categoryNames = categories.map(({ name }) => name)
        state.categories = categories
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
