<template>
    <b-row>
        <b-col cols="12" :lg="hasContactInfo ? 8 : 12">
            <b-row>
                <b-col cols="12" :lg="profilePhotoURL ? 4 : 3" class="mb-4 mb-lg-5">
                    <b-card-img
                        v-if="profilePhotoURL"
                        :src="profilePhotoURL"
                        class="rounded"
                    />
                    <div v-else class="placeholder-thumbnail"></div>
                </b-col>
                <b-col cols="12" :lg="profilePhotoURL ? 8 : 9">
                    <div>
                        <h2 class="text-gray-800 mb-0 fw-bold">
                            <span v-if="displayName">{{ displayName }}</span>
                        </h2>
                        <div class="mb-4">
                            <span v-if="jobTypeText">{{ jobTypeText }}</span>
                            <span v-if="specialityText">, {{ specialityText }}</span>
                        </div>
                        <div class="mb-4">
                            <h4 class="d-inline-block"><b>Desired Job Areas:</b></h4>
                            &nbsp;
                            <span v-for="(area, idx) in listPreferedArea()" :key="idx">
                                <span v-if="idx != 0"> - </span>
                                <span v-if="area.city">{{ area.city }}</span>
                                <span v-if="area.state">, {{ area.state }}</span>
                            </span>
                        </div>
                    </div>
                    <slot name="header-bottom"></slot>
                </b-col>
                <b-col cols="12" class="mt-2" v-if="description">
                    <h4 class="property-info__sub-title fw-bold">
                        About
                    </h4>
                    <p class="mb-0 pre-formatted" v-if="description">
                        {{ description }}
                    </p>
                </b-col>
                <b-col v-if="resume" cols="12" class="mt-4 mb-5">
                    <a
                        :href="resumeLink()"
                        download
                        target="_blank"
                        class="btn btn-secondary btn-sm text-capitalize d-inline-flex w-auto align-items-center justify-content-center"
                    >
                        <span class="me-2">Download Resume</span
                        ><b-icon icon="download" aria-hidden="true"></b-icon>
                    </a>
                </b-col>
            </b-row>
        </b-col>
        <b-col v-if="hasContactInfo" cols="12" lg="4" class="mt-lg-0 mt-4">
            <contact-info :email="email" :phoneNumber="phoneNumber" />
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import WorkforceViewMixin from '@mixins/WorkforceViewMixin'
import ContactInfo from '@comp/detail/ContactInfo'

export default {
    name: 'WorkforceDetail',
    mixins: [WorkforceViewMixin],
    components: { ContactInfo },
    props: {
        phoneNumber: String,
        email: String,
        resume: {
            type: [String, File],
            default: undefined
        }
    },
    computed: {
        ...mapGetters({
            preferedAreaById: 'preferedAreaById',
            fieldTypeById: 'workforceFieldTypeById'
        }),
        hasContactInfo() {
            return !!(this.email || this.phoneNumber)
        }
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt)
        },
        listPreferedArea() {
            return this.preferedArea.map(area => {
                if (area && typeof area == 'object') {
                    return area
                } else if (area && typeof area == 'number') {
                    return this.preferedAreaById(area)
                }
                return { city: '', state: '' }
            })
        },
        resumeLink() {
            if (!this.resume) {
                return
            }
            if (typeof this.resume === 'object' && this.resume instanceof File) {
                return 'javascript:;' // do nothing, just to preview for now
            }
            if (typeof this.resume === 'string') {
                return this.resume
            }
        }
    },
    mounted() {}
}
</script>
