<template>
    <b-card
        no-body
        class="list-card horizontal-card d-flex flex-row"
        @click="handleClick"
    >
        <div class="thumbnail pe-3">
            <b-card-img v-if="profilePhotoURL" :src="profilePhotoURL"></b-card-img>
            <div v-else></div>
        </div>
        <div class="body">
            <h4 class="card-title">
                <span v-if="displayName">{{ displayName }}</span>
            </h4>
            <h6 class="card-subtitle text-muted mb-2">
                <span v-if="jobTypeText">{{ jobTypeText }}</span>
                <span v-if="specialityText">, {{ specialityText }}</span>
            </h6>
            <b-card-text class="text-muted">
                <span v-for="(loc, idx) in areaLocations" :key="idx">
                    <span v-if="idx != 0"> - </span>
                    <span>{{ loc }}</span>
                </span>
            </b-card-text>
            <listing-excerpt
                class="pre-formatted"
                v-if="description"
                :text="description"
                @click:expand="onClickExpand"
            />
        </div>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import WorkforceViewMixin from '@mixins/WorkforceViewMixin'
import ListingExcerpt from '@comp/ListingExcerpt'
import { makeLocationString } from '@util/helpers'

export default {
    name: 'WorkforceListing',
    mixins: [WorkforceViewMixin],
    components: { ListingExcerpt },
    computed: {
        ...mapGetters({
            preferedAreaById: 'preferedAreaById',
            fieldTypeById: 'workforceFieldTypeById',
            fieldTypes: 'workforceFieldTypes'
        }),
        areaLocations() {
            var preferedArea = this.preferedArea
            return preferedArea.map(area => {
                if (area && typeof area == 'object') {
                    return makeLocationString(area)
                } else if (area && typeof area == 'number') {
                    var areaObject = this.preferedAreaById(area)
                    if (areaObject) {
                        return makeLocationString(areaObject)
                    }
                }
                return ''
            })
        }
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt)
        },
        onClickExpand() {
            this.$emit('click:expand')
        }
    },
    mounted() {}
}
</script>
