import Redirect from '@serv/Redirect'
import PlaceMixin from '@mixins/PlaceMixin'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            tempQuery: undefined,
            type: undefined,
            category: undefined,
            search: undefined,
            containsFilter: true,
            categorySearch: ''
        }
    },
    mixins: [PlaceMixin],
    computed: {
        ...mapGetters(['auth']),
        categoryOptions() {
            return this.fieldTypes.map(category => {
                return {
                    label: category.name,
                    value: category.id
                }
            })
        }
    },
    methods: {
        onSelectCategory(category) {
            this.category = category
        },
        onCategorySearchClear() {
            this.category = undefined
        },
        redirect(location) {
            if (this.search) {
                this.setSearchQuery(this.search)
            } else {
                this.clearSearchQuery()
            }
            if (this.category) {
                this.addListFilter({
                    name: 'category',
                    value: this.category
                })
            } else {
                this.removeListFilter()
            }
            Redirect.gotoName(location)
        }
    },
    mounted() {
        this.clearFilters()
    }
}
