const state = {
    jobSearchQuery: undefined,
    jobFieldTypes: [],
    jobTypes: [],
    jobFilters: []
}
const jobFieldTypeByName = (state, name) => {
    return state.jobFieldTypes.find(
        type => type.name.trim().toLowerCase() === name.trim().toLowerCase()
    )
}
const getters = {
    jobSearchQuery: state => {
        return state.jobSearchQuery
    },
    jobFilters: state => {
        return state.jobFilters
    },
    jobTypes: state => {
        return state.jobTypes
    },
    jobCategories: state => {
        var categories = state.jobTypes
        return categories
    },
    jobSubcategoriesOf: state => category => {
        var catId = jobFieldTypeByName(state, category).id
        return state.jobFieldTypes
            .filter(type => type.parent && type.parent == catId)
            .map(item => item.name)
    },
    jobFieldTypeByName: state => name => {
        return jobFieldTypeByName(state, name)
    },
    jobFieldTypeById: state => id => {
        return state.jobFieldTypes.find(type => type.id === id)
    },
    jobFieldTypes: state => {
        return state.jobFieldTypes
    },
    jobFiltersQuery: state => {
        if (!state.jobFilters.length) return null
        const filters = {}
        state.jobFilters.forEach(item => {
            if (item.name === 'category') {
                var category = item.value
                filters['cat_id'] = category.id
            } else {
                filters[item.name] =
                    'object' == typeof item.value ? item.value.join(',') : item.value
            }
        })
        return filters
    }
}

const actions = {}

const mutations = {
    setJobSearchQuery: (state, search) => {
        state.jobSearchQuery = search
    },
    addJobListFilter: (state, filter) => {
        state.jobFilters = state.jobFilters.filter(item => item.name !== name)
        state.jobFilters.push(filter)
    },
    removeJobListFilter: (state, type) => {
        state.jobFilters = state.jobFilters.filter(item => item.name !== type)
    },
    clearJobSearchQuery: state => {
        state.jobSearchQuery = undefined
    },
    clearJobFilters: state => {
        state.jobFilters = []
    },
    setJobTypes: (state, types) => {
        types = types.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
        state.jobTypes = types.map(({ name }) => name)
        state.jobFieldTypes = types
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
