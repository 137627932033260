<template>
    <b-container class="listings px-2 px-xl-3 pb-5">
        <template v-if="!!auth">
            <h1 class="page-title mb-3 text-left">
                My Job Openings
            </h1>
            <div class="list-container">
                <div class="mb-3 d-inline-flex w-100">
                    <b-button
                        :variant="
                            listFilter == 'Published' ? 'primary' : 'outline-primary'
                        "
                        size="sm"
                        class="text-capitalize my-1 me-2"
                        @click="filterToggle('Published')"
                        >Published</b-button
                    >

                    <b-button
                        :variant="
                            listFilter == 'Unpublished' ? 'primary' : 'outline-primary'
                        "
                        size="sm"
                        class="text-capitalize my-1 me-2"
                        @click="filterToggle('Unpublished')"
                        >Draft</b-button
                    >
                    <b-button
                        :variant="listFilter == 'All' ? 'primary' : 'outline-primary'"
                        size="sm"
                        class="text-capitalize my-1 me-2"
                        @click="filterToggle('All')"
                        >All</b-button
                    >
                    <b-button
                        variant="secondary"
                        size="sm"
                        class="text-capitalize my-1 ms-auto"
                        @click="redirect('CreateJobOpening')"
                        >New Opening</b-button
                    >
                </div>

                <loading-overlay
                    class="loading-list"
                    v-if="!loaded"
                    :state="!loaded"
                ></loading-overlay>
                <b-row v-if="0 < filteredList.length">
                    <b-col
                        v-for="property in filteredList"
                        :key="`my_job_opening_${property.id}`"
                        cols="12"
                        md="6"
                        class="mb-4"
                    >
                        <div class="listing-item-container">
                            <job-listing
                                :thumbnail="property.imageURL"
                                :title="property.title"
                                :companyName="property.companyName"
                                :description="property.description"
                                :compensation="property.compensation"
                                :preferedAreaId="property.preferedArea"
                                :badge="isDraft(property) ? 'Draft' : ''"
                                @click="openPreview(property)"
                            >
                            </job-listing>
                            <div class="listing-actions">
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    title="Edit"
                                    class="listing-action"
                                    @click="
                                        () => {
                                            callAction('Edit', property)
                                        }
                                    "
                                >
                                    <b-icon icon="pencil-square"></b-icon>
                                </b-button>
                                <b-button
                                    v-if="property.status === 'Unpublished'"
                                    variant="outline-primary"
                                    size="sm"
                                    title="Publish"
                                    class="listing-action"
                                    @click="
                                        () => {
                                            callAction('Publish', property)
                                        }
                                    "
                                >
                                    <b-icon icon="folder2-open"></b-icon>
                                </b-button>
                                <b-button
                                    v-if="property.status === 'Published'"
                                    variant="outline-primary"
                                    size="sm"
                                    title="Unpublish"
                                    class="listing-action"
                                    @click="
                                        () => {
                                            callAction('Unpublish', property)
                                        }
                                    "
                                >
                                    <b-icon icon="folder"></b-icon>
                                </b-button>
                                <b-button
                                    variant="outline-primary"
                                    size="sm"
                                    title="Delete"
                                    class="listing-action"
                                    @click="() => callAction('Delete', property)"
                                >
                                    <b-icon icon="trash" class=""></b-icon>
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <no-data-placeholder
                            v-if="
                                (!filteredList.length &&
                                    loaded &&
                                    listFilter == 'All' &&
                                    noOtherListings) ||
                                    (!filteredList.length &&
                                        loaded &&
                                        listFilter == 'Unpublished' &&
                                        noOtherListings)
                            "
                            class="my-5"
                        >
                            <template #sub
                                >Create your first opening now. <br />Just click on
                                <div
                                    class="add-property-cta d-inline-block"
                                    @click="redirect('CreateJobOpening')"
                                >
                                    "New Opening"
                                </div>
                            </template>
                        </no-data-placeholder>
                        <no-data-placeholder
                            v-if="
                                !filteredList.length &&
                                    loaded &&
                                    listFilter == 'Unpublished' &&
                                    !noOtherListings
                            "
                            class="my-5"
                        >
                            <template #sub
                                >You have published all your openings.</template
                            >
                        </no-data-placeholder>

                        <no-data-placeholder
                            v-if="
                                !filteredList.length &&
                                    loaded &&
                                    listFilter == 'Published'
                            "
                            class="my-5"
                        >
                            <template #sub
                                >Create your first opening now. <br />Just click on
                                <div
                                    class="add-property-cta d-inline-block"
                                    @click="redirect('CreateJobOpening')"
                                >
                                    "New Opening"
                                </div>
                            </template>
                        </no-data-placeholder>
                    </b-col>
                </b-row>

                <!-- Modals -->
                <b-modal
                    size="lg"
                    dialog-class=" big-modal"
                    class="centered"
                    return-focus="body"
                    :id="`preview-modal`"
                    :hide-footer="true"
                >
                    <template #modal-title>PREVIEW</template>
                    <job-detail :form="this.modalObject" :view="true" />
                </b-modal>
                <b-modal
                    size="md"
                    class="centered"
                    id="delete-modal"
                    return-focus="body"
                    @ok="deleteAction()"
                    ok-title="Delete"
                >
                    <template #modal-title>Delete Opening?</template>
                    <div>
                        Are you sure you want to delete this opening?<br />
                        This action cannot be undone.
                    </div>
                </b-modal>
                <b-modal
                    size="md"
                    class="centered"
                    id="publish-modal"
                    return-focus="body"
                    @ok="publishProperty(true)"
                    ok-title="Publish"
                >
                    <template #modal-title>Publish Opening?</template>
                    <div>
                        This will set the opening to the active status; it will be seen
                        by the public. You may unpublish this opening at any-time.
                    </div>
                </b-modal>
                <b-modal
                    size="md"
                    class="centered"
                    id="unpublish-modal"
                    return-focus="body"
                    @ok="publishProperty(false)"
                    ok-title="Unpublish"
                >
                    <template #modal-title>Unpublish Opening?</template>
                    <div>
                        This will remove the opening from active status; it will no
                        longer be seen by the public. You may re-publish this opening at
                        any-time.
                    </div>
                </b-modal>
            </div>
        </template>
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingOverlay from '@comp/LoadingOverlay'
import NoDataPlaceholder from '@comp/NoDataPlaceholder'
import JobService from '@serv/JobService'
import JobListing from '@comp/listing/JobListing'
import JobDetail from '@comp/detail/JobDetail'
import NotifyService from '@serv/NotifyService'
import FormMixin from '@mixins/FormMixin'
import Redirect from '@serv/Redirect'
import Vue from 'vue'

export default {
    name: 'MyJobOpeningsView',
    metaInfo: 'My Job Openings',
    components: {
        JobDetail,
        JobListing,
        LoadingOverlay,
        NoDataPlaceholder
    },
    mixins: [FormMixin],
    computed: {
        ...mapGetters(['user', 'auth']),
        currentScope() {
            return this
        }
    },
    data() {
        return {
            list: [],
            filteredList: [],
            modalObject: {
                id: null
            },
            loaded: false,
            listFilter: 'All',
            noOtherListings: true
        }
    },
    methods: {
        getList() {
            JobService.getMyJobOpenings()
                .then(jobs => {
                    this.list = jobs
                    this.filteredList = jobs
                })
                .finally(() => {
                    this.loaded = true
                })
        },
        openPreview(property) {
            this.$bvModal.show(`preview-modal`)
            this.modalObject = property
        },
        filterList() {
            var listFilter = this.listFilter
            var filteredList = this.list
            if (listFilter != 'All') {
                filteredList = filteredList.filter(
                    ({ status }) => status == this.listFilter
                )
            }
            this.filteredList = filteredList
            this.noOtherListings = this.list.length < 1
        },
        filterToggle(filter) {
            this.listFilter = filter
            this.filterList()
        },
        actionOptions(property) {
            let array = [{ label: 'Delete' }, { label: 'Edit' }, { label: 'Unpublish' }]
            let arrayDraft = [
                { label: 'Delete' },
                { label: 'Edit' },
                { label: 'Publish' }
            ]
            return property.status == 'Published' ? array : arrayDraft
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        isDraft(property) {
            return property.status == 'Unpublished'
        },
        deleteAction() {
            this.$bvModal.hide(`preview-modal`)
            JobService.deleteJobOpening(this.modalObject.id).then(() => {
                this.list.splice(
                    this.list.findIndex(i => {
                        return i.id === this.modalObject.id
                    }),
                    1
                )
                //this.$store.commit('addListingAllowed', this.user)
            })
            NotifyService.notify(this, 'Opening removed successfully', 'success')
        },
        callAction(actionName, property) {
            this.modalObject = property

            if (actionName == 'Delete') {
                this.$bvModal.show('delete-modal')
            } else if (actionName == 'Edit') {
                const editId = property.id
                this.$router.push({
                    name: 'EditOpening',
                    params: { id: editId }
                })
            } else if (actionName == 'Publish') {
                this.$bvModal.show(`publish-modal`)
            } else if (actionName == 'Unpublish') {
                this.$bvModal.show('unpublish-modal')
            }
        },
        publishProperty(toPublish) {
            var property = this.modalObject
            JobService.changeListingStatus(toPublish, property).then(res => {
                NotifyService.notify(this, 'Opening updated successfully', 'success')

                const index = this.list.findIndex(x => x.id == property.id)
                Vue.set(this.list, index, res)
                this.$bvModal.hide(`preview-modal`)
            })
        }
    },
    beforeMount() {
        if (this.user) {
            this.getList()
        }
    },
    mounted() {},
    watch: {
        list() {
            this.filterList()
        }
    }
}
</script>

<style lang="scss" scoped>
@import './../assets/sass/pages/_list.scss';
</style>
<style lang="scss">
.listing-actions {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
}
.listing-action.btn {
    min-height: 0;
    padding: 5px;
    border: 0;
}
.listing-action.btn + .listing-action.btn {
    margin-left: 5px;
}
.listing-item-container {
    position: relative;
}
.listing-item-container .card-title {
    padding-right: 90px;
    width: 100%;
}

.big-modal {
    min-width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    height: 100vh !important;
    display: contents !important;
    position: fixed !important;
    overflow-y: scroll !important;
    z-index: 100000 !important;
}
.add-property-cta {
    cursor: pointer;
    font-weight: bold;
}
</style>
