var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"listings pb-5"},[_c('h1',{staticClass:"page-title mb-3 text-left"},[_vm._v(" My Searches ")]),(!_vm.loaded)?_c('loading-overlay',{staticClass:"loading-list",attrs:{"state":!_vm.loaded}}):_vm._e(),(0 < _vm.list.length && _vm.loaded)?_c('b-row',{attrs:{"cols":"1","cols-md":"2","cols-lg":"3"}},_vm._l((_vm.list),function(savedSearch,idx){return _c('b-col',{key:idx,staticClass:"my-3"},[_c('b-card',{staticClass:"h-100 border-0 text-center position-relative",attrs:{"title":savedSearch.name,"tag":"article","body-class":"p-0"}},[_c('b-link',{staticClass:"m-3 position-absolute top-0 end-0",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$bvModal.show(("delete-search-modal-" + idx))}}},[_c('b-icon',{staticClass:"text-white bg-danger rounded-circle",attrs:{"icon":"x","font-scale":"1.5"}})],1),_c('b-card-text',{staticClass:"px-3 pb-5 mb-5"},[_c('ul',{staticClass:"card-list card-list--md mx-auto text-start"},[_vm._l((savedSearch.search),function(searchParameter,idx){return [(searchParameter.name !== 'coords')?_c('li',{key:idx,staticClass:"mb-2",class:{
                                    'd-flex align-items-center justify-content-between':
                                        searchParameter.name !== 'address'
                                }},[_c('strong',{staticClass:"card-list__title",class:{
                                        'mb-1': searchParameter.name === 'address'
                                    }},[_vm._v(_vm._s(_vm.formatCamelCaseLabel(searchParameter.label)))]),_c('span',{staticClass:"ps-2 text-muted d-block"},[(_vm.isBoolean(searchParameter.value))?[_vm._v(" "+_vm._s(_vm.booleanCheck(searchParameter.value))+" ")]:_vm._e(),(
                                            Array.isArray(searchParameter.value) &&
                                                typeof searchParameter.value[0] ==
                                                    'string'
                                        )?_vm._l((searchParameter.value),function(value,idx){return _c('span',{key:idx},[_vm._v(" "+_vm._s(value)+" ")])}):_vm._e(),(
                                            typeof searchParameter.value[0] ==
                                                'number'
                                        )?[_vm._v(" "+_vm._s(_vm.valueBuild(searchParameter.value[1]))+" ")]:_vm._e(),(
                                            typeof searchParameter.value == 'number'
                                        )?[_vm._v(" "+_vm._s(searchParameter.value)+" ")]:_vm._e(),(
                                            !Array.isArray(searchParameter.value) &&
                                                typeof searchParameter.value[0] ==
                                                    'string'
                                        )?[(searchParameter.name === 'type')?[_vm._v(" "+_vm._s(_vm.typeDisplayName( searchParameter.value ))+" ")]:[_vm._v(" "+_vm._s(searchParameter.value)+" ")]]:_vm._e()],2)]):_vm._e()]})],2)]),_c('b-card-footer',{staticClass:"w-100 py-3 border-gray-200 position-absolute bottom-0 has-border"},[_c('b-button',{staticClass:"w-100 text-capitalize",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.goToListSearch(savedSearch.search)}}},[_vm._v("Use search")])],1)],1),_c('b-modal',{staticClass:"centered",attrs:{"size":"md","id":("delete-search-modal-" + idx),"return-focus":"body","ok-title":"Delete"},on:{"ok":function($event){return _vm.removeSearch(savedSearch.id)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v("Delete Search")]},proxy:true}],null,true)},[_c('div',[_vm._v(" Are you sure you want to delete this search?"),_c('br'),_vm._v(" This action cannot be undone. ")])])],1)}),1):_vm._e(),(!_vm.list.length && !!_vm.loaded)?_c('no-data-placeholder',{staticClass:"placeholder-search",scopedSlots:_vm._u([{key:"sub",fn:function(){return [_vm._v("You do not have any searches saved")]},proxy:true}],null,false,142916149)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }