<template>
    <b-form @submit.prevent="sendNotification()">
        <b-form-group>
            <b-form-input
                v-model="model.name"
                placeholder="Name"
            ></b-form-input>
        </b-form-group>

        <b-form-group>
            <b-form-input v-model="model.phone" placeholder="Phone Number"></b-form-input>
        </b-form-group>

        <b-form-group>
            <b-form-input
                type="email"
                v-model="model.email"
                placeholder="Email"
            ></b-form-input>
        </b-form-group>

        <b-form-group>
            <b-form-textarea
                v-model="model.body"
                placeholder="Message"
                rows="7"
                class="textarea"
            ></b-form-textarea>
        </b-form-group>

        <div class="d-flex align-items-center justify-content-b">
            <b-button type="submit">Submit</b-button>
        </div>
    </b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import UserService from '@serv/UserService'
import NotifyService from '@serv/NotifyService'

export default {
    name: 'ContactForm',
    data() {
        return {
            model: {
                email: null
            }
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        sendNotification() {
            UserService.contactNotification(this.model).then(() => {
                this.model = {
                    email: this.user ? this.user.email : null
                }

                NotifyService.notify(
                    this,
                    'Your contact details have been sent !',
                    'success'
                )
            })
        }
    },
    mounted() {
        if (this.user) this.model.email = this.user.email
    }
}
</script>
