<template>
    <div>
        <upload
            class="upload-property"
            :auto-upload="false"
            :file-list="fileList"
            :limit="limit > 1 ? limit : 0"
            :on-exceed="onExceed"
            :on-change="onChange"
            :show-file-list="false"
            action=""
            list-type="picture"
            accept="image/*,.heic"
            ref="uploader"
            :disabled="disabled"
            drag
            :multiple="limit > 1 ? true : false"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                Drop file here or
                <em>Click to upload</em>
                <br />
                (600 x 400)
                <br />
                Max : {{ limit }} {{ limit === 1 ? 'image' : 'images' }}
            </div>
            <div slot="tip" class="el-upload__tip">
                Accepted file types: jpg, png, gif
            </div>
        </upload>

        <draggable
            v-model="innerValue"
            :component-data="getComponentData"
            draggable="div"
            class="image__list mt-4 d-flex align-items-center flex-wrap"
        >
            <div
                v-for="fileItem in innerValue"
                :key="fileItem.uid"
                :style="{ backgroundImage: `url(${fileItem.url})` }"
                class="image__item card m-2 border-0 position-relative"
            >
                <b-icon
                    icon="arrows-move"
                    font-scale="1"
                    class="text-gray-700 m-2 position-absoute top-0 start-0"
                ></b-icon>
                <a
                    @click.stop.prevent="handleRemove(fileItem)"
                    href
                    class="text-danger m-1 position-absolute top-0 end-0"
                >
                    <b-icon icon="x" font-scale="1.5"></b-icon>
                </a>
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import { Upload, MessageBox } from 'element-ui'
import NotifyService from '@serv/NotifyService'

export default {
    props: {
        value: Array,
        limit: {
            type: Number,
            default: 10
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        draggable,
        Upload
    },
    data() {
        return {
            innerValue: [],
            images: [],
            fileList: []
        }
    },
    computed: {
        getComponentData() {
            return {
                on: {
                    sort: this.handleSort
                },
                attrs: {
                    wrap: true
                }
            }
        }
    },
    methods: {
        onChange(file, fileList) {
            const idx = fileList.findIndex(item => item.uid === file.uid)
            const reader = new FileReader()
            if (this.limit === 1) {
                this.clear()
            }

            if (
                0 > file.raw.type.indexOf('image/') &&
                !file.raw.name.endsWith('.heic')
            ) {
                NotifyService.notify(this, 'Invalid file type', 'danger')
                return
            }

            reader.readAsDataURL(file.raw)
            reader.onload = e => {
                file.image = e.target.result

                if (0 <= idx) file.orderNumber = idx

                this.innerValue.push(file)

                this.$emit('input', this.innerValue)
            }
        },
        onExceed(files, fileList) {
            if (this.limit === 1) {
                return
            }
            MessageBox.alert(
                `The limit is ${this.limit}, you selected ${
                    files.length
                } files this time, add up to ${files.length + fileList.length} totally`
            )
        },
        handleRemove(file, fileList) {
            MessageBox.confirm(
                `Are you sure you want to remove this photo? <img src="${file.url}" class="mt-2 img img-thumbnail"/>`,
                { dangerouslyUseHTMLString: true }
            ).then(rs => {
                if ('confirm' !== rs) return
                this.removeFile(file, fileList)
            })
        },
        removeFile(file, fileList) {
            const type = file.id ? 'id' : 'uid'
            const fileToRemoveIndex = this.innerValue.findIndex(
                item => file[type] === item[type]
            )

            if (0 <= fileToRemoveIndex) this.innerValue.splice(fileToRemoveIndex, 1)
            else this.innerValue = fileList

            this.$emit('input', this.innerValue)
        },
        clear() {
            this.$refs['uploader'].clearFiles()
            this.innerValue = []
            this.fileList = []
        },
        handleSort() {
            this.innerValue.forEach((file, fileIdx) => {
                const idx = this.innerValue.findIndex(item => item.uid === file.uid)

                if (0 <= idx) this.innerValue[idx].orderNumber = fileIdx
            })

            this.$emit('input', this.innerValue)
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                if (!newVal.length) return
                this.innerValue = newVal.map((file, fileIdx) => {
                    file.orderNumber = fileIdx
                    return file
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.image {
    &__list {
        margin: 0 -10px;
    }

    &__item {
        width: calc(25% - 20px);
        height: 100px;
        margin: 10px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: grab;

        &.sortable {
            &-ghost {
                background-image: none !important;
                border: 2px dashed #dee2e6 !important;
                cursor: grabbing;

                * {
                    opacity: 0;
                }
            }
        }
    }
}
</style>
