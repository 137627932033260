<template>
    <div class="login-form d-flex align-items-center justify-content-center mb-4 mt-4">
        <b-container>
            <h3 class="mb-4 ff-secondary fs-title text-center">
                {{ loginText }}
            </h3>
            <b-card no-body class="py-2 px-2 px-lg-0 mb-0 border-0">
                <b-card-body class="p-4 p-lg-5 text-center">
                    <validation-observer
                        ref="formValidator"
                        v-slot="{ invalid }"
                        tag="form"
                        @submit.prevent="onSubmit()"
                    >
                        <div v-if="!registerToggle">
                            <t-input
                                alternative
                                class="mb-4"
                                name="email"
                                placeholder="Email"
                                v-model="form.email"
                                autocomplete="formname"
                                mode="lazy"
                                :rules="{ required: true, email: true }"
                            />

                            <t-input
                                v-if="!!passwordToggle"
                                alternative
                                class="mb-4"
                                name="password"
                                :rules="{ required: true }"
                                placeholder="Password"
                                type="password"
                                v-model="form.password"
                                autocomplete="current-password"
                            />

                            <p
                                class="mb-4 password-link pointer text-end"
                                @click="forgotPasswordToggle()"
                            >
                                {{ forgotPassword }}
                            </p>

                            <t-button
                                type="secondary"
                                native-type="submit"
                                size="sm"
                                class="w-75 text-white mx-auto text-uppercase"
                                :loading="loading"
                                :disabled="invalid"
                                >{{ submit }}</t-button
                            >

                            <p
                                class="register-link pointer mt-4 mb-0"
                                @click="goToRegister()"
                            >
                                {{ register }}
                            </p>
                        </div>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import AuthService from '@serv/AuthService'
import Redirect from '@serv/Redirect'
import NotifyService from '@serv/NotifyService'

export default {
    name: 'Login',
    metaInfo: {
        title: 'Login'
    },
    data() {
        return {
            loading: false,
            form: {
                email: '',
                password: ''
            },
            passwordToggle: true,
            registerToggle: false
        }
    },
    computed: {
        forgotPassword() {
            return !!this.passwordToggle ? 'Forgot Password ?' : 'Back to login'
        },
        submit() {
            return !!this.passwordToggle ? 'Log In' : 'Submit'
        },
        loginText() {
            return !!this.passwordToggle ? 'Login' : 'Forgot Password'
        },
        register() {
            return !!this.registerToggle
                ? 'Back to login'
                : "Don't have an account yet? Sign up here."
        }
    },
    methods: {
        forgotPasswordToggle() {
            this.passwordToggle = !!this.passwordToggle ? false : true
            this.registerToggle = false
        },
        goToRegister() {
            Redirect.gotoName('Register')
        },
        onSubmit() {
            this.loading = true
            this.errors = {}
            const isValid = this.$refs.formValidator.validate()
            if (!isValid) {
                this.loading = false
                return
            }
            if (this.form.email && this.form.password && this.passwordToggle) {
                AuthService.login(this.form)
                    .then(() => {
                        Redirect.gotoName('Home')
                        NotifyService.notify(
                            this,
                            'You have logged in successfully',
                            'success'
                        )
                    })
                    .catch(() => {
                        NotifyService.notify(
                            this,
                            'Email or password is incorrect.',
                            'danger'
                        )
                    })
            }
            if (this.form.email && !this.passwordToggle) {
                AuthService.passwordRecovery(this.form.email)
                NotifyService.notify(
                    this,
                    'If this email has an account you will receive a link to reset your password',
                    'success'
                )
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
</style>
