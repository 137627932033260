import guards from '@serv/RouteGuards'

import Login from '@views/Login'

import Home from '@views/overview/Home'
import JobHome from '@views/overview/JobHome'
import WorkforceHome from '@views/overview/WorkforceHome'

import Register from '@views/Register'
import WorkforceRegister from '@views/WorkforceRegister'
import EmailConfirm from '@comp/EmailConfirm'
import PasswordReset from '@views/PasswordReset'
import NotFound from '@views/NotFound'
import FlaggedListings from '@views/admin/FlaggedListingsView'
import CategoryImporterView from '@views/admin/CategoryImporterView'

import Account from '@comp/Account'
import Checkout from '@comp/Checkout'

import List from '@comp/List'
import JobList from '@comp/list/JobList'
import WorkforceList from '@comp/list/WorkforceList'

import ListUser from '@comp/ListUser'
import Profile from '@comp/Profile'
import Searches from '@comp/Searches'
import Contact from '@comp/Contact'
import Favorites from '@comp/Favorites'

import Detail from '@comp/Detail'

import JobDetailView from '@views/detail/JobDetailView'
import WorkforceDetailView from '@views/detail/WorkforceDetailView'

import Create from '@comp/Create'
import Transactions from '@comp/Transactions'

import Listings from '@comp/Listings'
import MyJobOpenings from '@views/MyJobOpenings'
import EditOpening from '@comp/EditOpening'

import Edit from '@comp/Edit'
import AddResume from '@comp/AddResume'
import CreateJobOpening from '@comp/CreateJobOpening'

export default [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/listing',
        name: 'Listing',
        component: List
    },
    {
        path: '/users',
        name: 'ListUser',
        component: ListUser,
        beforeEnter: guards.authRequired
    },
    {
        path: '/flagged-listings',
        name: 'FlaggedListings',
        component: FlaggedListings,
        beforeEnter: guards.superUserRequired
    },
    {
        path: '/upload-categories',
        name: 'CategoryImport',
        component: CategoryImporterView,
        beforeEnter: guards.superUserRequired
    },
    {
        path: '/listings',
        name: 'Listings',
        component: Listings
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: guards.anonymousRequired
    },
    {
        path: '/property/:id',
        name: 'Detail',
        component: Detail
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/create',
        name: 'Create',
        component: Create
    },
    {
        path: '/edit/:id',
        name: 'Edit',
        component: Edit
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        beforeEnter: guards.authRequired
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        beforeEnter: guards.authRequired
    },
    {
        path: '/searches',
        name: 'Searches',
        component: Searches,
        beforeEnter: guards.authRequired
    },
    {
        path: '/favorites',
        name: 'Favorites',
        component: Favorites,
        beforeEnter: guards.authRequired
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        beforeEnter: guards.authRequired
    },
    {
        path: '/password/reset/confirm/:uid/:token',
        name: 'PasswordReset',
        component: PasswordReset,
        beforeEnter: guards.anonymousRequired
    },
    {
        path: '/email/confirm/:key',
        name: 'EmailConfirm',
        component: EmailConfirm
    },
    /*Workforce routes*/
    {
        path: '/job-openings',
        name: 'SearchJob',
        component: JobHome
    },
    {
        path: '/job-openings/search',
        name: 'JobListing',
        component: JobList
    },
    {
        path: '/job-openings/edit/:id',
        name: 'EditOpening',
        component: EditOpening,
        beforeEnter: guards.authRequired
    },
    {
        path: '/job-openings/view/:id',
        name: 'JobDetail',
        component: JobDetailView
    },
    {
        path: '/my-job-openings',
        name: 'MyOpenings',
        component: MyJobOpenings
    },
    {
        path: '/create-job-opening',
        name: 'CreateJobOpening',
        component: CreateJobOpening
    },
    {
        path: '/workforce',
        name: 'SearchWorkForce',
        component: WorkforceHome
    },
    {
        path: '/workforce/search',
        name: 'WorkforceListing',
        component: WorkforceList
    },
    {
        path: '/resume',
        name: 'AddResume',
        component: AddResume
    },
    {
        path: '/workforce/view/:id',
        name: 'WorkforceDetail',
        component: WorkforceDetailView
    },
    {
        path: '/workforce/register',
        name: 'WorkforceRegister',
        component: WorkforceRegister,
        beforeEnter: guards.anonymousRequired
    },
    {
        path: '/404',
        name: 'Not Found',
        component: NotFound
    },
    {
        path: '*',
        component: NotFound
    }
]
