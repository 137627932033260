export default {
    methods: {
        setPlace(place) {
            let extraFields = {}
            const isObject = 'object' === typeof place

            const addressObj = {}
            const addressHtmlArray = isObject
                ? place.adr_address.split(', ')
                : place.split(', ')

            if (
                addressHtmlArray.length &&
                addressHtmlArray.some(v => v.includes('span'))
            ) {
                for (const i in addressHtmlArray) {
                    const div = document.createElement('div')
                    div.innerHTML = addressHtmlArray[i]

                    div.childNodes.forEach(node => {
                        if (node.className) {
                            addressObj[node.className.replace('-', '_')] =
                                node.innerText
                        }
                    })
                }

                const streetAddress = addressObj.street_address || addressObj[0]
                const city = place.vicinity
                const region = addressObj[2] ? addressObj[2].split(' ')[0] : ''
                const zipcode = addressObj[2] ? addressObj[2].split(' ')[1] : ''
                const country = addressObj.country_name || addressObj[3]

                if (this.containsFilter) {
                    extraFields.city = city
                    extraFields.state = addressObj.region || region
                    extraFields.zipcode = addressObj.postal_code || zipcode
                    extraFields.country = country || ''
                } else {
                    this.model.formattedStreetAddress = isObject
                        ? place.formatted_address
                        : place
                    this.model.streetAddress = streetAddress || ''
                    this.model.city = city || ''
                    this.model.state = addressObj.region || region
                    this.model.zipcode = addressObj.postal_code || zipcode
                    this.model.country = country || ''

                    if (isObject) {
                        this.model.coords = {
                            lat: place.geometry.location.lat(),
                            long: place.geometry.location.lng()
                        }
                    }
                }
            }
            var extraFieldsKeys = Object.keys(extraFields)
            if (extraFieldsKeys.length) {
                extraFieldsKeys.forEach(field => {
                    if (typeof this.removeListFilter === 'function') {
                        this.removeListFilter(field)
                    } else {
                        this.$store.commit('removeListFilter', field)
                    }
                    if (extraFields[field]) {
                        if (typeof this.addListFilter === 'function') {
                            this.addListFilter({
                                name: field,
                                value: extraFields[field]
                            })
                        } else {
                            this.$store.commit('addListFilter', {
                                name: field,
                                value: extraFields[field]
                            })
                        }
                    }
                })
            }
            if (this.containsFilter) {
                this.search = place.formatted_address
                if (this.addListFilter) {
                    this.addListFilter({
                        name: 'coords',
                        value: [
                            place.geometry.location.lat(),
                            place.geometry.location.lng()
                        ]
                    })
                } else {
                    this.$store.commit('addListFilter', {
                        name: 'coords',
                        value: [
                            place.geometry.location.lat(),
                            place.geometry.location.lng()
                        ]
                    })
                }

                this.$emit('address-changed', {
                    name: 'address',
                    value: place.formatted_address
                })
            }
        }
    }
}
