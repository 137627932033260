import TInput from '@template/TInput'
import TButton from '@template/TButton'
import TCheckbox from '@template/TCheckbox'
import TSlider from '@template/TSlider'
import Vue from 'vue'

Vue.component(TInput.name, TInput)
Vue.component(TButton.name, TButton)
Vue.component(TCheckbox.name, TCheckbox)
Vue.component(TSlider.name, TSlider)
