import Vue from 'vue'

/* eslint-disable */
class ErrorService {
    constructor() {
        this.errors = []

        Vue.prototype.$errorService = this
        Vue.observable(this)
    }

    catch(err) {
        err = err.error || err

        if (err.detail)
            this.errors.push({
                type: 'non-field',
                message: err.detail
            })
        else if (err.nonFieldErrors)
            this.errors = this.errors.concat(
                err.nonFieldErrors.map(item => ({
                    type: 'non-field',
                    message: item
                }))
            )
        else
            Object.keys(err).forEach(key => {
                if ('object' == typeof err[key])
                    this.errors = this.errors.concat(
                        err[key].map(item => ({
                            type: 'field',
                            field: key,
                            message: item
                        }))
                    )
                else
                    this.errors.push({
                        type: 'field',
                        field: key,
                        message: err[key]
                    })
            })

        return this
    }

    clear() {
        this.errors = []

        return this
    }
}

export default new ErrorService()
