<template>
    <div
        class="
			m-auto
			py-5 py-md-0
			d-flex
			align-items-center
			justify-content-center
			text-center
		"
    >
        <b-container>
            <h1 class="text-gray-600 ff-primary fw-bold">Error 404</h1>
            <p>Page not found.</p>

            <b-button
                :to="{ name: 'Home' }"
                type="secondary"
                size="sm"
                class="py-2 px-4 mt-4"
                >Back to Home</b-button
            >
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'NotFound',
    metaInfo: {
        title: 'Not Found'
    },

    computed: {
        ...mapGetters(['user'])
    }
}
</script>
