<template>
    <b-card
        no-body
        class="list-card horizontal-card d-flex flex-row"
        @click="handleClick"
        title="View more details."
    >
        <div class="thumbnail pe-3">
            <b-card-img v-if="thumbnail" :src="thumbnail" :alt="title"></b-card-img>
            <div v-else></div>
        </div>
        <div class="body">
            <h4 class="card-title">
                {{ title }}
                <span v-if="badge.length" class="card-title-badge">{{ badge }}</span>
            </h4>

            <h6 v-if="companyName" class="card-subtitle text-muted mb-2">
                {{ companyName }}
            </h6>

            <b-card-text v-if="areaLocation" class="card-subtitle mb-2">
                {{ areaLocation }}
            </b-card-text>

            <b-card-text v-if="compensation" class="card-subtitle text-muted mb-2"
                >Compensation: <b>{{ compensation }}</b></b-card-text
            >
            <listing-excerpt
                class="pre-formatted"
                v-if="description"
                :text="description"
                @click:expand="onClickExpand"
            />
        </div>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ListingExcerpt from '@comp/ListingExcerpt'
import { makeLocationString } from '@util/helpers'

export default {
    name: 'JobListing',
    data() {
        return {
            area: undefined
        }
    },
    components: { ListingExcerpt },
    computed: {
        ...mapGetters(['preferedAreaById']),
        areaLocation() {
            var area = this.preferedAreaId
            if (!area) {
                return ''
            }
            if (area && typeof area == 'number') {
                area = this.preferedAreaById(area)
            }
            return makeLocationString(area)
        }
    },
    props: {
        id: Number,
        thumbnail: String,
        title: String,
        companyName: String,
        preferedAreaId: [Number, Object],
        description: String,
        compensation: String,
        badge: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt)
        },
        onClickExpand() {
            this.$emit('click:expand')
        }
    },
    beforeMount() {}
}
</script>
