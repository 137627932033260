class Space {
    constructor(object) {
        const space = object.space || object

        Object.keys(space).forEach(key => {
            this[key] = space[key]
        })
        this.listingId = object.id
        this.listingStatus = object.status
    }
}

export default Space
