<template>
    <div class="workforce-detail-view">
        <workforce-detail />
    </div>
</template>

<script>
import WorkforceDetail from '@comp/detail/WorkforceDetail'
export default {
    name: 'WorkforceDetailView',
    components: { WorkforceDetail }
}
</script>
