import axios from '@serv/AxiosService'
import TokenService from '@serv/TokenService'
import Redirect from '@serv/Redirect'
import store from '@store'
import User from '@models/User'

class AuthService {
    login(user) {
        return axios
            .post('auth/login/', user)
            .then(res => {
                const details = res.data
                if (details.accessToken) {
                    TokenService.setLocalUserTokens({
                        accessToken: details.accessToken,
                        refreshToken: details.refreshToken
                    })
                    const user = new User(res.data.details)
                    store.commit('setUser', user)
                    store.commit('setAuth', true)
                }
            })
            .catch(error => {
                TokenService.removeLocalUserTokens()
                throw error
            })
    }
    logout() {
        TokenService.removeLocalUserTokens()
        store.commit('logout')
        Redirect.gotoName('Home')
    }

    passwordRecovery(email) {
        return axios.post('/auth/password/reset/', { email: email }).catch(error => {
            throw error
        })
    }

    passwordReset(payload) {
        return axios.post('/auth/password/reset/confirm/', payload).catch(error => {
            throw error
        })
    }
}

export default new AuthService()
