var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 m-auto py-5 py-md-4 align-items-center justify-content-center"},[_c('CouponBanner'),(!!_vm.banner)?_c('banner',{attrs:{"bannerText":'MATCHMAKER4BUSINESS'}}):_vm._e(),_c('div',{staticClass:"register m-auto d-flex align-items-center justify-content-center",class:['form-page-' + _vm.formPage]},[_c('b-container',{staticClass:"w-100 h-100",style:(_vm.quickCenter)},[(_vm.formPage == 0)?[(
                            _vm.showSpacesPriceBoxes() ||
                                (!_vm.$route.query.workforceSignUp &&
                                    _vm.isInvalidReferrerRoute())
                        )?_c('product-type',{on:{"changepage":function($event){return _vm.formIndex(true)}}}):_vm._e(),(_vm.showWorkforcePriceBoxes() || _vm.isWorkforceSingup())?_c('workforce-product-type',{staticClass:"mt-5",attrs:{"includeFindJobs":_vm.includeFindJobs,"includePostJobs":_vm.includePostJobs},on:{"changepage":function($event){return _vm.formIndex(true)}}}):_vm._e()]:_vm._e(),(_vm.formPage == 1)?[_c('information',{on:{"fully-scrolled":_vm.handleInfoScroll}}),_c('div',{staticClass:"mt-4 d-sm-flex justify-content-between"},[_c('b-button',{staticClass:"w-100 mb-2 mb-sm-0 mx-auto mx-sm-0 d-block",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.formIndex(false)}}},[_vm._v("Back")]),_c('b-button',{staticClass:"mx-auto mx-sm-0 d-block",attrs:{"disabled":!_vm.termsBottom,"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.formIndex(true)}}},[_vm._v("I accept the Terms of Use")])],1)]:_vm._e(),(_vm.formPage == 2)?[_c('div',{staticClass:"account-form"},[_c('h3',{staticClass:"\n\t\t\t\t\t\t\t\tmb-4\n\t\t\t\t\t\t\t\tff-secondary\n\t\t\t\t\t\t\t\tfs-title\n\t\t\t\t\t\t\t\ttext-center\n\t\t\t\t\t\t\t"},[_vm._v(" Account Information ")]),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"px-0"},[(!_vm.selectedWorkforceProduct)?_c('v-select',{staticClass:"mb-3",attrs:{"value":_vm.selectedTypeOption,"placeholder":"Practice","options":_vm.typeOptions},on:{"input":function (option) { return _vm.setPractice(
                                                    !option ? null : option.value
                                                ); }}}):_vm._e(),_c('b-row',[_c('b-col',[_c('t-input',{staticClass:"mb-3",attrs:{"alternative":"","name":"firstName","label":"First Name","autocomplete":"formname","mode":"lazy","rules":{
                                                    required: true
                                                }},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1),_c('b-col',[_c('t-input',{staticClass:"mb-3",attrs:{"alternative":"","name":"lastName","label":"Last Name","autocomplete":"formname","mode":"lazy","rules":{
                                                    required: true
                                                }},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1),_c('t-input',{staticClass:"mb-2",attrs:{"alternative":"","name":"email","label":"Email","autocomplete":"formname","mode":"lazy","rules":{ required: true, email: true }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-text',{staticClass:"mb-3",attrs:{"id":"email-help-block"}},[_vm._v(" We will send you a confirmation email from "),_c('a',{attrs:{"href":("mailto:" + (_vm.getEnv(
                                                        'VUE_APP_SUPPORT_EMAIL'
                                                    )))}},[_vm._v(_vm._s(_vm.getEnv('VUE_APP_SUPPORT_EMAIL')))])])],1),_c('b-row',[_c('b-col',[_c('t-input',{staticClass:"mb-3",attrs:{"alternative":"","name":"password1","rules":{
                                                    required: true,
                                                    minLength: 8
                                                },"label":"Password","type":"password","autocomplete":"new-password"},model:{value:(_vm.form.password1),callback:function ($$v) {_vm.$set(_vm.form, "password1", $$v)},expression:"form.password1"}})],1),_c('b-col',[_c('t-input',{staticClass:"mb-3",attrs:{"alternative":"","name":"password2","label":"Confirm Password","rules":{
                                                    confirmed: 'password',
                                                    required: true,
                                                    minLength: 8
                                                },"type":"password","autocomplete":"new-password"},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('t-input',{staticClass:"mb-3 coupon-field",attrs:{"alternative":"","rules":"coupon","name":"couponCode","label":"Coupon (When Available)","type":"text"},model:{value:(_vm.form.couponCode),callback:function ($$v) {_vm.$set(_vm.form, "couponCode", $$v)},expression:"form.couponCode"}})],1)],1)],1),_c('div',{staticClass:"mt-4 d-sm-flex justify-content-between"},[_c('b-button',{staticClass:"w-100 mb-2 mb-sm-0 mx-auto mx-sm-0 d-block",attrs:{"variant":"outline-secondary","siz":"sm"},on:{"click":function($event){return _vm.formIndex(false)}}},[_vm._v("Back")]),_c('b-button',{staticClass:"w-100 mx-auto mx-sm-0 d-block",attrs:{"disabled":!!_vm.loading || !_vm.isRefValid('formValidator'),"variant":"secondary","size":"sm","type":"submit"}},[_vm._v("Submit")])],1)],1)]}}],null,false,2183132492)}),_c('checkout')],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }