<template>
    <div class="workforce-form-page create-job-opening">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div class="d-flex align-items-center py-4 py-lg-4">
                        <b-link @click="redirect('MyOpenings')" class="me-4 fs-5">
                            <b-icon icon="arrow-left" class="me-1"></b-icon> Back
                        </b-link>
                        <h2 class="mb-0 text-primary text-left">
                            {{ this.edit ? 'Edit Opening' : 'New Opening' }}
                        </h2>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12 mt-3">
                    <h3 class="h4 text-gray-800 mb-4 fw-bold">Job Description</h3>
                </b-col>
            </b-row>
            <validation-observer ref="createJobOpeningValidator" tag="form">
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="jobTitle"
                                    :rules="{ required: true }"
                                    label="Job Title"
                                    placeholder="Enter Job Title"
                                    type="text"
                                    v-model="model.title"
                                />
                            </b-col>
                            <b-col cols="12" lg="6">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="companyName"
                                    label="Company Name (optional)"
                                    placeholder="Enter Company Name"
                                    type="text"
                                    v-model="model.companyName"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group class="mb-3 mb-lg-4">
                            <template #label>
                                Description
                                <small class="text-danger">*</small>
                            </template>
                            <validation-provider
                                rules="required"
                                name="description"
                                v-bind="$attrs"
                                v-slot="{ errors, validated }"
                            >
                                <b-textarea
                                    class="text-area"
                                    required="required"
                                    rules="required"
                                    :class="{
                                        'is-invalid': errors.length && validated
                                    }"
                                    v-model="model.description"
                                    rows="10"
                                    cols="50"
                                ></b-textarea>

                                <div v-if="0 < errors.length" class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <category-select
                            :categories="this.fieldTypes"
                            categoryInputLabel="Category"
                            subcategoryInputLabel="Subcategory"
                            :category="this.category"
                            :subcategory="this.subcategory"
                            @set-category="
                                category => {
                                    this.setCategory(category)
                                }
                            "
                            @set-subcategory="
                                subcategory => {
                                    this.setSubcategory(subcategory)
                                }
                            "
                        />
                    </b-col>
                    <b-col cols="12" class="mb-5">
                        <b-row>
                            <b-col cols="6">
                                <label class="bv-no-focus-ring col-form-label pt-0"
                                    >Photos of the Workspace</label
                                >
                                <ImagesUploader
                                    v-model="fileList"
                                    :limit="1"
                                    :disabled="processing"
                                />
                            </b-col>
                            <b-col cols="6">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="compensation"
                                    label="Compensation"
                                    placeholder="Enter Compensation"
                                    type="text"
                                    v-model="model.compensation"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12">
                        <h3 class="h4 text-gray-800 mb-4 fw-bold">Location</h3>
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-form-group class="mb-3 mb-lg-4">
                                    <label for="workerLocation" class="form-label">
                                        State - City (Type in your location and select
                                        from the dropdown)
                                    </label>
                                    <place-search
                                        @change="place => onPlaceChanged(place)"
                                        :value="formattedArea"
                                        :class="{
                                            'is-invalid': serverErrors['invalidArea']
                                        }"
                                    />
                                    <div
                                        v-if="serverErrors['invalidArea']"
                                        class="invalid-feedback d-block"
                                    >
                                        {{ serverErrors['invalidArea'] }}
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="zipCode"
                                    label="Zip Code"
                                    placeholder="Enter Zip Code"
                                    type="text"
                                    v-model="model.zipcode"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12">
                        <h3 class="h4 text-gray-800 mb-4 fw-bold">Contact</h3>
                        <b-row>
                            <b-col cols="12" lg="6">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="firstName"
                                    label="First Name"
                                    placeholder="Enter First Name"
                                    type="text"
                                    v-model="model.firstName"
                                />
                            </b-col>
                            <b-col cols="12" lg="6">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Enter Last Name"
                                    type="text"
                                    v-model="model.lastName"
                                />
                            </b-col>
                            <b-col cols="12" lg="6" class="mb-3 mb-lg-4">
                                <t-input
                                    alternative
                                    name="email"
                                    label="Email"
                                    placeholder="Enter Email"
                                    type="email"
                                    v-model="model.email"
                                />
                            </b-col>
                            <b-col cols="12" lg="6">
                                <t-input
                                    alternative
                                    name="phone"
                                    label="Phone Number"
                                    placeholder="Enter Phone Number (e.g. +12125552368)"
                                    type="phone"
                                    v-model="model.phoneNumber"
                                    :inputClasses="
                                        serverErrors['phoneNumber'] ? 'is-invalid' : ''
                                    "
                                />
                                <div
                                    v-if="serverErrors['phoneNumber']"
                                    class="invalid-feedback d-block"
                                >
                                    {{ serverErrors['phoneNumber'][0] }}
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12 mb-5">
                        <b-row>
                            <b-col cols="12" lg="6">
                                <b-button
                                    class="mt-3 py-3 text-capitalize w-100"
                                    @click="previewListing"
                                    block
                                    variant="outline-primary"
                                    >Preview Opening
                                </b-button>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-row class="g-2">
                                    <b-col v-if="!isPublished" cols="6">
                                        <b-button
                                            variant="outline-primary"
                                            :disabled="!this.isValid || this.processing"
                                            class="mt-3 py-3 text-capitalize w-100"
                                            @click="saveAsDraft"
                                            block
                                        >
                                            {{ saveAsDraftText }}
                                        </b-button>
                                    </b-col>
                                    <b-col :cols="!isPublished ? '6' : '12'">
                                        <b-button
                                            :disabled="!this.isValid || this.processing"
                                            class="mt-3 py-3 text-capitalize w-100"
                                            variant="accent"
                                            @click="() => publish(!this.isPublished)"
                                            block
                                            >{{ saveText }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </validation-observer>
            <b-modal
                class="centered"
                size="xl"
                id="preview-modal-resume"
                :hide-footer="true"
            >
                <template #modal-title>PREVIEW</template>
                <job-detail-basic
                    :image="(this.imageFiles.length && this.imageFiles[0].url) || ''"
                    :title="model.title"
                    :companyName="model.companyName"
                    :compensation="model.compensation"
                    :preferedAreaId="model.preferedArea"
                    :zipcode="model.zipcode"
                    :description="model.description"
                    :contactEmail="model.email"
                    :contactFirstName="model.firstName"
                    :contactLastName="model.lastName"
                    :contactPhoneNumber="model.phoneNumber"
                />
            </b-modal>
            <b-modal
                v-if="
                    this.user &&
                        this.user.checkActiveFindJobsProduct &&
                        !this.user.checkActivePostJobsProduct()
                "
                class="modal"
                id="post-jobs-subscription-modal"
                centered
                ok-title="Subscribe"
                variant="primary"
                @ok="redirect('Account')"
            >
                <template #modal-title> Workforce - Post job openings </template>
                <workforce-product-type
                    :includeFindJobs="false"
                    :includePostJobs="true"
                />
            </b-modal>
        </b-container>
    </div>
</template>
<script>
//import Schedule from '@comp/Schedule'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import JobService from '@serv/JobService'
import { mapGetters } from 'vuex'
import ImagesUploader from '@comp/ImagesUploader'
import JobDetailBasic from '@comp/detail/JobDetailBasic'
import Redirect from '@serv/Redirect'
//import Checkout from '@comp/Checkout'
import NotifyService from '@serv/NotifyService'
//import RunoutModal from '@comp/modal/RunoutModal'
//import PublishModal from '@comp/modal/PublishModal'
//import TypeModal from '@comp/modal/TypeModal'
import FormMixin from '@mixins/FormMixin'
import WorkforceProductType from '@comp/WorkforceProductType'
import CategorySelect from '@comp/CategorySelect'
import PlaceSearch from '@comp/PlaceSearch'
import { debounce, makeLocationString } from '@util/helpers'
import PreferedAreaService from '@serv/PreferedAreaService'

locale.use(lang)

export default {
    name: 'CreateJobOpening',
    components: {
        ImagesUploader,
        //Schedule,
        //RunoutModal,
        //PublishModal,
        //TypeModal,
        //Checkout,
        CategorySelect,
        JobDetailBasic,
        WorkforceProductType,
        PlaceSearch
    },
    mixins: [FormMixin],
    data() {
        return {
            isValid: false,
            model: {
                description: '',
                title: '',
                companyName: '',
                category: undefined,
                subcategory: undefined,
                preferedArea: undefined,
                compensation: '',
                zipcode: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                status: ''
            },
            serverErrors: {},
            processing: false,
            savingAsDraft: false,
            publishing: false,
            place: undefined,
            category: undefined,
            subcategory: undefined,
            isPublished: false,
            fileList: [],
            imageFiles: [],
            edit: false
        }
    },
    props: {
        form: {
            type: Object
        },
        create: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters(['user', 'preferedAreaById', 'queryPreferedArea']),
        ...mapGetters({
            types: 'jobTypes',
            fieldTypeByName: 'jobFieldTypeByName',
            fieldTypeById: 'jobFieldTypeById',
            categories: 'jobCategories',
            subcategoriesOf: 'jobSubcategoriesOf',
            fieldTypes: 'jobFieldTypes'
        }),
        formattedArea() {
            if (!this.place) {
                return ''
            }
            if (this.place.inputValue) {
                return this.place.inputValue
            } else {
                return makeLocationString(this.place)
            }
        },
        saveAsDraftText() {
            if (this.processing && this.savingAsDraft) {
                return 'Saving Draft'
            }
            return 'Save as Draft'
        },
        saveText() {
            if (this.processing && this.publishing) {
                if (this.isPublished) {
                    return 'Saving Opening'
                }
                return 'Publishing Opening'
            }
            if (this.isPublished) {
                return 'Save'
            } else {
                return 'Publish'
            }
        }
    },
    methods: {
        checkChangeType(value) {
            if (this.edit && value !== this.model.type) {
                this.tempTypeValue = value
                this.$bvModal.show('type-modal')
            } else {
                this.model.type = value
            }
        },
        selectState(value) {
            this.model.state = value
        },
        selectCity(value) {
            this.model.city = value
        },
        setCategory(category) {
            this.category = category
            this.model.category = category ? category.id : null
        },
        setSubcategory(subcategory) {
            this.subcategory = subcategory
            this.model.subcategory = subcategory ? subcategory.id : null
        },
        saveImages(data) {
            return JobService.setJobImages(data).then(images => {
                if (!images.length) {
                    return
                }
                this.fileList = images.map(picture => {
                    return { url: picture.image }
                })
            })
        },
        async save(status = '') {
            if (this.user.checkActivePostJobsProduct()) {
                this.processing = true
                this.serverErrors = {}

                //First try to create and set the preferred area
                try {
                    if (_.isObject(this.model.preferedArea)) {
                        const area = await PreferedAreaService.createPreferedArea(
                            this.model.preferedArea
                        )
                        this.model.preferedArea = area.id
                    }
                } catch (error) {
                    NotifyService.notify400Error(this, error)
                    this.processing = false
                    this.serverErrors = { invalidArea: error.response.data[0] }
                    return
                }

                //Then save the job opening
                let job

                try {
                    let freshModel = { ...this.model }
                    if (status) {
                        freshModel.status = status
                    }
                    job = await JobService.saveJobOpening(freshModel)
                    this.model.status = job.status
                } catch (error) {
                    this.serverErrors = error.response.data
                    this.processing = false
                    if (NotifyService.notify400Error(this, error)) {
                        return
                    }
                    NotifyService.notify(
                        this,
                        'The opening could not be updated',
                        'danger'
                    )
                    return
                }

                // Associate the images with the job opening
                try {
                    await this.saveImages({ id: job.id, images: this.imageFiles })
                    NotifyService.notify(
                        this,
                        'Opening updated successfully',
                        'success'
                    )
                    return Promise.resolve(true)
                } catch (error) {
                    NotifyService.notify(
                        this,
                        'Your opening is successfully updated, but there was an error while saving the Photos of Workspace.',
                        'warning'
                    )
                } finally {
                    this.processing = false
                }
            } else {
                this.$bvModal.show('post-jobs-subscription-modal')
                return Promise.resolve(false)
            }
        },
        saveAsDraft() {
            this.savingAsDraft = true
            this.save('Unpublished')
                .then(saved => {
                    if (saved && !this.edit) {
                        Redirect.gotoName('MyOpenings')
                    }
                })
                .finally(() => {
                    this.savingAsDraft = false
                })
        },
        publish(shouldPublish = true) {
            this.publishing = true
            const status = shouldPublish ? 'Published' : ''
            this.save(status)
                .then(saved => {
                    if (saved && shouldPublish) {
                        Redirect.gotoName('MyOpenings')
                    }
                })
                .finally(() => {
                    this.publishing = false
                })
        },
        previewListing() {
            this.$bvModal.show('preview-modal-resume')
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        manualValidation() {
            this.manualValidationDebaunce()
        },
        formatImages() {
            return this.fileList.filter(file => file.image || file.url)
        },
        setJobOpening(form) {
            var images = form.images.map(picture => {
                return { url: picture.image }
            })
            var categoryId = form.category && form.category.id
            var subcategoryId = form.subcategory && form.subcategory.id

            this.category = form.category
            this.subcategory = form.subcategory
            this.fileList = images

            this.model = { ...form, category: categoryId, subcategory: subcategoryId }

            var area = this.preferedAreaById(form.preferedArea)
            if (area) {
                this.place = area
            }
        },
        areaInput(e) {
            if (!e.target.value) {
                this.model.formattedStreetAddress = ''
                this.model.city = ''
                this.model.state = ''
                this.model.preferedArea = null
            }
        },
        onPlaceChanged(place) {
            this.place = place
        }
    },
    created() {
        this.manualValidationDebaunce = debounce(() => {
            if (this.$refs['createJobOpeningValidator']) {
                var flags = this.$refs['createJobOpeningValidator'].flags
                this.isValid = flags.valid
            }
        }, 400)
    },
    beforeMount() {
        if (this.form) {
            this.setJobOpening(this.form)
            this.edit = true
            this.isPublished = this.model.status === 'Published'
        }
    },
    mounted() {
        if (this.edit) {
            this.$nextTick(() => {
                this.manualValidationDebaunce()
            })
        }
    },
    watch: {
        model: {
            handler() {
                this.manualValidation()
            },
            deep: true
        },
        place(newVal) {
            var area = this.queryPreferedArea(newVal)
            this.model.preferedArea = area ? area.id : newVal
        },
        fileList() {
            this.imageFiles = this.formatImages()
        }
    }
}
</script>
<style lang="scss">
.create-job-opening .el-upload--picture,
.create-job-opening .el-upload-dragger {
    width: 100%;
}

.create-job-opening .clear-icon {
    cursor: pointer;
    font-size: 160%;
    position: absolute;
    right: 20px;
    top: 37px;
    display: flex;
    float: right;
    z-index: 1000;
}

#preview-modal-multi .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: contents;
    position: fixed;
}
</style>
