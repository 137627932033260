import { mapGetters } from 'vuex'
import PlaceMixin from '@mixins/PlaceMixin'

export default {
    name: 'ListFiltersBasic',
    data() {
        return {
            //isFlagged: false,
            containsFilter: true,
            search: undefined,
            categorySearch: ''
        }
    },
    mixins: [PlaceMixin],
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        onSelectCategory(category) {
            this.$emit('add-filter', {
                key: 'category',
                value: category
            })
        },
        onCategorySearchClear() {
            this.$emit('clear-filter', 'category')
        },
        clearSearchFilters() {
            this.search = undefined
            if (this.$refs.categorySearch) {
                this.$refs.categorySearch.clearInput()
            }
        },
        clearFilters() {
            this.clearSearchFilters()
            this.$emit('clear-filters')
        },
        setFilters() {
            const category = this.filters.find(s => s.name == 'category')
            if (category) {
                this.categorySearch = category.value.name
            }
            if (this.searchQuery) {
                this.search = this.searchQuery
            }
        }
    },
    beforeMount() {
        this.setFilters()
    },
    mounted() {},
    watch: {
        filters() {
            if (!this.filters.length) {
                this.clearSearchFilters()
            }
        }
    }
}
