<template>
    <validation-observer ref="addCategoryValidationObserver" tag="form">
        <b-row>
            <b-col cols="12" class="mb-3 mb-lg-4">
                <workforce-search-input
                    :value="category"
                    :isSearchingResults="false"
                    :categorySearchResults="searchResults"
                    :rules="{ required: true }"
                    placeholder="Enter Category"
                    label="Category"
                    notFoundText="No category found - A new category will be created from this input"
                    @input="onSearch"
                    @category-change="onSelect"
                />
                <div v-if="serverErrors['name']" class="invalid-feedback d-block">
                    {{ serverErrors['name'][0] }}
                </div>
            </b-col>
            <b-col cols="12">
                <div
                    v-for="(name, idx) in subcategories"
                    :key="`add_subcategories_items_${idx}`"
                >
                    <label
                        v-if="idx === 0"
                        :for="`subcategory_${idx}`"
                        class="bv-no-focus-ring col-form-label pt-0"
                    >
                        Subcategory (optional)
                    </label>
                    <div class="mb-3 mb-lg-4">
                        <div class="d-flex">
                            <div
                                class="add-subcategory-container me-2 w-100 position-relative"
                            >
                                <t-input
                                    alternative
                                    :name="`subcategory_${idx}`"
                                    placeholder="Enter Subcategory"
                                    type="text"
                                    v-model="subcategories[idx]"
                                />
                                <a
                                    v-if="name"
                                    href="javascript:;"
                                    @click="clearSubcategory(idx)"
                                    class="clear-form-input"
                                >
                                    <img
                                        :src="require('@assets/svg/times.svg')"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <b-button
                                variant="outline-primary"
                                size="sm"
                                class="text-capitalize"
                                @click="addOrRemoveSubcategory(idx)"
                            >
                                <b-icon
                                    class="add-form-input-btn"
                                    v-if="idx == 0"
                                    icon="plus"
                                    size="lg"
                                    aria-hidden="true"
                                ></b-icon>
                                <b-icon
                                    class="add-form-input-btn"
                                    v-if="idx > 0"
                                    icon="dash"
                                    size="lg"
                                    aria-hidden="true"
                                ></b-icon>
                            </b-button>
                        </div>
                        <div
                            v-if="subcategoriesErrors[idx]"
                            class="invalid-feedback d-block"
                        >
                            {{ subcategoriesErrors[idx] }}
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </validation-observer>
</template>

<script>
import NotifyService from '@serv/NotifyService'
import CategoryService from '@serv/CategoryService'
import WorkforceSearchInput from '@comp/WorkforceSearchInput'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { debounce } from '@util/helpers'

export default {
    name: 'AddCategoryForm',
    data() {
        return {
            category: '',
            categoryId: null,
            subcategories: [''],
            serverErrors: {},
            subcategoriesErrors: [],
            isValid: false
        }
    },
    components: {
        WorkforceSearchInput
    },
    computed: {
        ...mapGetters(['categories', 'categoryByName']),
        cleanCategory() {
            return this.category.trim()
        },
        searchResults() {
            if (this.categories) {
                var search = this.cleanCategory.toLowerCase()
                return this.categories.filter(({ name }) => {
                    return name.toLowerCase().includes(search)
                })
            } else {
                return []
            }
        }
    },
    methods: {
        addOrRemoveSubcategory(idx) {
            if (idx == 0) {
                this.subcategories.push('')
            } else {
                this.subcategories.splice(idx, 1)
            }
        },
        clearSubcategory(idx) {
            Vue.set(this.subcategories, idx, '')
        },
        onSearch(value) {
            this.categoryId = null
            if (!value) {
                this.category = ''
            } else {
                this.category = value
            }
        },
        onSelect(idx) {
            var category = this.searchResults[idx]
            if (category) {
                this.category = category.name
                this.categoryId = category.id
            }
        },
        submit() {
            const name = this.category
            this.subcategoriesErrors = []
            this.serverErrors = {}
            let addCategoryProcess
            //If category was selected from search
            let existingId = this.categoryId
            if (!existingId) {
                //Double check by name if category was not selected from search but is existing
                var existingCategory = this.categoryByName(name)
                existingId = existingCategory ? existingCategory.id : null
            }
            if (existingId) {
                addCategoryProcess = Promise.resolve({ id: existingId })
            } else {
                addCategoryProcess = CategoryService.addCategory({ name })
            }
            return addCategoryProcess
                .then(category => {
                    const id = category.id
                    return Promise.allSettled(
                        this.subcategories
                            .filter(name => name)
                            .map(name => {
                                return CategoryService.addSubcategory({
                                    parent: id,
                                    name
                                })
                            })
                    ).then(results => {
                        results.forEach((result, idx) => {
                            if (result.status === 'rejected') {
                                var error = result.reason.response.data
                                let errorMessage = ''
                                if (!error) {
                                    errorMessage =
                                        'And error occured while adding this subcategory.'
                                } else {
                                    if (_.isObject(error)) {
                                        errorMessage = Object.values(error)[0][0]
                                    }
                                    //Ignore this error if subcategory already exists. just act like it was successfully added
                                    /*else if (_.isString(error)) {
                                            errorMessage = error;
                                            if (errorMessage.includes('duplicate key value violates unique constraint')) {
                                                errorMessage = 'This subcategory already exist for the parent category'
                                            }
                                        }*/
                                }
                                if (errorMessage) {
                                    Vue.set(this.subcategoriesErrors, idx, errorMessage)
                                }
                            }
                        })
                        if (existingId) {
                            NotifyService.notify(
                                this,
                                'Category successfully updated',
                                'success'
                            )
                        } else {
                            NotifyService.notify(
                                this,
                                'Category successfully created',
                                'success'
                            )
                        }
                        if (this.subcategoriesErrors.length === 0) {
                            this.$emit('submit-success')
                        } else {
                            this.$emit('submit-subcategories-error')
                        }
                    })
                })
                .catch(error => {
                    if (error.response) {
                        var data = error.response.data
                        this.serverErrors = data
                    }
                    if (!NotifyService.notify400Error(this, error)) {
                        NotifyService.notify(
                            this,
                            'The category could not be added',
                            'danger'
                        )
                    }
                    this.$emit('submit-error', error)
                })
        }
    },
    created() {
        this.manualValidationDebaunce = debounce(() => {
            var flags = this.$refs.addCategoryValidationObserver.flags
            this.isValid = flags.valid
            this.$emit('validated-form', flags.valid)
        }, 400)
    },
    watch: {
        category() {
            this.manualValidationDebaunce()
        }
    }
}
</script>
