import axios from '@serv/AxiosService'
import store from '@store'
import User from '@models/User'
import TokenService from '@serv/TokenService'
class UserService {
    getUser() {
        return axios
            .get('/auth/user/')
            .then(res => {
                const user = new User(res.data)
                store.commit('setUser', user)
                store.commit('setAuth', true)
            })

            .catch(error => {
                throw error
            })
    }
    getUsers() {
        return axios.get(`/users/`).then(res => {
            return res.data
        })
    }
    patchUser(user) {
        return axios
            .patch('/auth/user/', user)
            .then(res => {
                const user = new User(res.data)
                store.commit('setUser', user)
            })

            .catch(error => {
                throw error
            })
    }
    createUser(user) {
        return axios
            .post('auth/register/', user)
            .then(res => {
                const details = res.data
                if (details.accessToken) {
                    TokenService.setLocalUserTokens({
                        accessToken: details.accessToken,
                        refreshToken: details.refreshToken
                    })
                    store.commit('setAuth', true)
                }
                const user = new User(details.user)
                store.commit('setUser', user)
                return details
            })

            .catch(error => {
                throw error.response.data
            })
    }
    changePassword(passwordForm) {
        const passwordRequest = {
            oldPassword: passwordForm.oldPassword,
            newPassword1: passwordForm.newPassword1,
            newPassword2: passwordForm.newPassword1
        }
        return axios
            .post('auth/password/change/', passwordRequest)
            .then(res => {
                return res.data
            })

            .catch(error => {
                throw error
            })
    }
    confirmEmail(payload) {
        return axios
            .post('auth/email/confirm/', payload)
            .then(res => {
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    changeStatus(row) {
        return axios
            .patch(`/users/${row.id}/toggle_active/`)
            .then(res => {
                return res.data.data
            })
            .catch(error => {
                throw error
            })
    }
    getUserProducts(id) {
        return axios
            .get(`/users/${id}/purchases`)
            .then(res => {
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    getStripeSession(priceId, couponCode) {
        let payload = { priceId: priceId }
        if (couponCode) {
            payload.couponCode = couponCode
        }
        return axios.post(`/create/stripe/session/`, payload).then(res => {
            const response = res.data.data

            return couponCode ? response : response.stripeSessionId
        })
    }
    checkoutComplete() {
        return axios.post('update/card/', { checkoutComplete: true }).catch(error => {
            throw error
        })
    }
    cardUpdate(token) {
        return axios.post('update/card/', { stripeToken: token.id }).catch(error => {
            throw error
        })
    }
    contactNotification(form) {
        return axios.post('notifications/contact/', form).then(res => {
            return res
        })
    }
}

export default new UserService()
