<template>
    <div class="search-input-container" v-click-outside="clickedOutiseHandler">
        <t-input
            alternative
            @input="handleInput"
            @keydown.enter.prevent="() => handleCategoryChange(0)"
            class=""
            name="jobtype_specility"
            :label="label"
            tab-index="1"
            :placeholder="placeholder"
            type="search"
            :rules="rules"
            :value="value"
        />
        <b-list-group class="search-input-results" v-if="showResults">
            <b-list-group-item v-if="isSearchingResults">
                <a href="javascript:;" class="text-gray-900">Searching suggestions</a>
            </b-list-group-item>
            <template v-else>
                <b-list-group-item
                    v-for="(category, idx) in categorySearchResults"
                    :key="`$category_search_result_${idx}`"
                >
                    <a
                        href="javascript:;"
                        class="text-gray-900 text-start"
                        :tab-index="idx + 2"
                        @click="handleCategoryChange(idx)"
                        v-html="displayName(category)"
                    ></a>
                </b-list-group-item>
                <b-list-group-item v-if="!categorySearchResults.length">
                    <a href="javascript:;" class="text-gray-900">{{ notFoundText }}</a>
                </b-list-group-item>
            </template>
        </b-list-group>
    </div>
</template>
<script>
import { highlight } from '@util/helpers'

export default {
    name: 'WorkforceSearchInput',
    mixins: [],
    data() {
        return {
            showResults: false
        }
    },
    props: {
        label: String,
        value: String,
        placeholder: {
            type: String,
            default: 'Enter job type and speciality'
        },
        notFoundText: {
            type: String,
            default: 'No results found'
        },
        isSearchingResults: Boolean,
        categorySearchResults: Array,
        rules: {
            type: [String, Array, Object],
            description: 'Vee validate validation rules',
            default: ''
        }
    },
    computed: {},
    methods: {
        clickedOutiseHandler() {
            this.showResults = false
        },
        displayName(category) {
            var name = category.name
            name = this.highlight(name)
            if (category.parent && category.parentName) {
                name += ` - <small class='text-muted'>${category.parentName}</small>`
            }
            return name
        },
        handleInput(value) {
            this.$emit('input', value)
            if (!value) {
                this.showResults = false
            } else {
                this.showResults = true
            }
        },
        handleCategoryChange(idx) {
            this.$emit('category-change', idx)
            this.showResults = false
        },
        highlight(str) {
            return highlight(str, this.value)
        }
    },
    mounted() {}
}
</script>
<style lang="scss" scoped>
.search-input-container {
    position: relative;
}
.search-input-results {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    padding: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    z-index: 2;
    max-height: 41vh;
    overflow: auto;
}
.search-input-results .list-group-item {
    padding: 0;
}
.search-input-results .list-group-item a:focus,
.search-input-results .list-group-item a:hover {
    background-color: #f5f5f5;
    outline: 0;
}
.search-input-results .list-group-item a {
    padding: 10px 30px;
    display: block;
    font-size: 14px;
    background-color: #fff;
}
</style>
