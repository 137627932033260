<template>
    <div class="edit-form">
        <multi-step v-if="loaded" :form="property"> </multi-step>
    </div>
</template>

<script>
import MultiStep from '@comp/MultiStep'
import PropertyService from '@serv/PropertyService'

export default {
    name: 'Edit',
    components: { MultiStep },
    data() {
        return {
            property: {},
            loaded: false
        }
    },
    beforeMount() {
        PropertyService.getProperty(
            this.$route.params.id,
            this.$route.params.isListing
        ).then(property => {
            property.timeslots.forEach(day => {
                if (day.startTime) {
                    day.startTime = day.startTime.slice(0, -3)
                }

                if (day.endTime) {
                    day.endTime = day.endTime.slice(0, -3)
                }
            })
            this.property = property
            this.loaded = true
        })
    }
}
</script>
