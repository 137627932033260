import { makeLocationString } from '@util/helpers'
const state = {
    preferedArea: [],
    preferedAreaFields: []
}

const getters = {
    preferedArea: state => {
        return state.preferedArea
    },
    preferedAreaByName: state => name => {
        return state.preferedAreaFields.find(area => area.name === name)
    },
    queryPreferedArea: state => area => {
        if (!area) {
            return
        }
        return state.preferedAreaFields.find(
            areaField => areaField.city === area.city && areaField.state === area.state
        )
    },
    preferedAreaById: state => id => {
        if (!id) {
            return
        }
        return state.preferedAreaFields.find(area => area.id === id)
    }
}

const actions = {}

const setPreferedArea = (state, preferedArea) => {
    preferedArea = preferedArea
        .map(({ state, city, id }) => ({
            id,
            city,
            state,
            name: makeLocationString({ city, state })
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    state.preferedAreaFields = preferedArea
    state.preferedArea = preferedArea.map(({ name }) => name)
}

const mutations = {
    setPreferedArea: (state, preferedArea) => {
        setPreferedArea(state, preferedArea)
    },
    pushPreferedArea: (state, preferedAreaField) => {
        setPreferedArea(state, state.preferedAreaFields.concat(preferedAreaField))
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
