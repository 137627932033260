import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Sentry from '@sentry/vue'
import Router from 'vue-router'
import {
    faListAlt,
    faHandHoldingUsd,
    faChartLine,
    faUsers,
    faAddressBook,
    faAt,
    faKey,
    faSignOutAlt,
    faCog,
    faUser,
    faExclamationCircle,
    faEnvelope,
    faLock,
    faUnlock,
    faCheckCircle,
    faExclamationTriangle,
    faUserMinus,
    faHome
} from '@fortawesome/free-solid-svg-icons'
library.add(
    faListAlt,
    faHandHoldingUsd,
    faChartLine,
    faUsers,
    faAddressBook,
    faAt,
    faKey,
    faSignOutAlt,
    faCog,
    faUser,
    faExclamationCircle,
    faEnvelope,
    faLock,
    faUnlock,
    faCheckCircle,
    faExclamationTriangle,
    faUserMinus,
    faHome
)
const directive = {
    install(Vue) {
        Vue.directive('click-outside', clickOutside)
    }
}

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places'
    },
    installComponents: true
})

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMeta from 'vue-meta'
import Notifications from '@notif'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import clickOutside from '@util/clickoutside'
import { Loading } from 'element-ui'
import vSelect from 'vue-select'
import VueFormWizard from 'vue-form-wizard'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-select/dist/vue-select.css'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@assets/sass/common.scss'

// Vue select component props
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement =>
            createElement('img', {
                domProps: { src: require('@assets/svg/times.svg'), alt: 'close' }
            })
    },
    OpenIndicator: {
        render: createElement =>
            createElement('img', {
                domProps: {
                    src: require('@assets/svg/chevron-down.svg'),
                    alt: 'chevron down'
                }
            })
    }
})
Sentry.init({
    Vue,
    dsn: 'https://1f49a700406d4e52a8d06bcd2f8a2795@o531798.ingest.sentry.io/6161309',
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    allowUrls: [/spacesmatchmaker\.com/]
})

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('v-select', vSelect)

Vue.use(Loading.directive)
Vue.use(VueFormWizard)
Vue.use(directive)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMeta, {})
Vue.use(Router)
Vue.use(Notifications)

/*
    Quick custom vee-validate rule (can be moved to its own file later.)
    Checks without case sensitivity if two strings are equal.
    Main use: Coupon code validation.
*/
extend('coupon', {
    validate: value => {
        const intendedCoupon = process.env.VUE_APP_COUPON.toLowerCase()

        return value.toLowerCase() === intendedCoupon
    },
    message: 'The {_field_} field is not valid'
})

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule],
        message: messages[rule]
    })
})

extend('regex', {
    ...rules['regex'],
    message: 'The {_field_} field is not valid'
})

extend('url', {
    validate: value => {
        if (value) {
            return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
                value
            )
        }
        return false
    },
    message: 'The {_field_} field is not a valid url'
})
extend('minLength', {
    params: ['minLength'],
    validate: (value, { minLength }) => {
        const valueLength = value.split('').length

        return valueLength >= minLength
    },
    message: 'The {_field_} field must be longer than or equal to {minLength}'
})
