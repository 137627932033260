<template>
    <div class="property-widget" v-if="displayName || phoneNumber || email">
        <div class="list-group pb-0 px-1 mb-4 shadow">
            <div class="list-group-item border-top-0 border-end-0 border-start-0">
                <h4 class="property-widget__title fw-bold">
                    Contact Information
                </h4>
                <p v-if="displayName" class="h3 mb-2 text-primary fw-bold">
                    {{ displayName }}
                </p>
                <p class="mb-2 fs-5" v-if="email">
                    <a :href="`mailto:${email}`" target="_blank">{{ email }}</a>
                </p>
                <p class="mb-0 fs-5" v-if="phoneNumber">
                    <a :href="`tel:${phoneNumber}`" target="_blank">
                        {{ phoneNumber }}
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfoComponent',
    props: {
        displayName: {
            type: String
        },
        phoneNumber: {
            type: String
        },
        email: {
            type: String
        }
    }
}
</script>
