<template>
    <div>
        <loading-overlay
            class="loading-list"
            v-if="!isLoaded"
            :state="!isLoaded"
        ></loading-overlay>
        <div v-if="isLoaded" class="edit-form">
            <create-job-opening :form="job" />
        </div>
    </div>
</template>

<script>
import CreateJobOpening from '@comp/CreateJobOpening'
import JobService from '@serv/JobService'
import LoadingOverlay from '@comp/LoadingOverlay'

export default {
    name: 'Edit',
    components: {
        CreateJobOpening,
        LoadingOverlay
    },
    data() {
        return {
            job: undefined,
            loaded: false
        }
    },
    computed: {
        isLoaded() {
            return this.loaded
        }
    },
    beforeMount() {
        JobService.getJobOpening(this.$route.params.id)
            .then(job => {
                this.job = job
            })
            .finally(() => {
                this.loaded = true
            })
    }
}
</script>
