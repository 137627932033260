import { mapGetters } from 'vuex'
import Redirect from '@serv/Redirect'
import UserService from '@serv/UserService'
//import Banner from '@comp/Banner'
import StringMixin from '@mixins/StringMixin'

export default {
    data() {
        return {
            background: {
                backgroundImage: `url(${require('@/assets/images/banner.png')})`
            },
            isProcessing: false,
            list: [],
            count: 0,
            noResult: false,
            loaded: false,
            searchString: '',
            currentPage: 1,
            savedSearchAddress: null,
            showAlert: true,
            notify: {}
        }
    },
    mixins: [StringMixin],
    props: {},
    computed: {
        ...mapGetters(['user', 'auth']),
        hasFilters() {
            return this.filters.length
        },
        isLoaded() {
            return this.loaded
        }
    },
    methods: {
        setFilter(option, type) {
            const template = {
                name: type,
                value: option
            }
            if (option == null || option.length == 0) {
                this.removeListFilter(type)
            } else {
                this.addListFilter(template)
            }
        },
        clearPlace() {
            this.loaded = false

            //this.$router.replace({ query: null })

            this.clearSearch()
            this.clearFilters()
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        setList(object) {
            this.list = object
            this.count = object.length
            this.showAlert = true
            this.loaded = true
        },
        loadListings() {
            this.isProcessing = true
            var getListProcess
            if (0 < this.filters.length) {
                getListProcess = this.getListingsByQuery(this.filtersQuery)
            } else {
                getListProcess = this.getListings()
            }
            return getListProcess
                .then(object => {
                    this.setList(object)
                })
                .finally(() => {
                    this.isProcessing = false
                })
        },
        onBasicFilter(payload) {
            this.setFilter(payload.value, payload.key)
        },
        onClearFilter(name) {
            if (name === 'category') {
                this.removeListFilter('category')
            }
        }
    },
    beforeMount() {
        //Keep the app loading overlay while list is set
        this.$bus.$emit('lock-app-loading-overlay')
        if (!!this.$route.query.stripe) {
            UserService.checkoutComplete()
        }
        this.getListingsProcess = this.loadListings()
    },
    mounted() {
        /*
        this.$bus.$on('api-request', payload => {
            this.loaded = !payload
        })
        */
        this.getListingsProcess.finally(() => {
            this.$bus.$emit('unlock-app-loading-overlay')
            this.loaded = true
        })
    },
    watch: {
        filters() {
            this.loadListings()
        }
    }
}
