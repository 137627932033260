<template>
    <div class="list pb-5">
        <b-container>
            <h1 class="page-title mb-3 text-left">
                My Favorites
            </h1>
            <loading-overlay
                class="loading-list"
                v-if="!loaded"
                :state="!loaded"
            ></loading-overlay>
            <b-row v-if="0 < list.length && loaded">
                <b-col
                    v-for="(property, idx) in list"
                    :key="idx"
                    cols="12"
                    sm="6"
                    class="mb-4"
                >
                    <b-card
                        img-top
                        tag="article"
                        body-class="p-0"
                        class="list-card h-100 border-0"
                    >
                        <div
                            class="card-featured-img"
                            :style="{
                                backgroundImage: `url(${imageOrPlaceholder(property)})`
                            }"
                        >
                            <div
                                class="action"
                                @click="favoriteProperty(property, false)"
                            >
                                <img :src="require('@assets/images/heart.svg')" />
                            </div>
                        </div>
                        <div @click="gotoProperty(property.id)">
                            <b-card-title
                                class="text-gray-900 border-0 fw-bold text-capitalize"
                                >{{ property.streetAddress }}</b-card-title
                            >
                            <b-card-text>
                                {{ property.city }} , {{ property.state }}
                            </b-card-text>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <no-data-placeholder v-if="!list.length && !!loaded">
                <template #sub>You do not have any favorites yet.</template>
            </no-data-placeholder>
        </b-container>
    </div>
</template>
<script>
import PropertyService from '@serv/PropertyService'
import NoDataPlaceholder from '@comp/NoDataPlaceholder'
import { mapGetters } from 'vuex'
import NotifyService from '@serv/NotifyService'
import LoadingOverlay from '@comp/LoadingOverlay'

export default {
    name: 'Favorites',
    metaInfo: {
        title: 'Favorites'
    },
    components: {
        LoadingOverlay,
        NoDataPlaceholder
    },
    data() {
        return {
            list: [],
            loaded: false
        }
    },
    props: {},
    computed: {
        ...mapGetters(['user', 'auth', 'searchQuery', 'filters'])
    },
    methods: {
        favoriteProperty(property, bool) {
            PropertyService.favoriteProperty(property.id, bool, this.user).then(() => {
                NotifyService.notify(this, 'Favorite removed successfully!', 'success')
                this.list.splice(
                    this.list.findIndex(i => {
                        return i.id === property.id
                    }),
                    1
                )
            })
        },
        imageOrPlaceholder(property) {
            return property.images[0] && property.images[0].image
                ? `http://${property.images[0].image}`
                : require('@assets/images/image-placeholder.jpeg')
        },
        getList() {
            PropertyService.getFavoriteProperties()
                .then(list => {
                    this.list = list
                    this.loaded = true
                })
                .catch(() => {
                    this.loaded = true
                })
        },
        gotoProperty(idx) {
            if (this.auth) {
                this.$router.push({ name: 'Detail', params: { id: idx } })
            }
        }
    },
    beforeMount() {
        if (this.user) {
            this.getList()
        }
    },
    watch: {
        user() {
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
.action {
    float: right;
    position: relative;
    top: -32px;
    margin: 0 5px;
    cursor: pointer;
    img {
        width: 25px;
    }
}
</style>
