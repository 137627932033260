const state = {
    productType: undefined,
    searchQuery: undefined,
    fieldTypes: undefined,
    types: undefined,
    plans: undefined,
    filters: []
}

const getTypeDisplayName = (state, type) => {
    if (!state.types) return ''
    const typeName = state.types.find(name => name === type)
    if (typeName === 'Stylish Salon') {
        return 'Salon/Boutique'
    }
    return typeName
}
const makeTypeOption = (state, typeName) => {
    if (!typeName) {
        return null
    }
    return {
        label: getTypeDisplayName(state, typeName),
        value: typeName
    }
}
const getTypes = state => {
    if (!state.types) return []

    const sortedTypes = state.types
        .filter(item => 'Other' != item)
        .sort((a, b) => a.localeCompare(b))

    sortedTypes.push('Other')

    return sortedTypes
}
const getters = {
    productType: state => {
        return state.productType
    },
    searchQuery: state => {
        return state.searchQuery
    },
    filters: state => {
        return state.filters
    },
    types: state => {
        return getTypes(state)
    },
    fieldTypes: state => {
        return state.fieldTypes
    },
    plans: state => {
        return state.plans
    },
    filtersQuery: state => {
        if (!state.filters.length) return null

        const filters = {}

        state.filters.forEach(item => {
            filters[item.name] =
                'object' == typeof item.value ? item.value.join(',') : item.value
        })

        return filters
    },
    typeDisplayName: state => type => {
        return getTypeDisplayName(state, type)
    },
    typeOption: state => type => {
        return makeTypeOption(state, type)
    },
    typeOptions: state => {
        const types = getTypes(state)
        return types.map(name => {
            return makeTypeOption(state, name)
        })
    }
}

const actions = {}

const mutations = {
    setProductType: (state, type) => {
        state.productType = type
    },
    setSearchQuery: (state, search) => {
        state.searchQuery = search
    },
    addListFilter: (state, filter) => {
        state.filters = state.filters.filter(item => item.name !== filter.name)
        state.filters.push(filter)
    },
    removeListFilter: (state, type) => {
        state.filters = state.filters.filter(item => item.name !== type)
    },
    clearSearch: state => {
        state.searchQuery = undefined
    },
    clearFilters: state => {
        state.filters = []
    },
    setExtraFilters: (state, filters) => {
        const previousFilters = state.filters.filter(
            item =>
                item.name == 'flagged' ||
                item.name == 'shareScopeType' ||
                item.name == 'type' ||
                item.name == 'coords'
        )

        state.filters = [...filters, ...previousFilters]
    },
    setSpaceTypes: (state, types) => {
        let simpleTypeFormat = []
        types.forEach(type => {
            simpleTypeFormat.push(type.name)
        })

        state.types = simpleTypeFormat
        state.fieldTypes = types.sort((a, b) => a.order - b.order)
    },
    setPlans: (state, plans) => {
        state.plans = plans
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
