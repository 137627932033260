export function formDataOf(data, excludedFields = []) {
    var formData = new FormData()
    for (let [key, value] of Object.entries(data)) {
        if (excludedFields.includes(key)) {
            continue
        }
        if (Array.isArray(value)) {
            if (value.length) {
                for (let v of value) {
                    if (value) {
                        formData.append(key, v)
                    }
                }
            }
        } else {
            if (value === null || value === undefined) {
                formData.append(key, '')
            } else {
                formData.append(key, value)
            }
        }
    }
    return formData
}

export function debounce(func, wait = 500, immediate = false) {
    var timeout
    return function() {
        var context = this,
            args = arguments
        clearTimeout(timeout)
        timeout = setTimeout(function() {
            timeout = null
            if (!immediate) func.apply(context, args)
        }, wait)
        if (immediate && !timeout) func.apply(context, args)
    }
}

export function highlight(text, search) {
    var re = new RegExp('(' + search.split(' ').join('|') + ')', 'gi')
    return text.replace(re, '<b class="highlighted-text">$1</b>')
}

export function makeLocationString({ city, state }) {
    return [city, state].filter(x => x).join(', ')
}
