<template>
    <div></div>
</template>
<script>
import UserService from '@serv/UserService'
import NotifyService from '@serv/NotifyService'
import { mapGetters } from 'vuex'
export default {
    name: 'EmailConfirm',
    computed: {
        ...mapGetters(['user', 'auth'])
    },
    mounted() {
        const body = {
            key: this.$route.params.key
        }
        UserService.confirmEmail(body)
            .then(() => {
                NotifyService.notify(
                    this,
                    'Thanks for confirming your email address!',
                    'success'
                )
                this.$router.replace({ name: 'Home' })
            })
            .catch(() => {
                NotifyService.notify(
                    this,
                    'Email confirmation link invalid or expired.',
                    'danger'
                )
                this.$router.replace({ name: 'Home' })
            })
    }
}
</script>
