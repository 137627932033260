<template>
    <div class="basic-filter">
        <b-container class="px-2 px-xl-3">
            <b-row class="mx-n2 mx-xl-n3">
                <!--
                <b-col
                    v-if="user && user.isSuperuser"
                    cols="12"
                    sm="4"
                    xl="1"
                    class="px-2 px-xl-3 mb-3 mb-sm-0"
                >
                    <t-checkbox
                        class="p-2"
                        :checked="isFlagged"
                        @input="value => onInput('flagged', value)"
                    >
                        Flagged</t-checkbox
                    >
                </b-col>
                -->
                <b-col
                    cols="12"
                    sm="4"
                    xl="5"
                    class="px-2 mt-3 mt-xl-0 px-xl-3 mb-3 mb-sm-0"
                >
                    <gmap-autocomplete
                        placeholder="Enter city and state"
                        :selectFirstOnEnter="true"
                        :types="['(regions)']"
                        @place_changed="setPlace"
                        :value="search"
                        :tab-index="0"
                        :componentRestrictions="{ country: 'us' }"
                        class="form-control"
                    />
                </b-col>

                <b-col
                    cols="12"
                    sm="7"
                    xl="6"
                    class="px-2 mt-3 mt-xl-0 px-xl-3 mb-3 mb-sm-0"
                >
                    <category-search
                        ref="categorySearch"
                        :value="categorySearch"
                        @select="onSelectCategory"
                        @clear-input="onCategorySearchClear"
                    />
                </b-col>

                <!--<b-col cols="12" sm="4" xl="3" class="px-2 mt-3 mt-xl-0 px-xl-3">
                    <v-select
                        v-model="selectedSubcategory"
                        placeholder="Subcategory"
                        @input="value => onInput('subcategory', value)"
                        :options="subcategoryOptions"
                    ></v-select>
                </b-col>
                -->
                <b-col
                    cols="1"
                    sm="2"
                    xl="1"
                    class="px-2 px-xl-3 mt-3 mt-xl-0 d-flex justify-content-between"
                >
                    <b-button
                        @click="clearFilters"
                        variant="secondary"
                        size="sm"
                        class="text-capitalize"
                        >Clear</b-button
                    >
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ListFiltersBasicMixin from '@mixins/ListFiltersBasicMixin'
import CategorySearch from '@comp/CategorySearch'

export default {
    name: 'JobListFiltersBasic',
    mixins: [ListFiltersBasicMixin],
    components: {
        CategorySearch
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters({
            searchQuery: 'jobSearchQuery',
            filters: 'jobFilters',
            types: 'jobTypes',
            fieldTypes: 'jobFieldTypes',
            fieldTypeByName: 'jobFieldTypeByName',
            categories: 'jobCategories',
            subcategoriesOf: 'jobSubcategoriesOf'
        })
    },
    methods: {
        ...mapMutations({
            removeListFilter: 'removeJobListFilter',
            addListFilter: 'addJobListFilter'
        })
    },
    mounted() {}
}
</script>
<style lang="scss" scoped>
.search-input-container {
    position: relative;
}
.search-input-results {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    padding: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    z-index: 2;
    max-height: 41vh;
    overflow: auto;
}
.search-input-results .list-group-item {
    padding: 0;
}
.search-input-results .list-group-item a:focus,
.search-input-results .list-group-item a:hover {
    background-color: #f5f5f5;
    outline: 0;
}
.search-input-results .list-group-item a {
    padding: 10px 30px;
    display: block;
    font-size: 14px;
    background-color: #fff;
}
</style>
