<template>
    <gmap-autocomplete
        placeholder="Enter city and state"
        :selectFirstOnEnter="true"
        :types="['(regions)']"
        @place_changed="place => onPlaceChanged(place)"
        @change="evt => onInputChanged(evt.target.value)"
        @blur="evt => onInputBlur(evt.target.value)"
        v-bind="$attrs"
        :value="value"
        :componentRestrictions="{
            country: 'us'
        }"
        class="form-control gmap-autocomplete search__location"
    >
    </gmap-autocomplete>
</template>
<script>
import PlaceMixin from '@mixins/PlaceMixin'
export default {
    name: 'AddResume',
    mixins: [PlaceMixin],
    data() {
        return {
            currentLocation: undefined,
            model: {}
        }
    },
    props: {
        value: String
    },
    methods: {
        onPlaceChanged(place) {
            this.setPlace(place)
            if (this.model.formattedStreetAddress) {
                const { city, state, formattedStreetAddress } = this.model
                this.$emit('change', {
                    city,
                    state,
                    inputValue: formattedStreetAddress
                })
            } else {
                this.$emit('change')
                this.currentLocation = undefined
            }
        },
        onInputBlur(value) {
            this.$emit('blur', value)
        },
        onInputChanged(value) {
            if (!value) {
                this.$emit('change')
                this.currentLocation = undefined
            } else {
                this.$emit('change', {
                    inputValue: value.trim(),
                    city: '',
                    state: ''
                })
            }
        }
    }
}
</script>
