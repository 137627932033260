<template>
    <div class="list-container">
        <list-filters-basic
            @address-changed="value => (savedSearchAddress = value)"
            @add-filter="onBasicFilter"
            @clear-filters="clearPlace"
        />

        <loading-overlay
            class="loading-list"
            v-if="!isLoaded"
            :state="!isLoaded"
        ></loading-overlay>

        <template v-if="isLoaded">
            <div v-if="count > 0">
                <banner
                    :bannerText="
                        `${searchResultsText} - Create an account to view the details!`
                    "
                    :cta="'Create Account'"
                    :redirect="'Register'"
                    v-if="!auth"
                />
                <banner
                    :bannerText="
                        `${searchResultsText} - Please purchase a view product or above to view the details!`
                    "
                    :cta="'Purchase product'"
                    :redirect="'Account'"
                    v-if="
                        auth &&
                            user &&
                            user.activeProduct.length < 1 &&
                            !user.isSuperuser
                    "
                />

                <div class="list">
                    <b-overlay
                        :show="isProcessing"
                        class="align-items-center"
                        rounded="sm"
                        spinner-type="grow"
                        spinner-small
                    >
                        <b-container v-if="expandedSearch" class="mb-4">
                            <b-alert v-model="showAlert" variant="info">
                                <b-icon icon="info-circle" class="me-2"></b-icon>
                                We couldn't find any spaces within your given query, so
                                we expanded the search and found these.
                                <b-icon
                                    class="close-icon me-2 float-right"
                                    icon="x-circle-fill"
                                    @click="showAlert = false"
                                ></b-icon>
                            </b-alert>
                        </b-container>
                        <b-container>
                            <b-row v-if="0 < list.length">
                                <b-col
                                    v-for="(property, idx) in list"
                                    :key="idx"
                                    cols="12"
                                    sm="6"
                                    class="mb-4"
                                >
                                    <b-card
                                        img-top
                                        tag="article"
                                        body-class="p-0"
                                        class="list-card h-100 border-0"
                                        @click="gotoProperty(property.listingId)"
                                    >
                                        <carousel
                                            v-if="
                                                property.images &&
                                                    property.images.length
                                            "
                                            :files="property.images"
                                            aspect-ratio
                                            :imageAsBackground="true"
                                        />
                                        <div
                                            v-else
                                            class="card-featured-img"
                                            :style="{
                                                backgroundImage: `url(${imageOrPlaceholder(
                                                    property
                                                )})`
                                            }"
                                        ></div>
                                        <b-card-title
                                            :class="{ 'pb-4': !auth }"
                                            class="text-gray-900 border-0 fw-bold text-capitalize"
                                            >{{
                                                propertyAddress(property)
                                            }}</b-card-title
                                        >
                                        <b-card-text
                                            v-if="!!auth && user.activeProduct.length"
                                        >
                                            {{ propertyLocation(property) }}
                                        </b-card-text>
                                        <b-card-text
                                            class="flagged-text"
                                            v-if="
                                                flagged &&
                                                    user &&
                                                    property.flag.length > 0 &&
                                                    user.isSuperuser
                                            "
                                        >
                                            <p
                                                v-for="(flag, idx) in property.flag"
                                                :key="idx"
                                            >
                                                {{ flag.title }}
                                                ,
                                                {{ flag.body }}
                                            </p>
                                        </b-card-text>
                                    </b-card>
                                </b-col>
                            </b-row>

                            <no-data-placeholder v-else>
                                <template v-if="hasFilters" #sub
                                    >Please refine your search.</template
                                >
                            </no-data-placeholder>
                        </b-container>
                    </b-overlay>
                </div>
            </div>

            <b-container v-else class="py-5 text-gray-600 text-center">
                <b-row class="justify-content-center">
                    <b-col md="8" lg="6" v-if="!auth && !$route.query.search">
                        <h1 class="mb-3 fs-2 fw-bold">No Spaces Found.</h1>
                        <p class="fs-5 lh-sm">
                            We are not in this area yet but we are rolling out new areas
                            every week.<br />
                            Please sign up for notifications.
                        </p>
                        <form @submit.prevent="noResultNotify()" class="mt-4">
                            <t-input
                                input-classes="border rounded-pill"
                                placeholder="Email Address"
                                v-model="notify.email"
                            ></t-input>
                            <t-button
                                class="btn-secondary py-3 mt-3 text-capitalize"
                                native-type="submit"
                                wide
                                >Submit</t-button
                            >
                        </form>
                    </b-col>
                    <b-col md="8" lg="6" v-if="auth && !$route.query.search">
                        <no-data-placeholder>
                            <h1 class="mb-3 fs-2 fw-bold">No Spaces Found.</h1>
                            <template #sub>
                                <p class="fs-5 lh-sm">
                                    Please refine your search providing a city/zipcode
                                    or save it for later.
                                </p>

                                <t-button
                                    class="btn-secondary py-3 mt-3 text-capitalize"
                                    @click="$bvModal.show('search-modal')"
                                    wide
                                    >Save Search</t-button
                                >
                            </template>
                        </no-data-placeholder>
                    </b-col>
                    <b-col md="8" lg="6" v-if="auth && $route.query.search">
                        <no-data-placeholder>
                            <h1 class="mb-3 fs-2 fw-bold">No Spaces Found.</h1>
                            <template #sub>
                                <p class="fs-5 lh-sm">
                                    You are already using a previous search - If you
                                    still are looking for a property please clear your
                                    search and start again
                                </p>

                                <b-button
                                    class="btn-secondary py-3 mt-3 text-capitalize"
                                    @click="clearPlace"
                                    wide
                                    >Clear Search</b-button
                                >
                            </template>
                        </no-data-placeholder>
                    </b-col>
                </b-row>
            </b-container>
            <b-modal
                size="md"
                class="centered"
                id="search-modal"
                :ok-disabled="searchString.length <= 0"
                @ok="saveSearch()"
                ok-title="Save"
            >
                <validation-observer ref="searchValidator">
                    <template #modal-title>Save search</template>
                    <div>
                        <t-input
                            input-classes="border rounded-pill"
                            name="search"
                            placeholder="Search Name"
                            v-model="searchString"
                        ></t-input>
                    </div>
                </validation-observer>
            </b-modal>
        </template>
        <list-filters-modal :activeFilters="filters" />
        <runout-modal :list="true" :scope="this" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Redirect from '@serv/Redirect'
import LoadingOverlay from '@comp/LoadingOverlay'
import Carousel from '@comp/Carousel'
import ListFiltersBasic from '@comp/ListFiltersBasic'
import ListFiltersModal from '@comp/ListFiltersModal'
import NoDataPlaceholder from '@comp/NoDataPlaceholder'
import PropertyService from '@serv/PropertyService'
import UserService from '@serv/UserService'
import Banner from '@comp/Banner'
import NotifyService from '@serv/NotifyService'
import PropertyMixin from '@mixins/PropertyMixin'
import StringMixin from '@mixins/StringMixin'
import RunoutModal from '@comp/modal/RunoutModal'

export default {
    name: 'List',
    metaInfo: {
        title: 'Find a Space'
    },
    components: {
        Banner,
        Carousel,
        LoadingOverlay,
        ListFiltersBasic,
        ListFiltersModal,
        RunoutModal,
        NoDataPlaceholder
    },
    data() {
        return {
            background: {
                backgroundImage: `url(${require('@/assets/images/banner.png')})`
            },
            list: [],
            count: 0,
            noResult: false,
            loaded: false,
            flagged: false,
            searchString: '',
            isProcessing: false,
            currentPage: 1,
            savedSearchAddress: null,
            expandedSearch: false,
            showAlert: true,
            notify: {}
        }
    },
    mixins: [PropertyMixin, StringMixin],
    props: {},
    computed: {
        ...mapGetters([
            'user',
            'auth',
            'searchQuery',
            'filters',
            'types',
            'filtersQuery'
        ]),
        isBlurText() {
            return this.auth && this.user.activeProduct.length > 0
                ? ''
                : { filter: 'blur(14px)' }
        },
        isBlur() {
            return this.auth && this.user.activeProduct.length > 0
                ? ''
                : { filter: 'blur(10px)', padding: '20px' }
        },
        hasFilters() {
            return this.filters.length
        },
        searchResultsText() {
            const listing = this.suffixIf(this.count > 1, 'Listing', 's')

            return `${this.count} ${listing} on the platform `
        },
        isLoaded() {
            return this.loaded
        }
    },
    methods: {
        noResultNotify() {
            const fields = ['state', 'city', 'country']

            this.filters.forEach(filter => {
                if (fields.includes(filter.name)) {
                    this.notify[filter.name] = filter.value
                }
            })
            PropertyService.noResultNotify(this.notify).then(() => {
                NotifyService.notify(
                    this,
                    'We have saved your details and will notify you when a space matches your search !',
                    'success'
                )
                this.clearPlace()
            })
        },
        saveSearch() {
            if (this.savedSearchAddress) {
                this.filtersQuery[
                    this.savedSearchAddress.name
                ] = this.savedSearchAddress.value
            }

            const queryString = Object.keys(this.filtersQuery)
                .map(
                    item =>
                        encodeURIComponent(item) +
                        '=' +
                        encodeURIComponent(this.filtersQuery[item])
                )
                .join('&')

            const payload = {
                // search: filterClone,
                query: queryString,
                ownerId: this.user.id,
                name: this.searchString
            }
            PropertyService.postSearch(payload).then(res => {
                NotifyService.notify(this, res.detail, 'success')
                this.$bvModal.hide('search-modal')
            })
        },
        setFilter(option, type) {
            const template = {
                name: type,
                value: option
            }
            if (type == 'flagged') {
                this.flagged = !!this.flagged ? false : true
            }
            if (option == null || option.length == 0) {
                this.$store.commit('removeListFilter', type)
            } else {
                this.$store.commit('addListFilter', template)
            }
        },
        clearPlace() {
            this.flag = false
            this.loaded = false

            this.$router.replace({ query: null })

            this.$store.commit('setSearchQuery', undefined)
            this.$store.commit('clearFilters')
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        gotoProperty(listingId) {
            if (this.auth) {
                if (this.user.activeProduct.length >= 1) {
                    this.$router.push({
                        name: 'Detail',
                        params: { id: listingId, isListing: true }
                    })
                } else if (this.user.containsView) {
                    this.$bvModal.show('runout-modal-0')
                } else {
                    NotifyService.confirm(
                        'Purchase Required',
                        'You need have an active product to view listings'
                    ).then(rs => {
                        if ('confirm' !== rs) return

                        Redirect.gotoName('Account')
                    })
                }
            } else {
                NotifyService.confirm(
                    'Sign Up Required',
                    'You need an active account to view a space'
                ).then(rs => {
                    if ('confirm' !== rs) return

                    Redirect.gotoName('Register')
                })
            }
        },
        setList(object) {
            this.list = object.data
            this.expandedSearch = object.expandedSearch
            this.count = object.data.length
            this.showAlert = true
            this.loaded = true
        },
        getProperty() {
            return PropertyService.getProperties().then(object => {
                this.setList(object)
            })
        },
        getPropertyQuery(query) {
            return PropertyService.getPropertiesByQuery(query).then(object => {
                this.setList(object)
            })
        },
        onBasicFilter(payload) {
            this.setFilter(payload.value, payload.key)
        }
    },
    beforeMount() {
        //Keep the app loading overlay while list is set
        this.$bus.$emit('lock-app-loading-overlay')
        let $propertyResult

        if (!!this.$route.query.stripe) {
            UserService.checkoutComplete()
        }
        if (this.filters.length >= 1) {
            $propertyResult = this.getPropertyQuery(this.filtersQuery)
        } else {
            $propertyResult = this.getProperty()
        }

        $propertyResult.finally(() => {
            this.$bus.$emit('unlock-app-loading-overlay')
        })

        this.$bus.$on('api-request', payload => {
            this.isProcessing = payload
        })
    },
    mounted() {
        this.$bus.$on('api-request', payload => {
            this.loaded = !payload
        })
    },
    watch: {
        filters() {
            if (0 < this.filters.length) {
                this.getPropertyQuery(this.filtersQuery)
            } else {
                this.getProperty()
            }
        }
    }
}
</script>

<style lang="scss">
.list-container {
    .basic-filter {
        padding: 30px 0 40px;

        .form-control,
        .vs__dropdown-toggle {
            // height: 42px !important;
            border: 1px solid #e5e5e5;
        }

        .btn {
            max-width: 130px;
            height: 42px;
        }
    }
    .flagged-text {
        color: white;
        padding-top: 18px;
        background-color: #f76e8b;
    }
    .list {
        padding: 30px 0;

        .container {
            max-width: 1130px;
        }
    }

    @media screen and (min-width: 860px) {
        .list {
            padding: 45px 0 30px;
        }
    }

    @media screen and (min-width: 1200px) {
        .list {
            padding: 65px 0 45px;
        }
    }
    .close-icon {
        position: relative;
        float: right;
        top: 3px;
        cursor: pointer;
    }
}
</style>
