<template>
    <div class="workforce-register-container">
        <register :isWorkforceContext="true" />
    </div>
</template>

<script>
import Register from '@views/Register'

export default {
    name: 'WorkforceRegister',
    components: { Register }
}
</script>
