import NavigationService from '@serv/NavigationService'
import Redirect from '@serv/Redirect'

const defaults = () => {
    return {
        user: undefined,
        auth: undefined
    }
}

const state = {
    user: localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : defaults().user,
    auth: localStorage.getItem('auth')
        ? JSON.parse(localStorage.getItem('user'))
        : defaults().auth
}

const getters = {
    user: state => {
        return state.user
    },
    auth: state => {
        return state.auth
    },
    userHome: state => {
        if (state.user) {
            return Redirect.gotoName(NavigationService.getNavigation(state.user).home)
        } else {
            return { name: 'Login' }
        }
    }
}

const actions = {}

const mutations = {
    login(state, user) {
        localStorage.setItem('user', JSON.stringify(user))

        state.user = user
    },
    logout(state) {
        localStorage.removeItem('user')
        localStorage.removeItem('auth')

        state.user = defaults().user
        state.auth = defaults().auth
    },
    setUser(state, user) {
        localStorage.setItem('user', JSON.stringify(user))

        state.user = user
    },
    setAuth(state, bool) {
        if (bool) localStorage.setItem('auth', 1)
        else localStorage.removeItem('auth')

        state.auth = bool
    },
    removeListingAllowed(state, user) {
        state.user.listingAllowed = user.listingAllowed - 1
    },
    addListingAllowed(state, user) {
        state.user.listingAllowed = user.listingAllowed + 1
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
