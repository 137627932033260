import axios from '@serv/AxiosService'
import App from './App'
import Interceptor from '@serv/Interceptor'
import libraries from '@plugins/libraries'
import router from '@serv/RouterService'
import Redirect from '@serv/Redirect'
import store from '@store'
import template from '@plugins/template'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

const EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
    $bus: {
        get: function() {
            return EventBus
        }
    }
})
Vue.prototype.axios = axios

Interceptor(store)

Vue.use(VueAnalytics, {
    id: 'G-29N42VX7H0',
    router
})

Redirect.init(router)

new Vue({
    el: '#app',
    render: h => h(App),
    router,
    libraries,
    template,
    store
})
