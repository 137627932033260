<template>
    <div class="type-form">
        <b-row class="justify-content-lg-center">
            <b-col
                v-if="includeFindJobs"
                cols="12"
                :lg="productColumnsCount"
                :md="6"
                class="mb-4"
            >
                <b-card
                    :class="{
                        active: isFindJobsSelect(),
                        'ms-lg-auto me-auto ms-auto me-lg-0': productColumnsCount == 6
                    }"
                    title="Find Jobs"
                    sub-title=""
                    img-top
                    tag="article"
                    body-class="p-0"
                    class="h-100 border-0 text-center position-relative cursor-pointer pb-md-5"
                    @click="selectFindJobs()"
                >
                    <b-card-text class="mb-0 p-4">
                        <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                            <li>Post your resume</li>
                            <li>Search for jobs</li>
                        </ul>
                    </b-card-text>
                    <b-card-footer class="w-100 position-absolute bottom-0">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="w-100 mw-100 mx-auto d-table"
                            >$9 for 3 months</b-button
                        >
                    </b-card-footer>
                </b-card>
            </b-col>
            <b-col
                v-if="includePostJobs"
                cols="12"
                :lg="productColumnsCount"
                :md="6"
                class="mb-4"
            >
                <b-card
                    title="Post Jobs"
                    :class="{
                        active: isPostJobsSelected(),
                        'me-lg-auto ms-auto me-auto ms-lg-0': productColumnsCount == 6
                    }"
                    sub-title=""
                    img-top
                    tag="article"
                    body-class="p-0"
                    class="h-100 border-0 text-center position-relative cursor-pointer pb-md-5"
                    @click="selectPostJobs()"
                >
                    <b-card-text class="mb-0 p-4">
                        <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                            <li>Post a job opening</li>
                            <li>Search resumes</li>
                        </ul>
                    </b-card-text>
                    <b-card-footer class="w-100 position-absolute bottom-0">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="w-100 mw-100 mx-auto d-table"
                            >$9 for 3 months</b-button
                        >
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { findJobsProductType, postJobsProductType } from '@util/workforce'

export default {
    name: 'WorkforceProductType',
    computed: {
        ...mapGetters(['user', 'productType']),
        productColumnsCount() {
            let activeProducts = 0
            if (this.includeFindJobs) {
                activeProducts++
            }
            if (this.includePostJobs) {
                activeProducts++
            }
            let productsCount = Math.max(1, activeProducts)
            return 12 / productsCount
        }
    },
    data() {
        return {
            from: null
        }
    },
    props: {
        includeFindJobs: {
            type: Boolean,
            default: true
        },
        includePostJobs: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        selectProduceType(product) {
            this.$store.commit('setProductType', product)
            this.changePage()
        },
        selectFindJobs() {
            this.selectProduceType(findJobsProductType)
        },
        selectPostJobs() {
            this.selectProduceType(postJobsProductType)
        },
        isFindJobsSelect() {
            return this.productType === findJobsProductType
        },
        isPostJobsSelected() {
            return this.productType === postJobsProductType
        },
        changePage() {
            this.$emit('changepage')
        }
    },
    mounted() {
        if (!!this.$route.query.post) {
            this.viewOff = true
        }
    }
}
</script>

<style lang="scss">
@import './../assets/sass/components/_product-type.scss';
</style>
