const state = {
    workforceSearchQuery: undefined,
    workforceFieldTypes: [],
    workforceTypes: [],
    workforceFilters: []
}
const workforceFieldTypeByName = (state, name) => {
    return state.workforceFieldTypes.find(
        type => type.name.trim().toLowerCase() === name.trim().toLowerCase()
    )
}
const getters = {
    workforceSearchQuery: state => {
        return state.workforceSearchQuery
    },
    workforceFilters: state => {
        return state.workforceFilters
    },
    workforceTypes: state => {
        return state.workforceTypes
    },
    workforceFieldTypeByName: state => name => {
        return workforceFieldTypeByName(state, name)
    },
    workforceFieldTypeById: state => id => {
        return state.workforceFieldTypes.find(type => type.id === id)
    },
    workforceCategories: state => {
        return state.workforceTypes
    },
    workforceSubcategoriesOf: state => category => {
        var catId = workforceFieldTypeByName(state, category).id
        return state.workforceFieldTypes
            .filter(type => type.parent && type.parent == catId)
            .map(item => item.name)
    },
    workforceFieldTypes: state => {
        return state.workforceFieldTypes
    },
    workforceFiltersQuery: state => {
        if (!state.workforceFilters.length) return null

        const filters = {}

        state.workforceFilters.forEach(item => {
            if (item.name === 'category') {
                var category = item.value
                filters['cat_id'] = category.id
            } else {
                filters[item.name] =
                    'object' == typeof item.value ? item.value.join(',') : item.value
            }
        })

        return filters
    }
}

const actions = {}

const mutations = {
    setWorkforceSearchQuery: (state, search) => {
        state.workforceSearchQuery = search
    },
    addWorkforceListFilter: (state, filter) => {
        state.workforceFilters = state.workforceFilters.filter(
            item => item.name !== filter.name
        )
        state.workforceFilters.push(filter)
    },
    removeWorkforceListFilter: (state, type) => {
        state.workforceFilters = state.workforceFilters.filter(
            item => item.name !== type
        )
    },
    clearWorkforceSearchQuery: state => {
        state.workforceSearchQuery = undefined
    },
    clearWorkforceFilters: state => {
        state.workforceFilters = []
    },
    setWorkforceTypes: (state, types) => {
        types = types.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })
        state.workforceTypes = types.map(({ name }) => name)
        state.workforceFieldTypes = types
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
