<template>
    <b-modal
        class="centered"
        :id="'type-modal'"
        @ok="changeType()"
        ok-title="Change type"
        return-focus="body"
    >
        <template #modal-title>Change type</template>
        <div>
            Changing type on an existing listing will clear all extra fields on this
            property
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'TypeModal',
    data() {
        return {}
    },
    props: {
        scope: {
            required: true
        }
    },
    methods: {
        changeType() {
            this.scope.changeType()
        }
    }
}
</script>
