<template>
    <p class="pre-formatted">
        {{ displayText }}
        <span v-if="hasMoreText">
            <span v-if="showEllipses">... </span>
            <a href="javascript:;" @click.stop="onClickExpand"
                ><b>{{ seeMoreText }}</b></a
            >
        </span>
    </p>
</template>

<script>
export default {
    name: 'ListingExcerptComponent',
    components: {},
    data() {
        return {
            innerText: '',
            expanded: false
        }
    },
    props: {
        text: {
            type: String,
            default: ''
        },
        wordLength: {
            type: Number,
            default: 50
        }
    },
    computed: {
        displayText() {
            if (!this.expanded) {
                var words = this.innerText.split(' ')
                words = words.slice(0, this.wordLength)
                return words.join(' ')
            }
            return this.innerText
        },
        hasMoreText() {
            return this.innerText.split(' ').length > this.wordLength
        },
        showEllipses() {
            return this.hasMoreText && !this.expanded
        },
        seeMoreText() {
            return this.expanded ? 'See Less' : 'See More'
        }
    },
    methods: {
        onClickExpand(evt) {
            this.expanded = !this.expanded
            this.$emit('click:expand', evt)
        }
    },
    beforeMount() {
        this.innerText = this.text
    }
}
</script>
