/**
 * Handle Route redirection.
 *
 * Since the Router is decoupled from Vue we can have this
 * service where the router API can be called directly.
 */
class Redirect {
    init(routerInstance) {
        this.router = routerInstance
    }

    /**
     * Go to named route
     */
    gotoName(name, to, query) {
        return this.router.push({ name, params: { redirect: to }, query })
    }

    /**
     * Go to path route
     */
    gotoPath(path, params, query) {
        return this.router.push({ path, params, query })
    }

    /**
     * Get params / query for the existing route
     */
    getParamsQuery(params, query) {
        return this.router.push({ params, query })
    }
    goBack() {
        return this.router.go(-1)
    }
}

export default new Redirect()
