import NavigationService from '@serv/NavigationService'
import { findJobsProductType, postJobsProductType } from '@util/workforce'
class User {
    constructor(object) {
        this.id = object.id
        this.email = object.email
        this.firstName = object.firstName
        this.lastName = object.lastName
        this.emailVerified = object.emailVerified
        this.type = object.type
        this.subExpiry = object.profile.accessExpiresAt
        this.subAmount = object.profile.accessPeriodAmount
        this.cardCvc = object.profile.last4
        this.listingAllowed = object.profile.listingAllowed
        this.activeProduct = object.activeProduct
        this.spaces = object.spaces
        this.favorites = object.favoriteSpacesId
        this.checkForSuperuser(object)
    }
    checkForSuperuser(object) {
        this.activeProduct = this.isSuperuser ? [{ test: 1 }] : object.activeProduct
    }
    checkWorkforceActiveProduct(productType) {
        return (
            this.isSuperuser ||
            (this.activeProduct.length &&
                this.activeProduct.find(product => product.type == productType))
        )
    }
    checkActiveFindJobsProduct() {
        return this.checkWorkforceActiveProduct(findJobsProductType)
    }
    checkActivePostJobsProduct() {
        return this.checkWorkforceActiveProduct(postJobsProductType)
    }

    get homeRoute() {
        return NavigationService.getNavigation(this).HomePage
    }
    get isSuperuser() {
        if (this.type) {
            return this.type == 'Superuser'
        } else {
            return false
        }
    }
    get containsView() {
        return this.activeProduct.some(e => e.type === 'View')
    }
}

export default User
