import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@store'
import Routes from '@src/Routes'
import TokenService from '@serv/TokenService'
import UserService from '@serv/UserService'

Vue.use(VueRouter)

const router = new VueRouter({ mode: 'history', routes: Routes })

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)

    if (to.name === 'Register' && store.state.user.auth) {
        next({ name: 'Account' })
    } else if (
        ['MyOpenings', 'AddResume', 'Listings'].includes(to.name) &&
        !store.state.user.auth
    ) {
        next({ name: 'Register', query: { post: 'true', referrerRoute: to.name } })
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (TokenService.getLocalAccessToken()) {
            if (store.state.user.user) {
                next()
            } else {
                next({ name: 'Login' })
            }
        } else {
            next()
        }
    } else {
        next()
    }
})

router.afterEach(async () => {
    if (TokenService.getLocalAccessToken()) await UserService.getUser()
})

export default router
