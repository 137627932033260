<template>
    <b-container>
        <div class="type-form">
            <b-row class="mb-5 justify-content-center">
                <b-col cols="12" md="4" class="mb-4 mb-md-0">
                    <b-card
                        title="Basic"
                        sub-title="View Only"
                        img-top
                        tag="article"
                        body-class="p-0"
                        class="h-100 mx-auto border-0 text-center position-relative cursor-pointer pb-md-5"
                        @click="selectOption(0)"
                    >
                        <b-card-text class="mb-0 p-4">
                            <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                                <li>I am an individual</li>
                                <li>View all available spaces</li>
                            </ul>
                        </b-card-text>
                        <b-card-footer class="w-100 position-absolute bottom-0">
                            <b-button
                                variant="secondary"
                                size="sm"
                                class="w-100 mx-auto d-table"
                                >$9 for 3 months</b-button
                            >
                        </b-card-footer>
                    </b-card>
                </b-col>
                <b-col cols="12" md="4" class="mb-4 mb-md-0">
                    <b-card
                        title="Pro"
                        sub-title="View & Post"
                        img-top
                        tag="article"
                        body-class="p-0"
                        class="h-100 mx-auto border-0 text-center position-relative cursor-pointer pb-md-5"
                        @click="selectOption(1)"
                    >
                        <b-card-text class="mb-0 p-4">
                            <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                                <li>I am an individual and I represent myself</li>
                                <li>View all available spaces</li>
                                <li>Post 1 space</li>
                            </ul>
                        </b-card-text>
                        <b-card-footer class="w-100 position-absolute bottom-0">
                            <b-button
                                variant="secondary"
                                size="sm"
                                class="w-100 mw-100 mx-auto d-table"
                                >$99 for 3 months</b-button
                            >
                        </b-card-footer>
                    </b-card>
                </b-col>
                <b-col cols="12" md="4">
                    <b-card
                        title="Enterprise"
                        sub-title="View & Post"
                        img-top
                        tag="article"
                        body-class="p-0"
                        class="h-100 mx-auto border-0 text-center position-relative cursor-pointer pb-md-5"
                        @click="selectOption(2)"
                    >
                        <b-card-text class="mb-0 p-4">
                            <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                                <li>I am representing a real-estate entity</li>
                                <li>View all available spaces</li>
                                <li>Post 10 spaces</li>
                            </ul>
                        </b-card-text>
                        <b-card-footer class="w-100 position-absolute bottom-0">
                            <b-button
                                variant="secondary"
                                size="sm"
                                class="w-100 mw-100 mx-auto d-table"
                                >$399 for 3 months</b-button
                            >
                        </b-card-footer>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import Redirect from '@serv/Redirect'
import { mapGetters } from 'vuex'

export default {
    name: 'Subscription',
    computed: {
        ...mapGetters(['auth'])
    },
    methods: {
        goToRegister() {
            Redirect.gotoName('Register')
        },
        selectOption(index) {
            const options = ['Basic', 'Pro', 'Enterprise']

            this.$store.commit('setProductType', options[index])
            this.goToRegister()
        }
    }
}
</script>
