import Workforce from '@models/Workforce'
import store from '@store'
import axios from '@serv/AxiosService'
import CategoryService from '@serv/CategoryService'
import PreferedAreaService from '@serv/PreferedAreaService'
import { formDataOf } from '@util/helpers'

class WorkforceService {
    getTypes() {
        return CategoryService.getCategories().then(categories => {
            store.commit('setWorkforceTypes', categories)
            return categories
        })
    }
    getProperties() {
        return axios.get('workforce/workforce-search/').then(res => {
            var data = res.data
            if (data.detail) {
                data = data.data
            }
            var listings = data
                .filter(listing => listing.status === 'Published')
                .map(listing => {
                    return new Workforce(listing)
                })
            return listings
        })
    }
    getPropertiesByQuery(filterQuery) {
        return axios
            .get('workforce/workforce-search/', { params: filterQuery })
            .then(res => {
                var data = res.data
                if (data.detail) {
                    data = data.data
                }
                var listings = data
                    .filter(listing => listing.status === 'Published')
                    .map(listing => {
                        return new Workforce(listing)
                    })
                return listings
            })
            .catch(error => {
                throw error
            })
    }
    getProperty(id) {
        return axios
            .get('workforce/worker-profile/' + id + '/')
            .then(res => {
                var data = res.data
                if (data.status !== 'Published') {
                    return
                }
                return new Workforce(data)
            })
            .catch(error => {
                throw error
            })
    }
    updateWorkerProfile(data) {
        var url = `workforce/worker-profile/${data.id}/`
        return axios({
            method: 'patch',
            url: url,
            data: data
        })
            .then(res => res.data)
            .catch(error => {
                throw error
            })
    }
    createWorkerProfile(data) {
        var url = `workforce/worker-profile/`
        return axios({
            method: 'post',
            url: url,
            data: data
        })
            .then(res => res.data)
            .catch(error => {
                throw error
            })
    }
    saveWorkerProfile(data) {
        var formData = formDataOf(data, ['resume', 'profilePhoto'])
        var resume = data.resume
        var profilePhoto = data.profilePhoto
        var hasPreferedArea = data.preferedArea && data.preferedArea.length
        var method = data.id ? 'patch' : 'post'
        var url =
            method === 'post'
                ? 'workforce/worker-profile/'
                : `workforce/worker-profile/${data.id}/`

        if (resume && resume instanceof File) {
            formData.set('resume', resume)
        } else if (!resume) {
            formData.set('resume', '')
        }
        if (profilePhoto && profilePhoto.raw instanceof File) {
            formData.set('profilePhoto', profilePhoto.raw)
        } else if (!profilePhoto) {
            formData.set('profilePhoto', '')
        }
        //Publish the profile when is not already published
        if (data.status != 'Published') {
            formData.set('status', 'Published')
        }

        return axios({
            method: method,
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                let data = res.data
                //On the backend preferedArea is not nullable, so we remove/blank it (if needed)
                //in a separate request
                if (!hasPreferedArea) {
                    return this.updateWorkerProfile({ id: data.id, preferedArea: [] })
                }
                return data
            })
            .catch(error => {
                throw error
            })
    }
    createPreferedArea(areaObjects) {
        if (!areaObjects.length) {
            return Promise.resolve([])
        }
        return Promise.allSettled(
            areaObjects.map(area => {
                return PreferedAreaService.createPreferedArea(area)
            })
        )
    }
    getWorkerProfiles() {
        var url = `workforce/worker-profile/`
        return axios({
            method: 'get',
            url: url
        })
            .then(res => new Workforce(res.data[0]))
            .catch(error => {
                throw error
            })
    }
    getWorkerProfile(id) {
        return axios
            .get('workforce/worker-profile/' + id + '/')
            .then(res => {
                var data = res.data
                return new Workforce(data)
            })
            .catch(error => {
                throw error
            })
    }
}

export default new WorkforceService()
