var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CouponBanner'),_c('b-container',{staticClass:"account-form h-110 py-5 align-items-center"},[_c('b-row',{staticClass:"justify-content-center text-left"},[_c('b-col',{staticClass:"mb-4 mb-sm-0",attrs:{"md":"12","xl":"12"}},[_c('h1',{staticClass:"page-title mb-3"},[_vm._v(" My Account ")]),_c('b-card',{staticClass:"bg-gray-300--light rounded-0 border-gray-300 shadow-none"},[_c('b-card-body',{staticClass:"w-75 py-3 px-0 mx-auto"},[_c('h5',{staticClass:"mb-3 fw-bold text-dark"},[_vm._v("Access")]),_c('div',{staticClass:"mb-2"},[_vm._v(" Current Allowed Listings : "),(_vm.user.isSuperuser)?_c('h4',{staticClass:"d-inline-flex"},[_vm._v("∞")]):_c('strong',[_vm._v(" "+_vm._s(_vm.user.listingAllowed)+" listings")])]),(!_vm.user.isSuperuser && _vm.user.activeProduct.length)?_c('div',{staticClass:"mb-2"},[_vm._v(" Purchased Products : "),_c('strong',_vm._l((_vm.userPackageCount),function(count,product,index){return _c('span',{key:product},[_vm._v(" "+_vm._s(count)+" "+_vm._s(product)+" "),(
                                            index <
                                                Object.keys(_vm.userPackageCount)
                                                    .length -
                                                    1
                                        )?[_vm._v(",")]:_vm._e()],2)}),0)]):_vm._e(),_c('div',{staticClass:"my-4 my-md-5"}),(!_vm.user.isSuperuser)?_c('div',[_c('h5',{staticClass:"mb-3 fw-bold text-dark"},[_vm._v("Payment Method")]),(_vm.showStripeUpdate == false && _vm.user.cardCvc)?_c('p',{staticClass:"mb-2"},[_vm._v(" Credit Card ending in "+_vm._s(_vm.user.cardCvc)+" ")]):[_c('stripe-element-card',{ref:"stripeCardIntegration",attrs:{"pk":_vm.publishableKey,"hidePostalCode":true},on:{"token":_vm.tokenCreated}}),_c('b-button',{staticClass:"w-100 py-3 mt-4 text-uppercase",attrs:{"variant":"secondary"},on:{"click":_vm.generateToken}},[_vm._v("Save Changes")]),_c('b-button',{staticClass:"w-100 py-3 mt-4 text-uppercase",attrs:{"variant":"secondary"},on:{"click":function($event){_vm.showStripeUpdate = false}}},[_vm._v("Cancel")])]],2):_vm._e(),(_vm.showStripeUpdate == false && _vm.user.cardCvc)?_c('b-button',{staticClass:"w-100 py-3 mt-4 text-uppercase",attrs:{"variant":"secondary"},on:{"click":function($event){_vm.showStripeUpdate = true}}},[_vm._v("Update Payment Method")]):_vm._e()],1)],1)],1),(!_vm.user.isSuperuser)?_c('b-col',{staticClass:"mb-4 mb-sm-0 extra-column",attrs:{"md":"12","xl":"12"}},[_c('h5',{staticClass:"mb-3 fw-bold text-dark"},[_vm._v("Purchase product")]),_c('div',{class:("product-types-container product-types-count-" + (_vm.getUserProductsCount()))},[_c('product-type',{attrs:{"hasView":_vm.user.containsView || _vm.user.activeProduct.length > 0}}),_c('workforce-product-type')],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var failed = ref.failed;
return [_c('t-input',{staticClass:"mt-3 coupon-field",attrs:{"alternative":"","rules":"coupon","name":"couponCode","label":"Coupon (Optional)","type":"text"},model:{value:(_vm.couponCode),callback:function ($$v) {_vm.couponCode=$$v},expression:"couponCode"}}),_c('b-button',{staticClass:"w-100 py-3 mt-4 text-uppercase",attrs:{"disabled":_vm.processingProduct || failed,"variant":"secondary"},on:{"click":function($event){return _vm.goToCheckout(_vm.couponCode)}}},[_vm._v("Purchase Selected")])]}}],null,false,2529246231)})],1):_vm._e()],1),_c('checkout')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }