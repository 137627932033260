<template>
    <div>
        <CouponBanner />
        <b-container class="account-form h-110 py-5 align-items-center">
            <b-row class="justify-content-center text-left">
                <b-col md="12" xl="12" class="mb-4 mb-sm-0">
                    <h1 class="page-title mb-3">
                        My Account
                    </h1>
                    <b-card
                        class="bg-gray-300--light rounded-0 border-gray-300 shadow-none"
                    >
                        <b-card-body class="w-75 py-3 px-0 mx-auto">
                            <h5 class="mb-3 fw-bold text-dark">Access</h5>
                            <div class="mb-2">
                                Current Allowed Listings :
                                <h4 v-if="user.isSuperuser" class="d-inline-flex">∞</h4>
                                <strong v-else>
                                    {{ user.listingAllowed }} listings</strong
                                >
                            </div>

                            <div
                                class="mb-2"
                                v-if="!user.isSuperuser && user.activeProduct.length"
                            >
                                Purchased Products :
                                <strong>
                                    <span
                                        v-for="(count,
                                        product,
                                        index) in userPackageCount"
                                        :key="product"
                                    >
                                        {{ count }} {{ product }}
                                        <template
                                            v-if="
                                                index <
                                                    Object.keys(userPackageCount)
                                                        .length -
                                                        1
                                            "
                                            >,</template
                                        >
                                    </span>
                                </strong>
                            </div>
                            <div class="my-4 my-md-5"></div>

                            <div v-if="!user.isSuperuser">
                                <h5 class="mb-3 fw-bold text-dark">Payment Method</h5>
                                <p
                                    class="mb-2"
                                    v-if="showStripeUpdate == false && user.cardCvc"
                                >
                                    Credit Card ending in {{ user.cardCvc }}
                                </p>
                                <template v-else>
                                    <stripe-element-card
                                        ref="stripeCardIntegration"
                                        :pk="publishableKey"
                                        @token="tokenCreated"
                                        :hidePostalCode="true"
                                    />
                                    <b-button
                                        variant="secondary"
                                        class="w-100 py-3 mt-4 text-uppercase"
                                        @click="generateToken"
                                        >Save Changes</b-button
                                    >
                                    <b-button
                                        variant="secondary"
                                        class="w-100 py-3 mt-4 text-uppercase"
                                        @click="showStripeUpdate = false"
                                        >Cancel</b-button
                                    >
                                </template>
                            </div>

                            <b-button
                                v-if="showStripeUpdate == false && user.cardCvc"
                                variant="secondary"
                                class="w-100 py-3 mt-4 text-uppercase"
                                @click="showStripeUpdate = true"
                                >Update Payment Method</b-button
                            >
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col
                    v-if="!user.isSuperuser"
                    md="12"
                    xl="12"
                    class="mb-4 mb-sm-0 extra-column"
                >
                    <h5 class="mb-3 fw-bold text-dark">Purchase product</h5>
                    <div
                        :class="
                            `product-types-container product-types-count-${getUserProductsCount()}`
                        "
                    >
                        <product-type
                            :hasView="
                                user.containsView || user.activeProduct.length > 0
                            "
                        />
                        <workforce-product-type />
                    </div>
                    <validation-observer v-slot="{ failed }">
                        <t-input
                            alternative
                            class="mt-3 coupon-field"
                            rules="coupon"
                            name="couponCode"
                            label="Coupon (Optional)"
                            type="text"
                            v-model="couponCode"
                        />
                        <b-button
                            :disabled="processingProduct || failed"
                            variant="secondary"
                            class="w-100 py-3 mt-4 text-uppercase"
                            @click="goToCheckout(couponCode)"
                            >Purchase Selected</b-button
                        >
                    </validation-observer>
                </b-col>
            </b-row>
            <checkout />
        </b-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import UserService from '@serv/UserService'
import CouponBanner from '@comp/CouponBanner'
import ProductType from '@comp/ProductType'
import WorkforceProductType from '@comp/WorkforceProductType'
import Checkout from '@comp/Checkout'
import moment from 'moment'
import NotifyService from '@serv/NotifyService'
import FormMixin from '@mixins/FormMixin'

export default {
    name: 'Account',
    metaInfo: {
        title: 'My Account'
    },
    mixins: [FormMixin],
    components: {
        CouponBanner,
        StripeElementCard,
        ProductType,
        Checkout,
        WorkforceProductType
    },
    data() {
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
        return {
            couponCode: null,
            processTime: '',
            showStripeUpdate: false,
            processingProduct: false
        }
    },
    computed: {
        ...mapGetters(['user', 'productType', 'plans']),
        userPackageCount() {
            return _.countBy(this.user.activeProduct, prod => prod.type)
        }
    },
    methods: {
        generateToken() {
            this.$refs.stripeCardIntegration.submit()
        },
        tokenCreated(token) {
            UserService.cardUpdate(token).then(() => {
                NotifyService.notify(
                    this,
                    'Your card details were updated successfully!',
                    'success'
                )
                UserService.getUser().then(() => {
                    this.showStripeUpdate = false
                })
            })
        },
        dateToString() {
            this.processTime = moment(this.user.subExpiry).format('dddd, MMMM Do YYYY')
        },
        getUserProductsCount() {
            var count = 5
            var hasView = this.user.containsView || this.user.activeProduct.length > 0
            if (hasView) {
                count = 4
            }
            return count
        }
    },
    beforeMount() {
        this.dateToString()
    },
    watch: {
        user() {
            this.dateToString()
        }
    }
}
</script>
<style lang="scss">
.extra-column {
    margin-top: 25px;
}

.el-message-box__btns {
    .el-button {
        font-size: 16px;
    }
}

.product-types-container > .type-form {
    margin-top: 30px;
}
@media screen and (min-width: 1200px) {
    .product-types-container {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
        flex-wrap: wrap;
    }
    .product-types-container {
        margin-top: -30px;
    }
    .product-types-container > .type-form {
        padding: 15px;
    }
    .product-types-container > .type-form article {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .product-types-container.product-types-count-4 > .type-form:first-child,
    .product-types-container.product-types-count-4 > .type-form:last-child {
        width: 50% !important;
    }

    .product-types-container.product-types-count-5 > .type-form:first-child {
        width: 60% !important;
    }
    .product-types-container.product-types-count-5 > .type-form:last-child {
        width: 40% !important;
    }
    .product-types-container.product-types-count-5 .type-form .card-list li {
        font-size: 16px;
    }
}
</style>
