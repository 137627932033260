<template>
    <div class="banner text-center">
        <b-container>
            <h4 class="banner__title" :class="{ 'mb-4': cta ? true : false }">
                {{ bannerText }}
            </h4>
            <b-button
                v-if="cta"
                @click="goTo()"
                variant="secondary"
                size="sm"
                class="banner__button w-100 text-capitalize"
                >{{ cta }}</b-button
            >
        </b-container>
    </div>
</template>

<script>
import Redirect from '@serv/Redirect'

export default {
    name: 'Banner',
    data() {
        return {}
    },
    props: {
        bannerText: {
            type: String
        },
        cta: {
            type: String
        },
        redirect: {
            type: String
        }
    },
    methods: {
        goTo() {
            this.redirect == 'Register'
                ? Redirect.gotoName('Register')
                : this.redirect == 'WorkforceRegister'
                ? Redirect.gotoName('Register', null, { workforceSignUp: true })
                : Redirect.gotoName('Account')
        }
    }
}
</script>

<style lang="scss">
.banner {
    background-image: url(../assets/images/banner.png);
}
</style>
