<template>
    <validation-provider
        :rules="rules"
        :name="lowerCasedLabel"
        v-bind="$attrs"
        v-slot="{ errors, valid, invalid, validated }"
        ref="input"
        tag="div"
    >
        <b-form-group class="t-input">
            <slot name="label">
                <label v-if="label" :class="labelClasses">
                    {{ label }}
                    <small v-if="rules.required" class="text-danger">*</small>
                </label>
            </slot>
            <div
                :class="[
                    { 'input-group': hasIcon },
                    { focused: focused },
                    { 'input-group-alternative': alternative },
                    { 'has-label': label || $slots.label },
                    inputGroupClasses
                ]"
            >
                <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
                    <span class="input-group-text">
                        <slot name="prepend">
                            <font-awesome-icon :icon="prependIcon" />
                        </slot>
                    </span>
                </div>
                <slot v-bind="slotData">
                    <input
                        :ref="inputRef"
                        :value="value"
                        :type="type"
                        v-on="listeners"
                        v-bind="$attrs"
                        :valid="valid"
                        :required="required"
                        class="form-control"
                        :disabled="disabled"
                        :class="[
                            {
                                'is-valid border-success':
                                    valid && validated && successMessage
                            },
                            { 'is-invalid border-danger': invalid && validated },
                            inputClasses
                        ]"
                    />
                </slot>
                <div v-if="appendIcon || $slots.append" class="input-group-append">
                    <span class="input-group-text">
                        <slot name="append">
                            <font-awesome-icon :icon="appendIcon" />
                        </slot>
                    </span>
                </div>
                <slot name="infoBlock"></slot>
            </div>
            <slot name="success">
                <div class="valid-feedback" v-if="valid && validated && successMessage">
                    {{ successMessage }}
                </div>
            </slot>
            <slot name="error">
                <div v-if="0 < errors.length" class="invalid-feedback d-block">
                    {{ errors[0] }}
                </div>
            </slot>
        </b-form-group>
    </validation-provider>
</template>
<script>
export default {
    inheritAttrs: false,
    name: 'TInput',
    props: {
        reference: {
            type: String
        },
        inputRef: String,
        required: {
            type: Boolean,
            description: 'Whether input is required (adds an asterix *)'
        },
        group: {
            type: Boolean,
            description:
                'Whether input is an input group (manual override in special cases)'
        },
        alternative: {
            type: Boolean,
            description: 'Whether input is of alternative layout'
        },
        label: {
            type: String,
            description: 'Input label (text before input)'
        },
        error: {
            type: String,
            description: 'Input error (below input)'
        },
        successMessage: {
            type: String,
            description: 'Input success message',
            default: ''
        },
        labelClasses: {
            type: String,
            description: 'Input label css classes',
            default: 'form-control-label'
        },
        inputClasses: {
            type: String,
            description: 'Input css classes'
        },
        inputGroupClasses: {
            type: String,
            description: 'Input group css classes'
        },
        value: {
            type: [String, Number],
            description: 'Input value'
        },
        type: {
            type: String,
            description: 'Input type',
            default: 'text'
        },
        appendIcon: {
            type: String,
            description: 'Append icon (right)'
        },
        prependIcon: {
            type: String,
            description: 'Prepend icon (left)'
        },
        rules: {
            type: [String, Array, Object],
            description: 'Vee validate validation rules',
            default: ''
        },
        name: {
            type: String,
            description: 'Input name (used for validation)',
            default: ''
        },
        disabled: {
            type: Boolean,
            description: 'Whether to disable or not the input field',
            default: false
        }
    },
    data() {
        return {
            focused: false
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.updateValue,
                focus: this.onFocus,
                blur: this.onBlur
            }
        },
        slotData() {
            return {
                focused: this.focused,
                error: this.error,
                ...this.listeners
            }
        },
        hasIcon() {
            const { append, prepend } = this.$slots
            return (
                append !== undefined ||
                prepend !== undefined ||
                this.appendIcon !== undefined ||
                this.prependIcon !== undefined ||
                this.group
            )
        },
        lowerCasedLabel() {
            if (this.label) {
                return this.label.toLowerCase()
            } else {
                return this.name.toLowerCase()
            }
        },
        fieldErrors() {
            const fieldName = this.name

            return this.$errorService.errors.filter(
                item => 'field' == item.type && fieldName == item.field
            )
        }
    },
    methods: {
        updateValue(evt) {
            let value = !isNaN(evt.target.valueAsNumber)
                ? evt.target.valueAsNumber
                : evt.target.value

            this.$emit('input', value)
        },
        onFocus(evt) {
            this.focused = true
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false
            this.$emit('blur', evt)
        }
    },
    watch: {
        fieldErrors(newVal) {
            if (!newVal.length) return false

            this.$refs.input.setErrors(newVal.map(item => item.message))
        }
    }
}
</script>
