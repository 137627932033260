<template>
    <div class="w-100 m-auto py-5 py-md-4 align-items-center justify-content-center">
        <CouponBanner />

        <banner v-if="!!banner" :bannerText="'MATCHMAKER4BUSINESS'" />
        <div
            class="register m-auto d-flex align-items-center justify-content-center"
            :class="['form-page-' + formPage]"
        >
            <b-container class="w-100 h-100" :style="quickCenter">
                <template v-if="formPage == 0">
                    <product-type
                        v-if="
                            showSpacesPriceBoxes() ||
                                (!$route.query.workforceSignUp &&
                                    isInvalidReferrerRoute())
                        "
                        v-on:changepage="formIndex(true)"
                    />
                    <workforce-product-type
                        class="mt-5"
                        v-if="showWorkforcePriceBoxes() || isWorkforceSingup()"
                        :includeFindJobs="includeFindJobs"
                        :includePostJobs="includePostJobs"
                        v-on:changepage="formIndex(true)"
                    />
                </template>
                <template v-if="formPage == 1">
                    <information @fully-scrolled="handleInfoScroll" />

                    <div class="mt-4 d-sm-flex justify-content-between">
                        <b-button
                            @click="formIndex(false)"
                            variant="outline-secondary"
                            size="sm"
                            class="w-100 mb-2 mb-sm-0 mx-auto mx-sm-0 d-block"
                            >Back</b-button
                        >
                        <b-button
                            @click="formIndex(true)"
                            :disabled="!termsBottom"
                            variant="secondary"
                            size="sm"
                            class="mx-auto mx-sm-0 d-block"
                            >I accept the Terms of Use</b-button
                        >
                    </div>
                </template>
                <template v-if="formPage == 2">
                    <div class="account-form">
                        <h3
                            class="
								mb-4
								ff-secondary
								fs-title
								text-center
							"
                        >
                            Account Information
                        </h3>

                        <validation-observer
                            ref="formValidator"
                            v-slot="{ handleSubmit }"
                        >
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <b-container v-loading="loading" class="px-0">
                                    <v-select
                                        v-if="!selectedWorkforceProduct"
                                        @input="
                                            option =>
                                                setPractice(
                                                    !option ? null : option.value
                                                )
                                        "
                                        :value="selectedTypeOption"
                                        placeholder="Practice"
                                        :options="typeOptions"
                                        class="mb-3"
                                    ></v-select>

                                    <b-row>
                                        <b-col>
                                            <t-input
                                                alternative
                                                class="mb-3"
                                                name="firstName"
                                                label="First Name"
                                                v-model="form.firstName"
                                                autocomplete="formname"
                                                mode="lazy"
                                                :rules="{
                                                    required: true
                                                }"
                                            />
                                        </b-col>
                                        <b-col>
                                            <t-input
                                                alternative
                                                class="mb-3"
                                                name="lastName"
                                                label="Last Name"
                                                v-model="form.lastName"
                                                autocomplete="formname"
                                                mode="lazy"
                                                :rules="{
                                                    required: true
                                                }"
                                            />
                                        </b-col>
                                        <t-input
                                            alternative
                                            class="mb-2"
                                            name="email"
                                            label="Email"
                                            v-model="form.email"
                                            autocomplete="formname"
                                            mode="lazy"
                                            :rules="{ required: true, email: true }"
                                        />
                                        <b-form-text id="email-help-block" class="mb-3">
                                            We will send you a confirmation email from
                                            <a
                                                :href="
                                                    `mailto:${getEnv(
                                                        'VUE_APP_SUPPORT_EMAIL'
                                                    )}`
                                                "
                                                >{{
                                                    getEnv('VUE_APP_SUPPORT_EMAIL')
                                                }}</a
                                            >
                                        </b-form-text>
                                    </b-row>

                                    <b-row>
                                        <b-col>
                                            <t-input
                                                alternative
                                                class="mb-3"
                                                name="password1"
                                                :rules="{
                                                    required: true,
                                                    minLength: 8
                                                }"
                                                label="Password"
                                                type="password"
                                                v-model="form.password1"
                                                autocomplete="new-password"
                                            />
                                        </b-col>
                                        <b-col>
                                            <t-input
                                                alternative
                                                class="mb-3"
                                                name="password2"
                                                label="Confirm Password"
                                                :rules="{
                                                    confirmed: 'password',
                                                    required: true,
                                                    minLength: 8
                                                }"
                                                type="password"
                                                v-model="form.password2"
                                                autocomplete="new-password"
                                            />
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="6">
                                            <t-input
                                                alternative
                                                class="mb-3 coupon-field"
                                                rules="coupon"
                                                name="couponCode"
                                                label="Coupon (When Available)"
                                                type="text"
                                                v-model="form.couponCode"
                                            />
                                        </b-col>
                                    </b-row>
                                </b-container>
                                <div class="mt-4 d-sm-flex justify-content-between">
                                    <b-button
                                        @click="formIndex(false)"
                                        variant="outline-secondary"
                                        siz="sm"
                                        class="w-100 mb-2 mb-sm-0 mx-auto mx-sm-0 d-block"
                                        >Back</b-button
                                    >
                                    <b-button
                                        :disabled="
                                            !!loading || !isRefValid('formValidator')
                                        "
                                        variant="secondary"
                                        size="sm"
                                        type="submit"
                                        class="w-100 mx-auto mx-sm-0 d-block"
                                        >Submit</b-button
                                    >
                                </div>
                            </form>
                        </validation-observer>
                        <checkout />
                    </div>
                </template>
            </b-container>
        </div>
    </div>
</template>
<script>
import Banner from '@comp/Banner'
import CouponBanner from '@comp/CouponBanner'
import ProductType from '@comp/ProductType'
import Information from '@comp/Information'
import { mapGetters } from 'vuex'
import UserService from '@serv/UserService'
import Checkout from '@comp/Checkout'
import NotifyService from '@serv/NotifyService'
import Redirect from '@serv/Redirect'
import EnvMixin from '@mixins/EnvMixin'
import WorkforceProductType from '@comp/WorkforceProductType'
import { findJobsProductType, postJobsProductType } from '@util/workforce'

export default {
    name: 'Register',
    metaInfo: {
        title: 'Register'
    },
    components: {
        Checkout,
        CouponBanner,
        ProductType,
        Banner,
        Information,
        WorkforceProductType
    },
    props: {
        isWorkforceContext: {
            type: Boolean,
            description:
                'If the view is related to the workforce product types, show and register workforce plans',
            default: false
        }
    },
    mixins: [EnvMixin],
    data() {
        return {
            loading: false,
            banner: false,
            formPage: 0,
            errors: false,
            termsBottom: false,
            form: {
                name: '',
                productType: '',
                practice: '',
                email: '',
                password1: '',
                password2: ''
            }
        }
    },
    computed: {
        ...mapGetters(['user', 'productType', 'plans', 'typeOptions', 'typeOption']),
        forgotPassword() {
            return !!this.passwordToggle ? 'Forgot your password?' : 'Back to login'
        },
        quickCenter() {
            return !!this.banner ? { top: '-68px', position: 'relative' } : ''
        },
        includeFindJobs() {
            if (!this.auth) {
                return true
            }
            return !this.user.checkActiveFindJobsProduct()
        },
        includePostJobs() {
            if (!this.auth) {
                return true
            }
            return !this.user.checkActivePostJobsProduct()
        },
        selectedWorkforceProduct() {
            return (
                this.productType === findJobsProductType ||
                this.productType === postJobsProductType
            )
        },
        selectedTypeOption() {
            console.log(this.form.description)
            return this.typeOption(this.form.description)
        }
    },
    methods: {
        formIndex(bool) {
            bool ? this.formPage++ : this.revert()
        },
        revert() {
            this.banner = false
            this.formPage--
        },
        isRefValid(ref) {
            if (!this.$refs[ref]) return false

            return this.$refs[ref].flags.valid
        },
        setPractice(value) {
            this.form = { ...this.form, description: value }
        },
        async onSubmit() {
            this.loading = true
            const isValid = await this.$refs.formValidator.validate()

            if (!isValid) {
                this.loading = false
                this.errors = isValid
                return
            }
            if (this.form.couponCode == '') {
                delete this.form.couponCode
            }

            this.form.type = this.productType
            this.form.priceId = this.plans.find(
                plan => plan.name === this.productType
            ).price.id

            var successURL = `${process.env.VUE_APP_URL}/listing?stripe=true`
            var cancelURL = `${process.env.VUE_APP_URL}/register`

            if (this.productType === findJobsProductType) {
                successURL = `${process.env.VUE_APP_URL}/job-openings/search?stripe=true`
                cancelURL = `${process.env.VUE_APP_URL}/workforce/register`
            } else if (this.productType === postJobsProductType) {
                successURL = `${process.env.VUE_APP_URL}/workforce/search?stripe=true`
                cancelURL = `${process.env.VUE_APP_URL}/workforce/register`
            }

            this.form.successURL = successURL
            this.form.cancelURL = cancelURL
            if (this.form.email && this.form.password1 && this.form.type) {
                UserService.createUser(this.form)
                    .then(res => {
                        if (res.user.stripeSessionId) {
                            this.$bus.$emit('checkoutOpen', res.user.stripeSessionId)
                        } else {
                            Redirect.gotoName('Home')
                        }
                        NotifyService.notify(
                            this,
                            'An email confirmation link has been sent. Please check your inbox',
                            'success'
                        )
                        this.loading = false
                    })
                    .catch(error => {
                        if (error.email) {
                            NotifyService.notify(this, error.email[0], 'danger')
                        } else {
                            NotifyService.notify(
                                this,
                                'An error has occured - please check the form and try again.',
                                'danger'
                            )
                        }

                        this.loading = false
                    })
            }
        },
        handleInfoScroll(payload) {
            this.termsBottom = payload
        },
        isWorkforceSingup() {
            return (
                this.isInvalidReferrerRoute() &&
                (!this.auth || this.$route.query.workforceSignUp)
            )
        },
        showSpacesPriceBoxes() {
            return !this.auth && ['Listings'].includes(this.$route.query.referrerRoute)
        },
        showWorkforcePriceBoxes() {
            return (
                !this.auth &&
                !['Listings'].includes(this.$route.query.referrerRoute) &&
                ['MyOpenings', 'AddResume'].includes(this.$route.query.referrerRoute)
            )
        },
        isInvalidReferrerRoute() {
            return (
                !this.$route.query.referrerRoute ||
                !['MyOpenings', 'AddResume', 'Listings'].includes(
                    this.$route.query.referrerRoute
                )
            )
        }
    },
    beforeMount() {},
    mounted() {
        if (this.productType) this.formPage += 1

        document.addEventListener('keypress', e => {
            if (e.key === 'Enter') {
                this.onSubmit()
            }
        })
        this.$bus.$on('change-page', () => {
            this.formIndex(true)
        })
    }
}
</script>

<style lang="scss">
.register.form-page-2,
.register.form-page-1 {
    max-width: 820px;
}
.register {
    padding-top: 35px;

    .btn {
        &.w-100 {
            max-width: 200px;
        }
    }
}
</style>
