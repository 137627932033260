import { MessageBox } from 'element-ui'
import UserService from '@serv/UserService'
import NotifyService from '@serv/NotifyService'
import { findJobsProductType, postJobsProductType } from '@util/workforce'

export default {
    computed: {
        productPrice() {
            let price = 0

            if ('Basic' == this.productType) price = 9
            else if ('Pro' == this.productType) price = 99
            else if ('Enterprise' == this.productType) price = 399
            else if (findJobsProductType == this.productType) price = 9
            else if (postJobsProductType == this.productType) price = 9

            return `$${price} for 3 months`
        }
    },
    methods: {
        goToCheckout(couponCode) {
            if (!this.productType) {
                NotifyService.notify(this, 'Please select a product first.', 'danger ')
            } else {
                this.processingProduct = true
                let productValue = this.plans.find(
                    plan => plan.name === this.productType
                ).price.id

                MessageBox.confirm(
                    `
                    <span class="d-block mb-1">Product: <strong>${
                        this.productType
                    }</strong></span>
                    <span class="d-block mb-1">Price: <strong>${
                        this.productPrice
                    }</strong></span>
                    <span class="d-block">Coupon: ${couponCode || 'N/A'}</span>
                `,
                    'Confirm Purchase',
                    {
                        confirmButtonText: 'Confirm',
                        confirmButtonClass: 'bg-primary border-primary',
                        cancelButtonText: 'Cancel',
                        cancelButtonClass:
                            'bg-white text-primary border-primary float-left',
                        dangerouslyUseHTMLString: true
                    }
                ).then(rs => {
                    if ('confirm' !== rs) return

                    UserService.getStripeSession(productValue, couponCode).then(
                        stripeSessionId => {
                            if (!this.couponCode) {
                                this.$bus.$emit('checkoutOpen', stripeSessionId)
                            } else {
                                UserService.getUser().then(() => {
                                    NotifyService.notify(
                                        this,
                                        'Product purchased successfully',
                                        'success'
                                    )
                                })
                            }
                            setTimeout(() => {
                                this.processingProduct = false
                            }, 3500)
                        }
                    )
                })
            }
        }
    }
}
