<template>
    <workforce-search-input
        :value="searchInput"
        :isSearchingResults="processing"
        :categorySearchResults="searchResults"
        @input="onSearch"
        @category-change="onSelect"
    />
</template>

<script>
import WorkforceSearchInput from '@comp/WorkforceSearchInput'
import CategoryService from '@serv/CategoryService'
import { debounce } from '@util/helpers'
export default {
    name: 'CategorySearch',
    components: { WorkforceSearchInput },
    data() {
        return {
            searchInput: '',
            processing: false,
            selected: undefined,
            searchResults: []
        }
    },
    props: {
        value: String
    },
    methods: {
        onSelect(idx) {
            if (this.searchResults && this.searchResults.length) {
                this.selected = this.searchResults[idx]
                this.$emit('select', this.selected)
            }
        },
        onSearch(value) {
            if (!value) {
                this.clear()
            } else {
                this.searchInput = value
                this.search(value)
            }
            this.$emit('input', value)
        },
        clearInput() {
            this.searchInput = ''
            this.processing = false
            this.selected = undefined
            this.searchResults = []
        },
        clear() {
            this.clearInput()
            this.$emit('clear-input')
        }
    },
    created() {
        this.aborter = undefined
        this.search = debounce(value => {
            this.processing = true
            if (this.aborter !== undefined) {
                this.aborter.abort()
            }
            this.aborter = new AbortController()
            CategoryService.searchCategory(value, this.aborter.signal)
                .then(results => {
                    this.searchResults = results
                    this.processing = false
                    this.$emit('search-success', this.searchResults)
                })
                .catch(error => {
                    if (!error.abortedRequest) {
                        this.processing = false
                    }
                    this.$emit('search-fail', error)
                })
        })
    },
    beforeMount() {
        this.searchInput = this.value
    },
    watch: {
        selected(current) {
            if (!current) {
                this.searchInput = ''
            } else {
                var category = this.searchResults.find(cat => cat.id === current.id)
                if (!category) {
                    category = this.fieldTypes.find(cat => cat.id === current.id)
                }
                this.searchInput = category ? category.name : ''
            }
        }
    }
}
</script>
