/* eslint-disable */
class NavigationService {
    getNavigation(user) {
        const links = {
            Anonymous: {
                navigation: [
                    { name: 'Find a Space', link: 'Home' },
                    { name: 'Find a Job', link: 'SearchJob' },
                    { name: 'Find a Workforce', link: 'SearchWorkForce' },
                    { name: 'Contact', link: 'Contact' }
                ]
            },
            Standard: {
                navigation: [
                    { name: 'Find a Space', link: 'Home' },
                    { name: 'Find a Job', link: 'SearchJob' },
                    { name: 'Find a Workforce', link: 'SearchWorkForce' },
                    { name: 'My Resume', link: 'AddResume' },
                    { name: 'My Job Openings', link: 'MyOpenings'},
                    { name: 'Contact', link: 'Contact' }
                ]
            },
            Superuser: {
                navigation: [
                    { name: 'Find a Space', link: 'Home' },
                    { name: 'Find a Job', link: 'SearchJob' },
                    { name: 'Find a Workforce', link: 'SearchWorkForce' },
                    { name: 'My Resume', link: 'AddResume' },
                    { name: 'My Job Openings', link: 'MyOpenings'},
                    { name: 'Contact', link: 'Contact' },
                    { name: 'Users', link: 'ListUser' }
                ]
            }
        }
        return user.type == 'Superuser'
            ? links['Superuser']
            : user.type == 'Anonymous'
            ? links['Anonymous']
            : links['Standard']
    }
}

export default new NavigationService()
