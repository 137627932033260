<template>
    <div :class="`type-form--${size}`" class="type-form">
        <b-row class="justify-content-lg-center">
            <b-col
                v-if="!!viewOn && !hasView && !viewOff"
                cols="12"
                :lg="productColumnsCount"
                :md="6"
                class="mb-4 mb-md-0"
            >
                <b-card
                    :class="{ active: isProductSelected('Basic') }"
                    title="Basic"
                    sub-title="View Only"
                    img-top
                    tag="article"
                    body-class="p-0"
                    class="h-100 mx-auto border-0 text-center position-relative cursor-pointer pb-md-5"
                    @click="selectOption(0)"
                >
                    <b-card-text class="mb-0 p-4">
                        <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                            <li>I am an individual</li>
                            <li>View all available spaces</li>
                        </ul>
                    </b-card-text>
                    <b-card-footer class="w-100 position-absolute bottom-0">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="w-100 mw-100 mx-auto d-table"
                            >$9 for 3 months</b-button
                        >
                    </b-card-footer>
                </b-card>
            </b-col>
            <b-col cols="12" :lg="productColumnsCount" :md="6" class="mb-4 mb-md-0">
                <b-card
                    :class="{ active: isProductSelected('Pro') }"
                    title="Pro"
                    sub-title="View & Post"
                    img-top
                    tag="article"
                    body-class="p-0"
                    class="h-100 mx-auto border-0 text-center position-relative cursor-pointer pb-md-5"
                    @click="selectOption(1)"
                >
                    <b-card-text class="mb-0 p-4">
                        <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                            <li>I am an individual and I represent myself</li>
                            <li>View all available spaces</li>
                            <li>Post 1 space</li>
                        </ul>
                    </b-card-text>
                    <b-card-footer class="w-100 position-absolute bottom-0">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="w-100 mw-100 mx-auto d-table"
                            >$99 for 3 months</b-button
                        >
                    </b-card-footer>
                </b-card>
            </b-col>
            <b-col cols="12" :lg="productColumnsCount" :md="6">
                <b-card
                    :class="{ active: isProductSelected('Enterprise') }"
                    title="Enterprise"
                    sub-title="View & Post"
                    img-top
                    tag="article"
                    body-class="p-0"
                    class="h-100 mx-auto border-0 text-center position-relative cursor-pointer pb-md-5"
                    @click="selectOption(2)"
                >
                    <b-card-text class="mb-0 p-4">
                        <ul class="card-list mx-auto text-start h5 m-0 fw-bold">
                            <li>I am representing a real-estate entity</li>
                            <li>View all available spaces</li>
                            <li>Post 10 spaces</li>
                        </ul>
                    </b-card-text>
                    <b-card-footer class="w-100 position-absolute bottom-0">
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="w-100 mw-100 mx-auto d-table"
                            >$399 for 3 months</b-button
                        >
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ProductType',
    props: {
        viewOn: {
            type: Boolean,
            default: true
        },
        hasView: {
            type: Boolean
        },
        size: {
            type: String,
            description: "The grid card's size and font sizes.",
            default: 'md',
            validator(value) {
                return 0 <= ['sm', 'md'].indexOf(value)
            }
        }
    },
    computed: {
        ...mapGetters(['productType']),
        productColumnsCount() {
            let productsCount = 2

            if (!!this.viewOn && !this.hasView && !this.viewOff) productsCount += 1

            return 12 / productsCount
        }
    },
    data() {
        return {
            from: null,
            viewOff: false
        }
    },
    methods: {
        selectOption(index) {
            const options = ['Basic', 'Pro', 'Enterprise']

            this.$store.commit('setProductType', options[index])
            this.changePage()
        },
        changePage() {
            this.$emit('changepage')
        },
        isProductSelected(type = 'View') {
            return type === this.productType
        }
    },
    mounted() {
        if (!!this.$route.query.post) {
            this.viewOff = true
        }
    }
}
</script>

<style lang="scss">
@import './../assets/sass/components/_product-type.scss';
</style>
