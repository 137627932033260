<template>
    <div class="job-detail-view">
        <job-detail />
    </div>
</template>

<script>
import JobDetail from '@comp/detail/JobDetail'
export default {
    name: 'JobDetailView',
    components: { JobDetail }
}
</script>
