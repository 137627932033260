/* eslint-disable */
import { MessageBox } from 'element-ui'

class NotifyService {
    notify(context, message, type, timeout = 4000) {
        var icon = !type  || type === 'danger' ? 'exclamation-circle' : 'check-circle';
        context.$notify({
            verticalAlign: 'bottom',
            horizontalAlign: 'right',
            type: type,
            icon: icon, 
            timeout: timeout,
            message: message
        })
    }

    confirm(title, message, extraOptions = {}) {
        return MessageBox.confirm(message, title, {
            ...extraOptions,
            confirmButtonText: 'Confirm',
            confirmButtonClass: 'bg-primary border-primary',
            cancelButtonText: 'Cancel',
            cancelButtonClass: 'bg-white text-primary border-primary float-left',
            dangerouslyUseHTMLString: true
        })
    }
    notify400Error(context, error) {
        if (error.response && error.response.status === 400) {
            var data = error.response.data;
            var errorMessages = [];

            if (Array.isArray(data) && data.length) {
                errorMessages = data;
            } else if (typeof data == 'object' && Object.keys(data).length) {
                var errors = data.error || data;
                for (let messages of Object.values(errors)) {
                    errorMessages = [... errorMessages, ...messages]
                }
            }
            for (let msg of errorMessages) {
                this.notify(context, msg, 'danger', 8000)
            }
            return true;
        }
        return false;
    }

}

export default new NotifyService()
