<template>
    <div class="detail-view py-4 py-lg-3">
        <b-container>
            <b-link v-if="!form" @click="redirect('WorkforceListing')">
                <b-icon icon="arrow-left" class="me-1"></b-icon> Back
            </b-link>
            <loading-overlay
                class="loading-list"
                v-if="!loaded"
                :state="!loaded"
            ></loading-overlay>

            <b-row v-if="loaded" class="mt-3">
                <b-col cols="12" class="mb-4 mb-lg-0">
                    <workforce-detail-basic
                        :displayName="property.displayName"
                        :jobType="property.category"
                        :speciality="property.subcategory"
                        :description="property.description"
                        :resume="property.resume"
                        :preferedArea="property.preferedArea"
                        :profilePhoto="property.profilePhoto"
                        :phoneNumber="property.phoneNumber"
                        :email="property.email"
                    >
                        <!-- FLAG -->

                        <!--<template v-slot:header-bottom>
                            <div
                                v-if="!form"
                                class="
                                    d-inline-b
                                    actions
                                    my-2
                                    position-absolute
                                    top-0
                                    end-0
                                    property-actions
                                "
                            >
                                <div
                                    v-if="hasFlag"
                                    @click="unflagProperty()"
                                    class="action"
                                >
                                    <b-icon icon="flag-fill" font-scale="1.2"></b-icon>
                                </div>
                                <div
                                    v-else
                                    class="action"
                                    @click="$bvModal.show('flagModal')"
                                >
                                    <b-icon icon="flag" font-scale="1.2"></b-icon>
                                </div>-->
                        <!-- /FLAG -->
                        <!-- FAVORITE -->
                        <!--<div
                                    v-if="isFavorite"
                                    class="action"
                                    @click="favoriteProperty(property, false)"
                                >
                                    <b-icon icon="heart-fill" font-scale="1.2"></b-icon>
                                </div>
                                <div
                                    v-else
                                    class="action"
                                    @click="favoriteProperty(property, true)"
                                >
                                    <b-icon icon="heart" font-scale="1.2"></b-icon>
                                </div>-->
                        <!-- /FAVORITE
                            </div>
                        </template> -->
                    </workforce-detail-basic>
                </b-col>
            </b-row>
            <flag-modal @submit="flagProperty" />
        </b-container>
    </div>
</template>

<script>
//import Schedule from '@comp/Schedule'
//import Redirect from '@serv/Redirect'
import WorkforceService from '@serv/WorkforceService'
import { mapGetters } from 'vuex'
//import NotifyService from '@serv/NotifyService'
import LoadingOverlay from '@comp/LoadingOverlay'
import DetailMixin from '@mixins/DetailMixin'
import WorkforceDetailBasic from '@comp/detail/WorkforceDetailBasic'
import FlagModal from '@comp/modal/FlagModal'

export default {
    name: 'WorkforceDetailView',
    metaInfo: {
        title: 'Workforce Detail'
    },
    components: {
        //Schedule,
        LoadingOverlay,
        WorkforceDetailBasic,
        FlagModal
    },
    mixins: [DetailMixin],
    computed: {
        ...mapGetters(['user', 'auth']),
        ...mapGetters({
            fieldTypes: 'workforceFieldTypes'
        })
    },
    methods: {
        flagListing(/*form, userId, listingId*/) {
            return Promise.resolve()
        },
        favoriteListing(/*listingId, isFavorite, user*/) {
            return Promise.resolve()
        },
        getProperty(id) {
            return WorkforceService.getProperty(id)
        },
        canAccessView() {
            return this.auth && this.user.checkActivePostJobsProduct()
        }
    }
}
</script>

<style lang="scss">
@import './../../assets/sass/pages/_property-detail.scss';
</style>
