<template>
    <b-row>
        <b-col cols="12" lg="6">
            <b-form-group class="mb-3 mb-lg-4">
                <template #label>
                    {{ categoryInputLabel }}
                    <small v-if="rules && rules.required" class="text-danger">*</small>
                </template>
                <validation-provider
                    :rules="rules"
                    :name="categoryValidatorName"
                    v-bind="$attrs"
                    v-slot="{ errors, validate }"
                >
                    <v-select
                        @input="value => setCategory(value)"
                        @search:blur="validate"
                        @option:deselected="() => setCategory()"
                        :placeholder="categoryPlaceholder()"
                        :value="currentCategoryOption"
                        :options="categoryOptions"
                        :required="rules && rules.required"
                        :class="{
                            'is-invalid': errors.length
                        }"
                    ></v-select>
                    <div v-if="0 < errors.length" class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </validation-provider>
            </b-form-group>
        </b-col>
        <b-col cols="12" lg="6">
            <b-form-group class="mb-3 mb-lg-4">
                <template #label>
                    {{ subcategoryInputLabel }}
                    <span v-if="loadingSubcategories">
                        - Finding a {{ subcategoryInputLabel }}</span
                    >
                </template>
                <v-select
                    @input="value => setSubcategory(value)"
                    @option:deselected="() => setSubcategory()"
                    :placeholder="subcategoryPlaceholder"
                    :disabled="loadingSubcategories"
                    :value="currentSubcategoryOption"
                    :options="subcategoryOptions"
                ></v-select>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import CategoryService from '@serv/CategoryService'
export default {
    name: 'CategorySelect',
    data() {
        return {
            currentCategoryOption: this.makeOption(this.category),
            currentSubcategoryOption: this.makeOption(this.subcategory),
            subcategoryList: [],
            loadingSubcategories: false
        }
    },
    props: {
        categoryInputLabel: String,
        subcategoryInputLabel: String,
        category: Object,
        subcategory: Object,
        categories: {
            type: Array,
            default: () => []
        },
        rules: {
            type: [String, Array, Object],
            description: 'Vee validate validation rules',
            default: ''
        }
    },
    computed: {
        categoryOptions() {
            return this.categories.map(category => {
                return this.makeOption(category)
            })
        },
        subcategoryOptions() {
            if (!(this.subcategoryList && this.subcategoryList.length)) {
                return []
            }
            return this.subcategoryList.map(category => {
                return this.makeOption(category)
            })
        },
        subcategoryPlaceholder() {
            if (!this.currentCategoryOption) {
                return `Select a ${this.categoryInputLabel} to select a ${this.subcategoryInputLabel}`
            } else {
                return `Select a ${this.subcategoryInputLabel}`
            }
        },
        categoryValidatorName() {
            return this.categoryInputLabel.toLowerCase()
        },
        subcategoryValidatorName() {
            return this.subcategoryInputLabel.toLowerCase()
        }
    },
    methods: {
        setCategory(option) {
            var category = option ? option.value : undefined
            this.getSubcategoryOf(category)
            this.currentCategoryOption = option
            this.currentSubcategoryOption = undefined
            this.$emit('set-category', category)
            this.$emit('set-subcategory', undefined)
        },
        setSubcategory(option) {
            this.currentSubcategoryOption = option
            var subcategory = option ? option.value : undefined
            this.$emit('set-subcategory', subcategory)
        },
        categoryPlaceholder() {
            return `Select a ${this.categoryInputLabel}`
        },
        makeOption(category) {
            if (!category || !category.id) {
                return
            }
            return {
                label: category.name,
                value: category
            }
        },
        getSubcategoryOf(category) {
            if (!category || !category.id) {
                this.subcategoryList = []
                return
            }
            this.loadingSubcategories = true
            CategoryService.getSubcategoryOf(category.id)
                .then(subcategories => {
                    this.subcategoryList = subcategories
                })
                .finally(() => {
                    this.loadingSubcategories = false
                })
        }
    },
    beforeMount() {
        this.getSubcategoryOf(this.category)
    },
    mounted() {}
}
</script>
