class SubcategorySearchItem {
    constructor(object) {
        Object.keys(object).forEach(key => {
            this[key] = object[key]
        })
        //Use these across the app
        this.parent = object.categoryId
        this.parentName = object.categoryName
        this.name = object.subcategoryName
        this.id = object.subcategoryId
    }
}

export default SubcategorySearchItem
