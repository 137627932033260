<template>
    <div class="profile-form centered">
        <b-container>
            <b-row class="mt-5 mb-5 justify-content-center">
                <b-col md="10" lg="7" xl="5" class="mb-4 mb-sm-0">
                    <b-card
                        title="My Profile"
                        img-top
                        tag="article"
                        body-class="p-0"
                        class="mx-auto border-0"
                    >
                        <validation-observer
                            ref="formProfile"
                            v-slot="{ invalid }"
                            tag="form"
                        >
                            <b-card-text class="p-4 mb-0">
                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="firstName"
                                    :rules="{ required: true }"
                                    label="First Name"
                                    type="text"
                                    v-model="model.firstName"
                                />

                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="lastName"
                                    :rules="{ required: true }"
                                    label="Last Name"
                                    type="text"
                                    v-model="model.lastName"
                                />

                                <t-input
                                    alternative
                                    class="mb-3 mb-lg-4"
                                    name="email"
                                    :rules="{ required: true }"
                                    label="Email"
                                    type="text"
                                    v-model="model.email"
                                />
                                <div v-if="!showPasswordForm">
                                    <t-input
                                        alternative
                                        class="mb-3 mb-lg-4"
                                        disabled
                                        label="Password"
                                        type="password"
                                        value="************"
                                    />
                                    <p
                                        class="cta-password cursor-pointer"
                                        @click="showPasswordForm = true"
                                    >
                                        Change Password
                                    </p>
                                </div>
                                <div v-else>
                                    <t-input
                                        alternative
                                        class="mb-3 mb-lg-4"
                                        name="oldPassword"
                                        label="Current Password"
                                        type="password"
                                        v-model="model.oldPassword"
                                    />

                                    <t-input
                                        alternative
                                        class="mb-3 mb-lg-4"
                                        name="newPassword1"
                                        label="New Password"
                                        type="password"
                                        vid="newPassword1"
                                        v-model="model.newPassword1"
                                    />

                                    <t-input
                                        alternative
                                        class="mb-3 mb-lg-4"
                                        name="newPassword2"
                                        label="Confirm New Password"
                                        type="password"
                                        rules="confirmed:newPassword1"
                                        v-model="model.newPassword2"
                                    />
                                    <p
                                        class="cta-password cursor-pointer"
                                        @click="showPasswordForm = false"
                                    >
                                        Cancel Password Update
                                    </p>
                                </div>
                                <t-button
                                    @click="saveProfile()"
                                    type="secondary"
                                    :disabled="invalid"
                                    size="sm"
                                    class="py-2 px-3 px-xl-4 text-uppercase"
                                >
                                    {{ saveText }}
                                </t-button>
                            </b-card-text>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserService from '@serv/UserService'
import NotifyService from '@serv/NotifyService'

export default {
    name: 'Profile',
    metaInfo: {
        title: 'My Profile'
    },
    computed: {
        ...mapGetters(['user']),
        saveText() {
            return !!this.showPasswordForm ? 'Save Password' : 'Save Changes'
        }
    },
    data() {
        return {
            model: {},
            showPasswordForm: false
        }
    },
    methods: {
        saveProfile() {
            this.loading = true
            this.errors = {}
            const isValid = this.$refs.formProfile.validate()
            if (!isValid) {
                this.loading = false
                return
            }
            if (!this.showPasswordForm) {
                UserService.patchUser(this.model).then(() => {
                    NotifyService.notify(
                        this,
                        'Profile successfully updated',
                        'success'
                    )
                })
            } else {
                UserService.changePassword(this.model)
                    .then(() => {
                        NotifyService.notify(
                            this,
                            'Password successfully changed',
                            'success'
                        )

                        this.showPasswordForm = false
                    })
                    .catch(err => {
                        if (!NotifyService.notify400Error(this, err)) {
                            NotifyService.notify(
                                this,
                                'Your password could not be updated.',
                                'danger'
                            )
                        }
                    })
            }
        }
    },
    mounted() {
        if (this.user) {
            this.model = _.clone(this.user)
        }
    },
    watch: {
        user() {
            this.model = _.clone(this.user)
        }
    }
}
</script>
<style lang="scss">
.cta-password {
    color: #fb633f;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
