class Job {
    constructor(object) {
        const job = object.job || object

        Object.keys(job).forEach(key => {
            this[key] = job[key]
        })
        this.listingId = object.id
        this.listingStatus = object.status
        this.imageURL = this.images.length ? this.images[0].image : ''
        this.category = {
            id: job.category,
            name: job.categoryName ? job.categoryName : ''
        }
        this.subcategory = {
            id: job.subcategory,
            name: job.subcategoryName ? job.subcategoryName : ''
        }
    }
}

export default Job
