<template>
    <div class="workforce-form-page resume">
        <loading-overlay
            class="loading-list"
            v-if="!isLoaded"
            :state="!isLoaded"
        ></loading-overlay>

        <template v-if="isLoaded">
            <b-container>
                <b-row>
                    <b-col :md="isPublished ? '8' : '12'" cols="12">
                        <h3 class="h4 text-gray-1000 mb-4 fw-bold">Resume</h3>
                    </b-col>
                    <b-col
                        v-if="isPublished"
                        cols="12"
                        md="4"
                        class="d-flex align-items-center mb-4"
                    >
                        <b-row class="g-2 w-100 d-flex justify-content-md-end">
                            <b-col cols="12" md="6">
                                <b-button
                                    v-if="isPublished"
                                    title="Unpublish your resume"
                                    variant="outline-primary"
                                    :disabled="!isLoaded || processing"
                                    class="py-2 px-3 text-capitalize w-100"
                                    @click="callUnpublish"
                                    block
                                    >Unpublish
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <validation-observer ref="resumeValidator" tag="form">
                    <b-row>
                        <b-col cols="12">
                            <t-input
                                alternative
                                :rules="{ required: true }"
                                class="mb-3 mb-lg-4"
                                name="displayName"
                                label="Display Name"
                                placeholder="Enter Display Name"
                                type="text"
                                v-model="model.displayName"
                            />
                        </b-col>
                        <b-col cols="12">
                            <div
                                v-for="(area, idx) in formattedAreas"
                                :key="`desired_work_area_${idx}`"
                            >
                                <label
                                    v-if="idx === 0"
                                    :for="`desired_work_area_${idx}`"
                                    class="bv-no-focus-ring col-form-label pt-0"
                                >
                                    Desired Job Area - Type in your location (city and
                                    state) and select from the dropdown
                                    <small class="text-danger">*</small>
                                </label>
                                <div class="mb-3 mb-lg-4">
                                    <div class="d-flex">
                                        <div class="gmap-autocomplete-container me-2">
                                            <place-search
                                                @change="
                                                    place => onPlaceChanged(idx, place)
                                                "
                                                @blur="checkHasPlace()"
                                                :value="area"
                                                :class="{
                                                    'is-invalid':
                                                        placeErrors &&
                                                        placeErrors.length &&
                                                        placeErrors[idx]
                                                }"
                                            />
                                            <a
                                                v-if="area"
                                                href="javascript:;"
                                                @click="clearArea(idx)"
                                                class="clear-form-input"
                                            >
                                                <img
                                                    :src="
                                                        require('@assets/svg/times.svg')
                                                    "
                                                    alt=""
                                                />
                                            </a>
                                        </div>
                                        <b-button
                                            variant="outline-primary"
                                            size="sm"
                                            class="text-capitalize"
                                            @click="addOrRemoveArea(idx)"
                                        >
                                            <b-icon
                                                class="add-form-input-btn"
                                                v-if="idx == 0"
                                                icon="plus"
                                                size="lg"
                                                aria-hidden="true"
                                            ></b-icon>
                                            <b-icon
                                                class="add-form-input-btn"
                                                v-if="idx > 0"
                                                icon="dash"
                                                size="lg"
                                                aria-hidden="true"
                                            ></b-icon>
                                        </b-button>
                                    </div>
                                    <div
                                        v-if="idx === 0 && !hasPlaces"
                                        class="invalid-feedback d-block"
                                    >
                                        You must provide at least one job area
                                    </div>
                                    <div
                                        v-if="placeErrors && placeErrors.length"
                                        class="invalid-feedback d-block"
                                    >
                                        {{ placeErrors[idx] }}
                                    </div>
                                    <span class="d-block mt-2" v-if="idx === 0"
                                        >You can add multiple desired job areas (at
                                        least one is required)</span
                                    >
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <category-select
                                :categories="this.fieldTypes"
                                :rules="{ required: true }"
                                categoryInputLabel="Job Type"
                                subcategoryInputLabel="Speciality"
                                :category="this.category"
                                :subcategory="this.subcategory"
                                @set-category="
                                    category => {
                                        this.setCategory(category)
                                    }
                                "
                                @set-subcategory="
                                    subcategory => {
                                        this.setSubcategory(subcategory)
                                    }
                                "
                            />
                        </b-col>
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" lg="6" class="mb-3 mb-lg-4">
                                    <t-input
                                        alternative
                                        name="email"
                                        label="Email"
                                        placeholder="Enter Email"
                                        type="email"
                                        v-model="model.email"
                                    />
                                    <div
                                        v-if="serverErrors['email']"
                                        class="invalid-feedback d-block"
                                    >
                                        {{ serverErrors['email'][0] }}
                                    </div>
                                </b-col>
                                <b-col cols="12" lg="6" class="mb-3 mb-lg-4">
                                    <t-input
                                        alternative
                                        name="phone"
                                        label="Phone Number"
                                        placeholder="Enter Phone Number (e.g. +12125552368)"
                                        type="phone"
                                        v-model="model.phoneNumber"
                                        :inputClasses="
                                            serverErrors['phoneNumber']
                                                ? 'is-invalid'
                                                : ''
                                        "
                                    />
                                    <div
                                        v-if="serverErrors['phoneNumber']"
                                        class="invalid-feedback d-block"
                                    >
                                        {{ serverErrors['phoneNumber'][0] }}
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group class="mb-3 mb-lg-4">
                                <template #label> Description </template>
                                <validation-provider
                                    name="description"
                                    v-bind="$attrs"
                                    v-slot="{ errors, validated }"
                                >
                                    <b-textarea
                                        class="text-area"
                                        :class="{
                                            'is-invalid': errors.length && validated
                                        }"
                                        v-model="model.description"
                                        rows="10"
                                        cols="50"
                                    ></b-textarea>
                                    <div
                                        v-if="0 < errors.length"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[0] }}
                                    </div>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12" md="6">
                                    <label class="bv-no-focus-ring col-form-label pt-0"
                                        >Upload Profile Photo</label
                                    >
                                    <ImagesUploader
                                        v-model="imageFiles"
                                        :limit="1"
                                        :disabled="processing"
                                    />
                                </b-col>
                                <b-col cols="12" md="6" class="mt-md-0 mt-3">
                                    <div class="resume-uploader-container">
                                        <!--<input type="file" @change="onChangeResumeFile">-->
                                        <label
                                            class="bv-no-focus-ring col-form-label pt-0"
                                            >Upload Resume</label
                                        >

                                        <ResumeUploader v-model="resumeFile" />
                                        <div
                                            v-if="resumeFile"
                                            class="resume-link-container mt-4"
                                        >
                                            <a
                                                href="javascript:;"
                                                class="color-primary"
                                                @click.stop.prevent="removeResume()"
                                                >{{ fileName }}
                                                <b-icon
                                                    icon="x-circle-fill"
                                                    aria-hidden="true"
                                                ></b-icon
                                            ></a>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12 mb-5">
                            <b-row>
                                <b-col cols="12" lg="6">
                                    <b-button
                                        class="mt-3 py-3 text-capitalize w-100"
                                        @click="previewListing"
                                        block
                                        variant="outline-primary"
                                        >Preview
                                    </b-button>
                                </b-col>

                                <b-col cols="12" lg="6">
                                    <b-row class="g-2">
                                        <b-col v-if="isPublished" cols="6">
                                            <b-button
                                                variant="outline-primary"
                                                :disabled="!isLoaded || processing"
                                                class="mt-3 py-3 text-capitalize w-100"
                                                @click="callUnpublish"
                                                block
                                                >Unpublish
                                            </b-button>
                                        </b-col>
                                        <b-col :cols="!isPublished ? '12' : '6'">
                                            <b-button
                                                :disabled="
                                                    !isValid || !isLoaded || processing
                                                "
                                                class="mt-3 py-3 text-capitalize w-100"
                                                variant="accent"
                                                @click="save"
                                                block
                                                >{{ publishButtonText }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </validation-observer>
                <b-modal
                    class="centered"
                    size="xl"
                    id="preview-modal-resume"
                    :hide-footer="true"
                >
                    <template #modal-title>PREVIEW</template>
                    <workforce-detail-basic
                        :displayName="this.model.displayName"
                        :jobType="this.category"
                        :speciality="this.subcategory"
                        :description="this.model.description"
                        :preferedArea="this.model.preferedArea"
                        :resume="this.model.resume"
                        :profilePhoto="this.model.profilePhoto"
                        :phoneNumber="this.model.phoneNumber"
                        :email="this.model.email"
                    />
                </b-modal>

                <b-modal
                    v-if="!this.user.checkActiveFindJobsProduct()"
                    class="modal"
                    id="find-jobs-subscription-modal"
                    centered
                    ok-title="Subscribe"
                    variant="primary"
                    @ok="redirect('Account')"
                >
                    <template #modal-title> Workforce - Post job openings </template>
                    <workforce-product-type
                        :includeFindJobs="true"
                        :includePostJobs="false"
                    />
                </b-modal>
                <b-modal
                    v-if="isPublished"
                    size="md"
                    class="centered"
                    id="unpublish-resume-modal"
                    return-focus="body"
                    @ok="unpublishResume()"
                    ok-title="Unpublish"
                >
                    <template #modal-title>Unpublish Resume?</template>
                    <div>
                        This will remove the resume from active status; it will no
                        longer be seen by the public. You may re-publish this resume at
                        any-time.
                    </div>
                </b-modal>
            </b-container>
        </template>
    </div>
</template>
<script>
//import Schedule from '@comp/Schedule'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import WorkforceService from '@serv/WorkforceService'
import { mapGetters } from 'vuex'
import ResumeUploader from '@comp/ResumeUploader'
import WorkforceDetailBasic from '@comp/detail/WorkforceDetailBasic'
import Redirect from '@serv/Redirect'
//import Checkout from '@comp/Checkout'
import NotifyService from '@serv/NotifyService'
//import RunoutModal from '@comp/modal/RunoutModal'
//import PublishModal from '@comp/modal/PublishModal'
//import TypeModal from '@comp/modal/TypeModal'
import FormMixin from '@mixins/FormMixin'
import WorkforceProductType from '@comp/WorkforceProductType'
import LoadingOverlay from '@comp/LoadingOverlay'
import { MessageBox } from 'element-ui'
import Vue from 'vue'
import CategorySelect from '@comp/CategorySelect'
import ImagesUploader from '@comp/ImagesUploader'
import ImagesUploaderMixin from '@mixins/ImagesUploaderMixin'
import PlaceSearch from '@comp/PlaceSearch'
import { debounce } from '@util/helpers'
locale.use(lang)

const initialModel = () => {
    return {
        id: undefined,
        description: '',
        displayName: '',
        category: undefined,
        subcategory: undefined,
        preferedArea: [],
        zipcode: '',
        state: undefined,
        resume: undefined,
        phoneNumber: '',
        email: '',
        profilePhoto: undefined
    }
}

export default {
    name: 'AddResume',
    components: {
        ResumeUploader,
        //Schedule,
        //RunoutModal,
        //PublishModal,
        //TypeModal,
        //Checkout,
        WorkforceProductType,
        WorkforceDetailBasic,
        LoadingOverlay,
        CategorySelect,
        ImagesUploader,
        PlaceSearch
    },
    mixins: [FormMixin, ImagesUploaderMixin],
    data() {
        return {
            isValid: false,
            model: initialModel(),
            places: [undefined],
            processing: false,
            category: undefined,
            subcategory: undefined,
            resumeFile: undefined,
            loaded: false,
            serverErrors: {},
            placeErrors: [],
            hasPlaces: []
        }
    },
    props: {
        form: {
            type: Object
        },
        create: {
            type: Boolean
        }
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters({
            fieldTypes: 'workforceFieldTypes',
            types: 'workforceTypes',
            preferedArea: 'preferedArea',
            fieldTypeByName: 'workforceFieldTypeByName',
            fieldTypeById: 'workforceFieldTypeById',
            preferedAreaByName: 'preferedAreaByName',
            preferedAreaById: 'preferedAreaById',
            queryPreferedArea: 'queryPreferedArea',
            categories: 'workforceCategories',
            subcategoriesOf: 'workforceSubcategoriesOf'
        }),
        isLoaded() {
            return this.loaded
        },
        fileName() {
            var name = ''
            var resumeFile = this.resumeFile
            if (typeof resumeFile === 'object' && resumeFile.name) {
                name = resumeFile.name.replace(' ', '_')
            } else if (typeof resumeFile === 'string') {
                name = resumeFile.split('/').pop()
            }
            return name
        },
        formattedAreas() {
            if (!this.places.length) {
                return ['']
            }
            return this.places.map(area => {
                if (!area) {
                    return ''
                }
                if (area.inputValue) {
                    return area.inputValue
                }
                if (area.city && area.state) {
                    return `${area.city}, ${area.state}`
                }
            })
        },
        isSaved() {
            return this.model.id
        },
        isPublished() {
            return this.isSaved && this.model.status === 'Published'
        },
        publishButtonText() {
            return this.isPublished ? 'Save' : 'Publish'
        }
    },
    methods: {
        addOrRemoveArea(idx) {
            if (idx == 0) {
                this.places.push(undefined)
            } else {
                this.places.splice(idx, 1)
            }
            this.removePlaceError(idx)
        },
        onPlaceChanged(idx, place) {
            if (!place) {
                this.clearArea(idx)
            } else {
                Vue.set(this.places, idx, place)
            }
            this.removePlaceError(idx)
        },
        setCategory(category) {
            this.category = category
            this.model.category = category ? category.id : null
        },
        setSubcategory(subcategory) {
            this.subcategory = subcategory
            this.model.subcategory = subcategory ? subcategory.id : null
        },
        checkHasPlace() {
            var places = this.places.filter(place => place)
            this.hasPlaces = places.length
        },
        async save() {
            if (this.user.checkActiveFindJobsProduct()) {
                this.processing = true
                this.serverErrors = {}
                this.placeErrors = []

                //First, assign indexes to the objects to map possible error messages
                var areaObjects = this.model.preferedArea.reduce((acc, area, i) => {
                    if (area && typeof area === 'object') {
                        acc.push({ ...area, index: i })
                    }
                    return acc
                }, [])

                // Then try to create the new preferedArea if any.
                let newAreas = await WorkforceService.createPreferedArea(areaObjects)
                if (newAreas && newAreas.length) {
                    for (const [i, area] of newAreas.entries()) {
                        //If the area creation fails, show the error
                        if (area.status === 'rejected') {
                            var error = area.reason.response.data
                            NotifyService.notify400Error(this, area.reason)
                            this.placeErrors[areaObjects[i].index] = error[0]
                        } else {
                            //Update model with the newly created area/locations ids to save them later alongside
                            //the profile
                            this.model.preferedArea[areaObjects[i].index] =
                                area.value.id
                        }
                    }
                }
                //If there are location/area related errors, stop here.

                if (this.placeErrors.length) {
                    this.processing = false
                    return
                }

                try {
                    const newProfileModel = await WorkforceService.saveWorkerProfile(
                        this.model
                    )
                    NotifyService.notify(this, 'Resume updated successfully', 'success')
                    this.model = newProfileModel
                    this.serverErrors = {}
                } catch (error) {
                    if (error.response) {
                        var data = error.response.data
                        this.serverErrors = data
                    }
                    if (!NotifyService.notify400Error(this, error)) {
                        NotifyService.notify(
                            this,
                            'The resume could not be updated',
                            'danger'
                        )
                    }
                } finally {
                    this.processing = false
                }
            } else {
                this.$bvModal.show('find-jobs-subscription-modal')
            }
        },
        previewListing() {
            this.$bvModal.show('preview-modal-resume')
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        setUserWorkerProfile(form) {
            var categoryObject = form.category
            var subcategoryObject = form.subcategory

            form.category = form.category && form.category.id
            form.subcategory = form.subcategory && form.subcategory.id

            this.category = categoryObject
            this.subcategory = subcategoryObject

            this.resumeFile = form.resume
            this.imageFiles = form.profilePhoto ? [{ url: form.profilePhoto }] : []
            this.isValid = true

            form.user = this.user.id
            this.model = form

            if (this.model.preferedArea.length) {
                this.places = this.model.preferedArea.map(areaId => {
                    return this.preferedAreaById(areaId)
                })
            }
        },
        manualValidation() {
            this.manualValidationDebaunce()
        },
        removeResume() {
            MessageBox.confirm(`Do you want to remove this file?`, 'Remove File').then(
                rs => {
                    if ('confirm' !== rs) return
                    this.resumeFile = undefined
                }
            )
        },
        clearArea(idx) {
            Vue.set(this.places, idx, undefined)
            this.removePlaceError(idx)
        },
        removePlaceError(idx) {
            if (this.placeErrors.length && this.placeErrors[idx]) {
                this.placeErrors.splice(idx, 1)
            }
        },
        callUnpublish() {
            this.$bvModal.show('unpublish-resume-modal')
        },
        unpublishResume() {
            this.processing = true
            WorkforceService.updateWorkerProfile({
                id: this.model.id,
                status: 'Unpublished'
            })
                .then(newModel => {
                    NotifyService.notify(
                        this,
                        'Your resume has been unpublished',
                        'success'
                    )
                    this.model = newModel
                })
                .catch(() => {
                    NotifyService.notify(
                        this,
                        'Your resume could not be unpublished',
                        'success'
                    )
                })
                .finally(() => {
                    this.processing = false
                })
        }
    },
    created() {
        this.manualValidationDebaunce = debounce(() => {
            var flags = this.$refs['resumeValidator'].flags
            this.isValid = this.hasPlaces && flags.valid
        }, 400)
    },
    beforeMount() {
        var getWorkerProfile = WorkforceService.getWorkerProfiles()
            .then(profile => {
                if (profile) {
                    this.setUserWorkerProfile(profile)
                } else {
                    console.log('No worker profile to retrieve.')
                }
            })
            .catch(() => {
                console.log('Could not retrieve worker profile.')
            })
        this.getWorkerProfileProcess = getWorkerProfile
    },
    mounted() {
        this.getWorkerProfileProcess.finally(() => {
            this.loaded = true
        })
    },
    watch: {
        model: {
            handler() {
                this.manualValidation()
            },
            deep: true
        },
        places(newVal) {
            var places = newVal.filter(place => place)
            this.checkHasPlace()
            this.model.preferedArea = places.map(place => {
                var area = this.queryPreferedArea(place)
                if (area) {
                    return area.id
                }
                return place
            })
        },
        resumeFile(file) {
            this.model.resume = file
        },
        imageFiles() {
            var images = this.formatImages()
            if (images.length) {
                this.model.profilePhoto = images[0]
            } else {
                this.model.profilePhoto = undefined
            }
        }
    }
}
</script>
<style lang="scss">
@media screen and (min-width: 900px) {
    .workforce-form-page .container {
        width: 900px;
    }
}

.clear-icon {
    cursor: pointer;
    font-size: 160%;
    position: absolute;
    right: 20px;
    top: 37px;
    display: flex;
    float: right;
    z-index: 1000;
}
.gmap-autocomplete-container {
    display: inline-block;
    width: 100%;
    position: relative;
}
.gmap-autocomplete-container input {
    height: 46px;
    padding-left: 30px;
    padding-right: 45px;
    border: 0;
}
.gmap-autocomplete-container input:focus {
    outline: none;
    color: #888888;
}
.area-btn {
    width: 24px !important;
    height: 24px !important;
}
.clear-area {
    color: rgba(60, 60, 60, 0.5);
    fill: rgba(60, 60, 60, 0.5);
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 24px !important;
    height: 24px !important;
    display: inline-block;
    cursor: pointer;
}
.form-control {
    box-shadow: 0px 1px 3px rgb(50 50 93 / 15%), 0px 1px 0px rgb(0 0 0 / 2%) !important;
    color: var(--bs-body-color) !important;
}
.form-control-label {
    color: var(--bs-body-color) !important;
}
.resume .el-upload,
.resume .el-upload-dragger {
    width: 100%;
    max-width: 100%;
}
</style>
