import Vue from 'vue'
import Vuex from 'vuex'
import user from '@modules/user'
import resources from '@modules/resources'
import jobResources from '@modules/jobResources'
import workforceResources from '@modules/workforceResources'
import preferedAreaResources from '@modules/preferedAreaResources'
import categoryResources from '@modules/categoryResources'
import token from '@modules/token'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        resources,
        token,
        jobResources,
        workforceResources,
        preferedAreaResources,
        categoryResources
    },
    strict: process.env.NODE_ENV !== 'production'
})
