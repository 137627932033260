<template>
    <div class="homepage">
        <CouponBanner />
        <section
            id="banner"
            class="py-5 text-gray-600 ff-primary text-center"
            :style="{
                backgroundImage: `url(${require('@assets/images/waiting-room.png')})`
            }"
        >
            <b-container class="pt-lg-2 intro">
                <h1 class="fs-2 mb-3 text-primary fw-bold fst-italic">
                    Find Your Job, Find Your Match!
                </h1>
                <p>
                    A simplified method for bringing people, opportunities, and spaces
                    together. Enter where and what type of job you are looking for!
                </p>

                <div class="search mt-4 mx-auto">
                    <gmap-autocomplete
                        placeholder="Enter city and state"
                        :selectFirstOnEnter="true"
                        :types="['(regions)']"
                        @place_changed="setPlace"
                        :value="search"
                        :componentRestrictions="{ country: 'us' }"
                        class="search__location"
                    />

                    <div class="search__options">
                        <b-form-group clas="form-inline" v-slot="{ ariaDescribedby }">
                            <h6 class="mx-auto mb-3 fw-bold d-block type-field">
                                Type of Job:
                            </h6>
                            <category-search
                                class="search__category"
                                :value="categorySearch"
                                @select="onSelectCategory"
                                @clear-input="onCategorySearchClear"
                            />
                        </b-form-group>
                    </div>

                    <b-button
                        @click="redirect('JobListing')"
                        variant="secondary"
                        class="search__button w-100 mt-4 mt-lg-5 text-capitalize"
                    >
                        Search for a Job</b-button
                    >
                </div>
            </b-container>
        </section>

        <section>
            <subscription
                class="mt-3 mt-lg-4"
                v-if="!auth"
                @changepage="subscriptionRedirect()"
            />
        </section>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CouponBanner from '@comp/CouponBanner'
import Subscription from '@comp/WorkforceProductType'
import HomeMixin from '@mixins/HomeMixin'
import Redirect from '@serv/Redirect'
import CategorySearch from '@comp/CategorySearch'

export default {
    name: 'JobHomePage',
    metaInfo: 'Find a Job',
    computed: {
        ...mapGetters({
            types: 'jobTypes',
            fieldTypes: 'jobFieldTypes',
            filters: 'jobFilters'
        })
    },
    mixins: [HomeMixin],
    components: { CouponBanner, Subscription, CategorySearch },
    methods: {
        ...mapMutations({
            setSearchQuery: 'setJobSearchQuery',
            clearSearchQuery: 'clearJobSearchQuery',
            addListFilter: 'addJobListFilter',
            removeListFilter: 'removeJobListFilter',
            clearFilters: 'clearJobFilters'
        }),
        subscriptionRedirect() {
            Redirect.gotoName('Register', null, { workforceSignUp: true })
        }
    },
    created() {},
    mounted() {}
}
</script>
<style lang="scss">
@import './../../assets/sass/pages/_home.scss';
</style>
