<template>
    <b-row class="text-center">
        <b-col
            :style="{ overflowY: iframeReady ? 'auto' : 'hidden' }"
            :class="{ ready: iframeReady }"
            @scroll="onScroll"
            class="iframe-wrapper"
            ref="iframeWrapper"
        >
            <iframe
                v-iresize="{
                    log: false,
                    autoResize: true,
                    heightCalculationMethod: 'taggedElement'
                }"
                @load="() => (iframeReady = true)"
                :style="{ height: iframeReady ? '9250px' : '50vh' }"
                src="https://mmforbiz.com/terms-and-conditions/"
                id="termsconditions"
                scrolling="no"
                ref="iframe"
            ></iframe>
        </b-col>
    </b-row>
</template>

<script>
import iframeResize from 'iframe-resizer/js/iframeResizer'
import Vue from 'vue'

// Workaround to make iframe-resizer work with Vue.
Vue.directive('iresize', {
    bind: function(el, { value = {} }) {
        el.addEventListener('load', () => iframeResize(value, el))
    },
    unbind: function(el) {
        el.iFrameResizer.removeListeners()
    }
})

export default {
    name: 'Information',
    metaInfo: {
        title: 'T&C'
    },
    components: {},
    data() {
        return {
            iframeReady: false,
            fullyScrolled: false
        }
    },
    props: {
        crumbs: {
            type: Array
        }
    },
    methods: {
        onScroll(evt) {
            var containerHeight = this.$refs.iframeWrapper.clientHeight
            const scrollPct = Math.ceil(
                (Math.round(evt.target.scrollTop) /
                    (evt.target.scrollHeight - containerHeight)) *
                    100
            )
            this.fullyScrolled = this.iframeReady && 95 <= scrollPct
        }
    },
    watch: {
        fullyScrolled(newVal) {
            this.$emit('fully-scrolled', newVal)
        }
    }
}
</script>

<style lang="scss" scoped>
.iframe-wrapper {
    max-height: 50vh;
    border: 2px solid #10377a;

    &:not(.ready) {
        background: url(../assets/images/loader.gif) center center no-repeat;
    }

    #termsconditions {
        width: 100%;
    }
}
</style>
