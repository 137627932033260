<template>
    <loading-overlay v-if="!loaded" :state="!loaded"></loading-overlay>
    <b-container v-else class="pb-5">
        <h1 class="page-title mb-3 text-left">
            Flagged Listings
        </h1>
        <el-input
            placeholder="Search by title or address"
            type="text"
            v-model="search"
            @input="searchFilter()"
        />
        <el-table
            class="pt-4"
            :data="filteredData"
            :default-sort="{ prop: 'addressDisplay' }"
            stripe
        >
            <el-table-column prop="addressDisplay" label="Title" sortable width="300">
                <template slot-scope="scope">
                    <b class="title">{{ scope.row.addressDisplay }}</b>
                </template>
            </el-table-column>
            <el-table-column
                prop="locationDisplay"
                label="Address"
                sortable
                width="600"
            />
            <el-table-column label="Actions" width="350">
                <template slot-scope="scope">
                    <b-button class="me-2" @click="showFlags(scope.row.listingId)"
                        >View Flag Comments</b-button
                    >
                    <b-button @click="callAction('Delete', scope.row)"
                        >Remove Listing</b-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <b-modal
            size="lg"
            :hide-footer="listingFlagsCount <= pageSize"
            :content-class="{ 'has-pagination': listingFlagsCount > pageSize }"
            return-focus="body"
            :id="`listing-flags-modal`"
        >
            <template #modal-title>Flag Comments</template>
            <div v-if="listingFlags.length == 0">
                <b-row class="listing-row">
                    <b-col cols="12">
                        No comments to show.
                    </b-col>
                </b-row>
            </div>
            <div
                v-else
                class="flag-list-container"
                v-for="(flag, idx) in listingFlags"
                :key="idx"
            >
                <div class="flag-item">
                    <h5 class="text-gray-800 mb-2 fw-bold">
                        {{ flag.title }}
                    </h5>
                    <span class="text-gray-600">
                        {{ flag.body }}
                    </span>
                    <a
                        href="javascript:;"
                        class="mb-1 delete-flag-icon"
                        @click="callDeleteFlag(flag.id)"
                        title="Delete flag."
                    >
                        <b-icon icon="trash" variant="primary"></b-icon>
                    </a>
                </div>
            </div>
            <template #modal-footer v-if="listingFlagsCount > pageSize">
                <div class="d-flex justify-content-center w-100">
                    <el-pagination
                        small
                        :page-size="pageSize"
                        :pager-count="5"
                        layout="prev, pager, next"
                        @current-change="onCurrentPageChange"
                        :total="listingFlagsCount"
                    >
                    </el-pagination>
                </div>
            </template>
        </b-modal>
        <b-modal
            size="md"
            class="centered"
            id="delete-flag-modal"
            return-focus="body"
            @ok="deleteFlag()"
            ok-title="Delete"
        >
            <template #modal-title>Delete Listing?</template>
            <div>
                Are you sure you want to delete this flag?<br />
                This action cannot be undone.
            </div>
        </b-modal>
        <b-modal
            size="md"
            class="centered"
            id="delete-listing-modal"
            return-focus="body"
            @ok="deleteListing()"
            ok-title="Delete"
        >
            <template #modal-title>Delete Listing?</template>
            <div>
                Are you sure you want to delete this listing?<br />
                This action cannot be undone.
            </div>
        </b-modal>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { Table, TableColumn, Input, Pagination } from 'element-ui'
import LoadingOverlay from '@comp/LoadingOverlay'
import NotifyService from '@serv/NotifyService'
import PropertyMixin from '@mixins/PropertyMixin'
import PropertyService from '@serv/PropertyService'
export default {
    name: 'List',
    metaInfo: {
        title: 'Users list'
    },
    components: {
        LoadingOverlay,
        [Input.name]: Input,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Pagination.name]: Pagination
    },
    data() {
        return {
            loaded: false,
            filteredData: [],
            currentListingId: undefined,
            currentFlagId: undefined,
            currentPage: 1,
            pageSize: 5,
            search: '',
            modalObject: undefined
        }
    },
    mixins: [PropertyMixin],
    props: {},
    computed: {
        ...mapGetters(['user']),

        listingFlagsCount() {
            var currentListingId = this.currentListingId
            if (!currentListingId) {
                return []
            }
            var data = this.filteredData
            var listingData = data.find(
                ({ listingId }) => listingId === currentListingId
            )
            var flags = listingData.flag
            if (!flags.length) {
                return 0
            }
            return flags.length
        },
        listingFlags() {
            var currentListingId = this.currentListingId
            if (!currentListingId) {
                return []
            }
            var data = this.filteredData

            var listingData = data.find(
                ({ listingId }) => listingId === currentListingId
            )
            var flags = listingData.flag
            if (!flags.length) {
                return []
            }
            flags = this.sortMostRecent(flags)
            var page = this.getPage(this.currentPage, flags)
            //When the last item from the current page is deleted,
            //go to the previous page.
            if (page.length === 0 && this.currentPage > 1) {
                page = this.getPage(this.currentPage - 1, flags)
            }
            flags = page
            return flags
        }
    },
    methods: {
        debounce: _.debounce,
        searchFilter() {
            const test = this.tableData.filter(data => {
                var search = this.search.toLowerCase()
                return (
                    data.addressDisplay.toLowerCase().includes(search) ||
                    data.locationDisplay.toLowerCase().includes(search)
                )
            })
            this.filteredData = test
        },
        showFlags(listingId) {
            this.currentListingId = listingId
            this.currentPage = 1
            this.$bvModal.show('listing-flags-modal')
        },
        callAction(action, property) {
            this.modalObject = property
            if (action == 'Delete') {
                this.$bvModal.show('delete-listing-modal')
            }
        },
        callDeleteFlag(flagId) {
            this.currentFlagId = flagId
            this.$bvModal.show('delete-flag-modal')
        },
        gotoProperty(id) {
            this.$router.push({
                name: 'Detail',
                params: { id: id, isListing: false, adminRedirectToUsers: true }
            })
        },
        deleteFlag() {
            var listingId = this.currentListingId
            var flagId = this.currentFlagId
            PropertyService.deleteFlag(flagId).then(() => {
                var index = this.tableData.findIndex(l => listingId === l.listingId)
                var listing = this.tableData[index]
                var flags = listing.flag.filter(({ id }) => flagId !== id)
                this.tableData[index].flag = flags
                this.currentFlagId = undefined
                this.searchFilter()
                NotifyService.notify(this, 'Flag removed successfully', 'success')
            })
        },
        deleteListing() {
            var spaceId = this.modalObject.id
            PropertyService.deleteProperty(spaceId).then(() => {
                this.tableData = this.tableData.filter(({ id }) => spaceId !== id)
                this.searchFilter()
                NotifyService.notify(this, 'Listing removed successfully', 'success')
            })
        },
        onCurrentPageChange(currentPage) {
            this.currentPage = currentPage
        },
        getPage(page, data) {
            var offset = (page - 1) * this.pageSize
            var limit = page * this.pageSize
            var pageItems = data.slice(offset, limit)
            this.currentPage = page
            return pageItems
        },
        sortMostRecent(flags) {
            return flags.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
        }
    },
    beforeMount() {
        this.getListProcess = PropertyService.getFlaggedListings().then(data => {
            this.tableData = data
            this.tableData.forEach(property => {
                property.addressDisplay = this.propertyAddress(property)
                property.locationDisplay = this.propertyLocation(property)
            })

            this.filteredData = data
            this.loaded = true
        })
    },
    mounted() {
        this.getListProcess.finally(() => {
            this.loaded = true
        })
    },
    watch: {}
}
</script>

<style lang="scss">
#listing-flags-modal .modal-body {
    height: auto;
    max-height: calc(100vh - 121px);
    padding: 24px;
    overflow: auto;
}
#listing-flags-modal .modal-content.has-pagination .modal-body {
    max-height: calc(100vh - 181px);
}
#listing-flags-modal .modal-body .flag-list-container + .flag-list-container {
    border-top: 1px solid #e5e5e5;
}
.flag-item {
    position: relative;
    padding: 12px 24px;
}
.flag-item + .flag-item {
    border-top: 1px solid #e5e5e5;
}
.flag-item .delete-flag-icon {
    position: absolute;
    top: 12px;
    right: 12px;
}
</style>
