<template>
    <div class="list-container">
        <list-filters-basic
            @address-changed="value => (savedSearchAddress = value)"
            @add-filter="onBasicFilter"
            @clear-filters="clearPlace"
            @clear-filter="onClearFilter"
        />

        <loading-overlay
            class="loading-list"
            v-if="!isLoaded"
            :state="!isLoaded"
        ></loading-overlay>

        <template v-if="isLoaded">
            <div v-if="count > 0">
                <banner
                    :bannerText="
                        `${searchResultsText} - Create an account and purchase a 'Post Jobs' product to view the details!`
                    "
                    :cta="'Create Account'"
                    :redirect="'WorkforceRegister'"
                    v-if="!auth"
                />
                <banner
                    :bannerText="
                        `${searchResultsText} - Please purchase a 'Post Jobs' product to view the details!`
                    "
                    :cta="'Purchase Post Jobs'"
                    :redirect="'Account'"
                    v-if="
                        auth &&
                            user &&
                            !user.checkActivePostJobsProduct() &&
                            !user.isSuperuser
                    "
                />

                <div class="list">
                    <b-overlay
                        :show="isProcessing"
                        class="align-items-center"
                        rounded="sm"
                        spinner-type="grow"
                        spinner-small
                    >
                        <b-container>
                            <b-row v-if="0 < list.length">
                                <b-col
                                    v-for="property in list"
                                    :key="`worker_profile_listing_${property.id}`"
                                    cols="12"
                                    sm="6"
                                    class="mb-4"
                                >
                                    <workforce-listing
                                        :displayName="property.displayName"
                                        :jobType="property.category"
                                        :speciality="property.subcategory"
                                        :description="property.description"
                                        :preferedArea="property.preferedArea"
                                        :profilePhoto="property.profilePhoto"
                                        @click:expand="
                                            () => gotoDetail(property.listingId)
                                        "
                                        @click="gotoProperty(property.listingId)"
                                    >
                                    </workforce-listing>
                                </b-col>
                            </b-row>
                            <no-data-placeholder v-else>
                                <template v-if="hasFilters" #sub
                                    >Please refine your search.</template
                                >
                            </no-data-placeholder>
                        </b-container>
                    </b-overlay>
                </div>
            </div>
            <b-container v-else class="py-5 text-gray-600 text-center">
                <b-row class="justify-content-center">
                    <b-col md="8" lg="6">
                        <no-data-placeholder>
                            <h1 class="mb-3 fs-2 fw-bold">
                                No Workforce Found.
                            </h1>
                        </no-data-placeholder>
                    </b-col>
                </b-row>
            </b-container>
            <!--<b-modal
                size="md"
                class="centered"
                id="search-modal"
                :ok-disabled="searchString.length <= 0"
                @ok="saveSearch()"
                ok-title="Save"
            >
                <validation-observer ref="searchValidator">
                    <template #modal-title>Save search</template>
                    <div>
                        <t-input
                            input-classes="border rounded-pill"
                            name="search"
                            placeholder="Search Name"
                            v-model="searchString"
                        ></t-input>
                    </div>
                </validation-observer>
            </b-modal>-->
        </template>
        <runout-modal :list="true" :scope="this" />
        <b-modal
            v-if="this.auth && !this.user.checkActivePostJobsProduct()"
            class="modal"
            id="post-jobs-subscription-modal"
            centered
            ok-title="Subscribe"
            variant="primary"
            @ok="redirect('Account')"
        >
            <template #modal-title>
                Workforce - Post job openings
            </template>
            <workforce-product-type :includeFindJobs="false" :includePostJobs="true" />
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import Redirect from '@serv/Redirect'
import LoadingOverlay from '@comp/LoadingOverlay'
import ListFiltersBasic from '@comp/filters/WorkforceListFiltersBasic'
//import ListFiltersModal from '@comp/ListFiltersModal'
import NoDataPlaceholder from '@comp/NoDataPlaceholder'
import WorkforceService from '@serv/WorkforceService'
import Banner from '@comp/Banner'
//import StringMixin from '@mixins/StringMixin'
import RunoutModal from '@comp/modal/RunoutModal'
import WorkforceListing from '@comp/listing/WorkforceListing'
import ListMixin from '@mixins/ListMixin'
import NotifyService from '@serv/NotifyService'
import WorkforceProductType from '@comp/WorkforceProductType'

export default {
    name: 'WorkforceList',
    metaInfo: 'Worforce Listings',
    components: {
        Banner,
        LoadingOverlay,
        ListFiltersBasic,
        //ListFiltersModal,
        RunoutModal,
        NoDataPlaceholder,
        WorkforceListing,
        WorkforceProductType
    },
    mixins: [ListMixin],
    computed: {
        ...mapGetters(['user', 'auth']),
        ...mapGetters({
            searchQuery: 'workforceSearchQuery',
            filtersQuery: 'workforceFiltersQuery',
            filters: 'workforceFilters',
            types: 'workforceTypes'
        }),
        searchResultsText() {
            const listing = this.suffixIf(this.count > 1, 'Workforce', 's')

            return `${this.count} ${listing} on the platform `
        }
    },
    methods: {
        ...mapMutations({
            setTypes: 'setWorkforceTypes',
            setSearchQuery: 'setWorkforceSearchQuery',
            clearSearch: 'clearWorkforceSearchQuery',
            clearFilters: 'clearWorkforceFilters',
            removeListFilter: 'removeWorkforceListFilter',
            addListFilter: 'addWorkforceListFilter'
        }),
        getListings() {
            return WorkforceService.getProperties()
        },
        getListingsByQuery(query) {
            return WorkforceService.getPropertiesByQuery(query)
        },
        postSearch(payload) {
            return WorkforceService.postSearch(payload)
        },
        noResultNotify(notify) {
            return WorkforceService.noResultNotify(notify)
        },
        goBack() {
            return Redirect.gotoName('SearchWorkForce')
        },
        gotoDetail(listingId) {
            if (this.auth && this.user.checkActivePostJobsProduct()) {
                this.$router.push({
                    name: 'WorkforceDetail',
                    params: { id: listingId }
                })
            }
        },
        gotoProperty(listingId) {
            if (this.auth) {
                if (this.user.checkActivePostJobsProduct()) {
                    this.$router.push({
                        name: 'WorkforceDetail',
                        params: { id: listingId }
                    })
                } /*else if (this.user.containsView) {
                    this.$bvModal.show('runout-modal-0')
                }*/ else {
                    this.$bvModal.show('post-jobs-subscription-modal')
                }
            } else {
                NotifyService.confirm(
                    'Sign Up Required',
                    'You need an active account to continue.'
                ).then(rs => {
                    if ('confirm' !== rs) return
                    Redirect.gotoName('Register', null, { workforceSignUp: true })
                })
            }
        }
    },
    beforeMount() {},
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
@import './../../assets/sass/pages/_list.scss';
</style>
