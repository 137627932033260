export default {
    data() {
        return {
            imageFiles: []
        }
    },
    methods: {
        formatImages() {
            return this.imageFiles.filter(file => file.image || file.url)
        }
    }
}
