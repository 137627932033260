<template>
    <div class="carousel">
        <el-carousel
            :height="aspectRatio ? 'auto' : height"
            :class="aspectRatio ? 'aspect-ratio' : null"
            :autoplay="false"
            :arrow="files.length > 1 ? 'always' : 'never'"
            trigger="click"
            type="image"
            class="rounded"
        >
            <el-carousel-item v-for="(image, key) in files" :key="key" class="rounded">
                <template v-if="imageAsBackground">
                    <div
                        v-if="image.image"
                        class="image as-background"
                        :style="{ backgroundImage: `url(http://${image.image})` }"
                    ></div>
                    <div
                        v-else-if="image.url"
                        class="image as-background"
                        :style="{ backgroundImage: `url(${image.url})` }"
                    ></div>
                </template>
                <template v-else>
                    <el-image
                        v-if="!image.uid && image.image"
                        class="uploaded image"
                        :src="`http://${image.image}`"
                    ></el-image>
                    <el-image
                        v-else-if="image.url"
                        class="cached image"
                        :src="image.url"
                    ></el-image>
                </template>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import { Carousel } from 'element-ui'
import { CarouselItem } from 'element-ui'
import { Tooltip } from 'element-ui'
import { Image } from 'element-ui'
export default {
    components: {
        [Carousel.name]: Carousel,
        [CarouselItem.name]: CarouselItem,
        [Tooltip.name]: Tooltip,
        [Image.name]: Image,
    },
    props: {
        aspectRatio: {
            type: Boolean,
            description: 'Display carousel image slide using aspect ratio.',
        },
        files: {
            type: Array,
            required: true,
        },
        height: {
            type: String,
            default: `400px`,
        },
        imageAsBackground: Boolean,
    },
    data() {
        return {}
    },
}
</script>
<style lang="scss">
.carousel {
    .list-inline {
        margin: 20px;
    }
    .el-carousel {
        &__item {
            .image {
                &.as-background {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 16 / 9;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.el-image {
                    height: 100%;
                    display: block;

                    img {
                        aspect-ratio: 16 / 9;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
        }

        &__arrow {
            background-color: rgba(0, 0, 0, 0.25);
            border: 2px solid #fff;
        }

        &.aspect-ratio {
            .el-carousel {
                &__container {
                    padding-bottom: 56.25%;
                }
            }
        }

        @media screen and (max-width: 991.98px) {
            &__container {
                height: 100%;
                padding-bottom: 56.25%;
            }
        }
    }
}
</style>
