import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['auth'])
    },
    methods: {
        propertyCurrency(property) {
            if ('USD' != property.compRateCurrency) return property.compRateCurrency

            return '$'
        },
        propertyAddress(property) {
            let streetAddress = property.streetAddress
            //The following logic was present but caused silent JS template error within List
            // if logic is not matching a new solution needs to be found
            //  !this.user.activeProduct.length > 0 ||
            if (!this.auth) {
                streetAddress = property.city

                if (property.zipcode)
                    streetAddress += streetAddress
                        ? `, ${property.zipcode}`
                        : property.zipcode

                return streetAddress
            }

            if (property.unitNumber) streetAddress += `, Suite ${property.unitNumber}`

            return streetAddress
        },
        propertyLocation(property) {
            let location = ''

            if (property.city) location += property.city

            if (property.state)
                location = location ? `${location}, ${property.state}` : location

            if (property.zipcode)
                location = location ? `${location} ${property.zipcode}` : location

            if (property.country)
                location = location ? `${location}, ${property.country}` : location

            return location
        },
        imageOrPlaceholder(property) {
            return property.images[0] && property.images[0].image
                ? `http://${property.images[0].image}`
                : require('@assets/images/image-placeholder.jpeg')
        }
    }
}
