<template>
    <div class="basic-filter">
        <b-container class="px-2 px-xl-3">
            <b-row class="mx-n2 mx-xl-n3">
                <b-col
                    v-if="user && user.isSuperuser"
                    cols="12"
                    sm="4"
                    xl="1"
                    class="px-2 px-xl-3 mb-3 mb-sm-0"
                >
                    <t-checkbox
                        class="p-2"
                        :checked="isFlagged"
                        @input="value => onInput('flagged', value)"
                    >
                        Flagged</t-checkbox
                    >
                </b-col>
                <b-col
                    cols="12"
                    sm="4"
                    xl="3"
                    class="px-2 mt-3 mt-xl-0 px-xl-3 mb-3 mb-sm-0"
                >
                    <gmap-autocomplete
                        class="search"
                        placeholder="Search by Location"
                        :value="search"
                        :selectFirstOnEnter="true"
                        @place_changed="setPlace"
                    >
                    </gmap-autocomplete>
                </b-col>

                <b-col
                    cols="12"
                    sm="7"
                    xl="4"
                    class="px-2 mt-3 mt-xl-0 px-xl-3 mb-3 mb-sm-0"
                >
                    <v-select
                        v-model="selectedPropertyType"
                        @input="option => onInput('type', !option ? '' : option.value)"
                        placeholder="Space Type"
                        :options="typeOptions"
                    ></v-select>
                </b-col>

                <b-col cols="12" sm="4" xl="2" class="px-2 mt-3 mt-xl-0 px-xl-3">
                    <v-select
                        v-model="selectedOfficeType"
                        placeholder="Shared Space"
                        @input="value => onInput('shareScopeType', value)"
                        :options="['Whole', 'Portion']"
                    ></v-select>
                </b-col>

                <b-col
                    cols="12"
                    sm="4"
                    xl="1"
                    class="px-2 px-xl-3 mt-3 mt-xl-0 d-flex justify-content-between"
                >
                    <b-button
                        variant="secondary"
                        size="sm"
                        class="w-100 text-capitalize"
                        @click="$bvModal.show('filterModal')"
                        >Filters</b-button
                    >
                </b-col>

                <b-col
                    cols="1"
                    sm="2"
                    xl="1"
                    class="px-2 px-xl-3 mt-3 mt-xl-0 d-flex justify-content-between"
                >
                    <b-button
                        @click="clearFilters"
                        variant="secondary"
                        size="sm"
                        class="text-capitalize"
                        >Clear</b-button
                    >
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlaceMixin from '@mixins/PlaceMixin'

export default {
    name: 'ListFiltersBasic',
    data() {
        return {
            isFlagged: false,
            containsFilter: true,
            selectedOfficeType: null,
            selectedPropertyType: null,
            search: null
        }
    },
    mixins: [PlaceMixin],
    computed: {
        ...mapGetters([
            'filters',
            'searchQuery',
            'user',
            'fieldTypes',
            'typeOptions',
            'typeOption'
        ]),
        officeTypeOptions() {
            return this.fieldTypes.map(item => item.name)
        }
    },
    methods: {
        onInput(key, value) {
            this.$emit('add-filter', {
                key: key,
                value: value
            })
        },
        clearFilters() {
            this.isFlagged = false
            this.selectedOfficeType = null
            this.selectedPropertyType = null
            this.search = null

            this.$emit('clear-filters')
        },
        setFilters() {
            const typeExist = this.filters.find(s => s.name == 'type')
            this.selectedPropertyType = typeExist
                ? this.typeOption(typeExist.value)
                : null

            const spaceExist = this.filters.find(s => s.name == 'shareScopeType')
            this.selectedOfficeType = spaceExist ? spaceExist.value : null
        }
    },
    beforeMount() {
        if (0 < this.filters.length || this.searchQuery) {
            if (this.searchQuery) this.search = this.searchQuery

            this.setFilters()
        }
    },
    watch: {
        filters() {
            if (!this.filters.length) {
                this.search = null
                this.selectedOfficeType = null
                this.selectedPropertyType = null
            }
        }
    }
}
</script>
