<template>
    <b-container class="listings pb-5">
        <h1 class="page-title mb-3 text-left">
            My Searches
        </h1>
        <loading-overlay
            class="loading-list"
            v-if="!loaded"
            :state="!loaded"
        ></loading-overlay>
        <b-row cols="1" cols-md="2" cols-lg="3" v-if="0 < list.length && loaded">
            <b-col v-for="(savedSearch, idx) in list" :key="idx" class="my-3">
                <b-card
                    :title="savedSearch.name"
                    tag="article"
                    body-class="p-0"
                    class="h-100 border-0 text-center position-relative"
                >
                    <b-link
                        @click.prevent.stop="
                            $bvModal.show(`delete-search-modal-${idx}`)
                        "
                        class="m-3 position-absolute top-0 end-0"
                    >
                        <b-icon
                            icon="x"
                            font-scale="1.5"
                            class="text-white bg-danger rounded-circle"
                        ></b-icon>
                    </b-link>

                    <b-card-text class="px-3 pb-5 mb-5">
                        <ul class="card-list card-list--md mx-auto text-start">
                            <template
                                v-for="(searchParameter, idx) in savedSearch.search"
                            >
                                <li
                                    v-if="searchParameter.name !== 'coords'"
                                    :key="idx"
                                    :class="{
                                        'd-flex align-items-center justify-content-between':
                                            searchParameter.name !== 'address'
                                    }"
                                    class="mb-2"
                                >
                                    <strong
                                        class="card-list__title"
                                        :class="{
                                            'mb-1': searchParameter.name === 'address'
                                        }"
                                        >{{
                                            formatCamelCaseLabel(searchParameter.label)
                                        }}</strong
                                    >
                                    <span class="ps-2 text-muted d-block">
                                        <!-- Boolean -->
                                        <template
                                            v-if="isBoolean(searchParameter.value)"
                                        >
                                            {{ booleanCheck(searchParameter.value) }}
                                        </template>
                                        <!-- Array of strings -->
                                        <template
                                            v-if="
                                                Array.isArray(searchParameter.value) &&
                                                    typeof searchParameter.value[0] ==
                                                        'string'
                                            "
                                        >
                                            <span
                                                v-for="(value,
                                                idx) in searchParameter.value"
                                                :key="idx"
                                            >
                                                {{ value }}
                                            </span>
                                        </template>
                                        <!-- Range integer -->
                                        <template
                                            v-if="
                                                typeof searchParameter.value[0] ==
                                                    'number'
                                            "
                                        >
                                            {{ valueBuild(searchParameter.value[1]) }}
                                        </template>
                                        <!-- Integer -->
                                        <template
                                            v-if="
                                                typeof searchParameter.value == 'number'
                                            "
                                        >
                                            {{ searchParameter.value }}
                                        </template>
                                        <!-- String -->
                                        <template
                                            v-if="
                                                !Array.isArray(searchParameter.value) &&
                                                    typeof searchParameter.value[0] ==
                                                        'string'
                                            "
                                        >
                                            <template
                                                v-if="searchParameter.name === 'type'"
                                            >
                                                {{
                                                    typeDisplayName(
                                                        searchParameter.value
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                {{ searchParameter.value }}
                                            </template>
                                        </template>
                                    </span>
                                </li>
                            </template>
                        </ul>
                    </b-card-text>

                    <b-card-footer
                        class="w-100 py-3 border-gray-200 position-absolute bottom-0 has-border"
                    >
                        <b-button
                            variant="secondary"
                            size="sm"
                            class="w-100 text-capitalize"
                            @click="goToListSearch(savedSearch.search)"
                            >Use search</b-button
                        >
                    </b-card-footer>
                </b-card>

                <b-modal
                    size="md"
                    class="centered"
                    :id="`delete-search-modal-${idx}`"
                    return-focus="body"
                    @ok="removeSearch(savedSearch.id)"
                    ok-title="Delete"
                >
                    <template #modal-title>Delete Search</template>
                    <div>
                        Are you sure you want to delete this search?<br />
                        This action cannot be undone.
                    </div>
                </b-modal>
            </b-col>
        </b-row>
        <no-data-placeholder v-if="!list.length && !!loaded" class="placeholder-search">
            <template #sub>You do not have any searches saved</template>
        </no-data-placeholder>
    </b-container>
</template>
<script>
import PropertyService from '@serv/PropertyService'
import { mapGetters } from 'vuex'
import ResourceService from '@serv/ResourceService'
import Redirect from '@serv/Redirect'
import NoDataPlaceholder from '@comp/NoDataPlaceholder'
import LoadingOverlay from '@comp/LoadingOverlay'
import NotifyService from '@serv/NotifyService'

export default {
    name: 'Searches',
    metaInfo: {
        title: 'Searches'
    },
    components: {
        NoDataPlaceholder,
        LoadingOverlay
    },
    data() {
        return {
            list: [],
            loaded: false
        }
    },
    props: {},
    computed: {
        ...mapGetters(['user', 'auth', 'filters', 'typeDisplayName'])
    },
    methods: {
        valueBuild(value) {
            return `<= ${value}`
        },
        booleanCheck(value) {
            return !!value ? 'Yes' : 'No'
        },
        isBoolean(value) {
            return _.isBoolean(value)
        },
        getList() {
            PropertyService.getSavedSearch(this.user.id).then(list => {
                this.list = list.data.map(item => {
                    item.search = item.query.split('&').map(qitem => {
                        const split = qitem.split('=')
                        const name = decodeURIComponent(split[0])
                        let value = decodeURIComponent(split[1])
                        return {
                            name: name,
                            label: name.replaceAll('_', ' '),
                            value: 0 <= value.indexOf(',') ? value.split(',') : value
                        }
                    })

                    return item
                })
                this.loaded = true
            })
        },
        removeSearch(searchId) {
            PropertyService.deleteSavedSearch(searchId).then(() => {
                NotifyService.notify(
                    this,
                    'Saved search deleted successfully',
                    'success'
                )

                this.list.splice(
                    this.list.findIndex(i => {
                        return i.id === searchId
                    }),
                    1
                )
            })
        },
        goToListSearch(filter) {
            const addressIdx = filter.findIndex(i => {
                if (i.name === 'address') {
                    this.$store.commit('setSearchQuery', i.value)
                    return i.name === 'address'
                }
            })

            if (0 <= addressIdx) filter.splice(addressIdx, 1)

            ResourceService.multiFilterPost(filter)
            Redirect.gotoName('Listing', null, { search: 'active' })
        },
        formatCamelCaseLabel(string) {
            return _.upperFirst(string)
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/lte|gte/gi, '')
        }
    },
    beforeMount() {
        if (this.user) {
            this.getList()
        }
    },
    watch: {
        user() {
            this.getList()
        }
    }
}
</script>
//
<style lang="scss" scoped>
.placeholder-search {
    margin-bottom: 3.1rem;
}
</style>
