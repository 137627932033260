<template>
    <div>
        <upload
            class="upload-resume"
            :auto-upload="false"
            :limit="1"
            :on-exceed="onExceed"
            :on-change="onChange"
            :show-file-list="false"
            action=""
            accept="application/msword,application/pdf"
            ref="resume-uploader"
            drag
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                Drop file here or <em>Click to upload your resume</em>
            </div>
            <div slot="tip" class="el-upload__tip">
                Accepted file types: doc, pdf
            </div>
        </upload>
    </div>
</template>

<script>
import { Upload, MessageBox } from 'element-ui'
import NotifyService from '@serv/NotifyService'

export default {
    props: ['value'],
    components: {
        Upload
    },
    data() {
        return {
            innerValue: undefined
        }
    },
    computed: {
        getComponentData() {
            return {
                attrs: {
                    wrap: true
                }
            }
        }
    },
    methods: {
        onChange(file) {
            if (
                0 > file.raw.type.indexOf('application/msword') &&
                0 > file.raw.type.indexOf('application/pdf')
            ) {
                NotifyService.notify(this, 'Invalid file type', 'danger')
                this.handleRemove(file, this.innerValue)
            } else {
                this.innerValue = file.raw
                this.$emit('input', this.innerValue)
            }
        },
        onExceed() {
            MessageBox.detail.warning(`The limit is 1 file.`)
        },
        handleRemove(file) {
            MessageBox.confirm(`Cancel the upload of this file? ${file.name}`).then(
                rs => {
                    if ('confirm' !== rs) return
                    ;(this.innerValue = undefined), this.$emit('input', this.innerValue)
                }
            )
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                if (!newVal) return

                this.innerValue = newVal
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.image {
    &__list {
        margin: 0 -10px;
    }

    &__item {
        width: calc(25% - 20px);
        height: 100px;
        margin: 10px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor: grab;

        &.sortable {
            &-ghost {
                background-image: none !important;
                border: 2px dashed #dee2e6 !important;
                cursor: grabbing;

                * {
                    opacity: 0;
                }
            }
        }
    }
}
</style>
