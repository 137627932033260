<template>
    <div class="homepage">
        <CouponBanner />

        <section
            id="banner"
            class="py-5 text-gray-600 ff-primary text-center"
            :style="{
                backgroundImage: `url(${require('@assets/images/waiting-room.png')})`
            }"
        >
            <b-container class="pt-lg-2 intro">
                <h1 class="fs-2 mb-3 text-primary fw-bold fst-italic">
                    Find Your Space, Find Your Match!
                </h1>
                <p>
                    A simplified method for bringing people, opportunities, and spaces
                    together. Enter where and what type of space you are looking for!
                </p>

                <div class="search mt-4 mx-auto">
                    <gmap-autocomplete
                        placeholder="Search by Location or Postal Code"
                        :selectFirstOnEnter="true"
                        @place_changed="setPlace"
                        class="search__location"
                    >
                    </gmap-autocomplete>
                    <div class="search__options">
                        <b-form-group clas="form-inline">
                            <h6 class="mx-auto mb-3 fw-bold d-block type-field">
                                Type of Spaces:
                            </h6>
                            <v-select
                                class="search__type"
                                placeholder="Select Type"
                                v-model="tempQuery"
                                :options="typeOptions"
                            ></v-select>
                        </b-form-group>
                    </div>

                    <b-button
                        @click="redirect('Listing')"
                        variant="secondary"
                        class="search__button w-100 mt-4 mt-lg-5 text-capitalize"
                    >
                        Search for a Space</b-button
                    >
                </div>
            </b-container>
        </section>

        <section>
            <subscription class="mt-3 mt-lg-4" v-if="!auth" />
        </section>
    </div>
</template>

<script>
import CouponBanner from '@comp/CouponBanner'
import Subscription from '@comp/Subscription'
import Redirect from '@serv/Redirect'
import PlaceMixin from '@mixins/PlaceMixin'
import { mapGetters } from 'vuex'

export default {
    name: 'app',
    metaInfo: {
        title: 'Home'
    },
    data() {
        return {
            tempQuery: undefined,
            type: undefined,
            search: undefined,
            containsFilter: true
        }
    },
    mixins: [PlaceMixin],
    components: { CouponBanner, Subscription },
    computed: {
        ...mapGetters(['auth', 'filters', 'typeOptions'])
    },
    methods: {
        redirect(location) {
            if (this.search) {
                this.$store.commit('setSearchQuery', this.search)
            } else {
                this.$store.commit('clearSearch')
            }
            if (this.type) {
                this.$store.commit('addListFilter', {
                    name: 'type',
                    value: this.type
                })
            } else {
                this.$store.commit('removeListFilter', 'type')
            }

            Redirect.gotoName(location)
        }
    },
    mounted() {
        this.$store.commit('clearFilters')
    },
    watch: {
        tempQuery() {
            this.type = !this.tempQuery ? '' : this.tempQuery.value
        }
    }
}
</script>
<style lang="scss">
.homepage {
    #banner {
        font-size: 18px;
        .intro {
            color: #35588f;
            p {
                color: #8898aa;
            }
        }
        .search {
            &__location {
                max-width: 420px;
            }

            &__input {
                height: 50px;
                border: 1px solid;
                border-radius: 30px;
                font-size: 1rem;
                box-shadow: none;
            }

            &__options {
                width: 100%;
                max-width: 800px;
                font-size: 1rem;
                margin: 24px 0;

                .custom-control {
                    margin: 5px 0;

                    &-label {
                        margin: 0;
                    }
                }
            }

            &__button {
                max-width: 280px;
                min-height: 50px;
                box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
                    0px 1px 3px rgba(0, 0, 0, 0.08);
            }
        }

        @media screen and (min-width: 768px) {
            font-size: 21px;
        }

        @media screen and (min-width: 992px) {
            font-size: 24px;

            .container {
                max-width: 820px;
            }

            .search {
                &__location {
                    max-width: 580px;
                }

                &__input {
                    height: 64px;
                    font-size: 1.125rem;
                }

                &__button {
                    max-width: 380px;
                    min-height: 60px;
                }
            }
        }
    }
    .pac-target-input {
        border-radius: 36px;
        border: 1px solid lightgray;
        padding: 12px;
        text-align: center;
        width: 100%;
    }
    .type-field {
        display: inline;
        margin-right: 24px;
    }
}
</style>
