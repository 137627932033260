const defaults = () => {
    return {
        tokens: {
            accessToken: localStorage.getItem('userTokens')
                ? JSON.parse(localStorage.getItem('userTokens')).accessToken
                : null,
            refreshToken: localStorage.getItem('userTokens')
                ? JSON.parse(localStorage.getItem('userTokens')).refreshToken
                : null
        }
    }
}

const state = {
    tokens: defaults().tokens
}

const getters = {
    tokens: state => {
        return state.tokens
    }
}

const actions = {}

const mutations = {
    setTokens(state, tokens = null) {
        state.tokens = tokens || defaults().tokens
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
