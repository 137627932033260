import Vue from 'vue'
import axios from '@serv/AxiosService'
import TokenService from '@serv/TokenService'
import ErrorService from '@serv/ErrorService'

var retryTokenRefresh = false

const setup = store => {
    axios.interceptors.request.use(
        config => {
            Vue.prototype.$bus.$emit('api-request', true)

            const accessToken = store.getters.tokens.accessToken

            ErrorService.clear()

            if (accessToken) {
                config.headers['Authorization'] = `JWT ${accessToken}`
                config.headers['Accept'] = 'application/json'
            }

            return config
        },
        error => {
            Vue.prototype.$bus.$emit('api-request', false)

            return Promise.reject(error)
        }
    )

    axios.interceptors.response.use(
        res => {
            retryTokenRefresh = false

            Vue.prototype.$bus.$emit('api-request', false)

            return res
        },
        async err => {
            Vue.prototype.$bus.$emit('api-request', false)

            const errorData = err.response.data
            const tokenInvalid = errorData && 'token_not_valid' === errorData.code

            if (tokenInvalid && !retryTokenRefresh) {
                retryTokenRefresh = true

                await TokenService.refreshToken(store.getters.tokens.refreshToken)

                return axios(err.config)
            } else {
                return Promise.reject(err)
            }
        }
    )
}

export default setup
