<template>
    <loading-overlay v-if="!loaded" :state="!loaded"></loading-overlay>
    <b-container v-else class="pb-5">
        <h1 class="page-title mb-3 text-left">
            My Users
        </h1>
        <el-input
            placeholder="Search by name"
            type="text"
            v-model="search"
            @input="searchFilter()"
        />
        <el-table
            class="pt-4"
            :data="filteredData"
            :default-sort="{ prop: 'firstName' }"
            stripe
        >
            <el-table-column prop="firstName" label="First Name" width="180" sortable />
            <el-table-column prop="lastName" label="Last Name" width="180" sortable />
            <el-table-column prop="email" label="Email" width="270" />
            <el-table-column prop="dateJoined" label="Date joined" width="270" sortable>
                <template slot-scope="scope">
                    <span class="date">{{ formatTime(scope.row.dateJoined) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="lastPurchase" label="Subscriptions" width="140">
                <template slot-scope="scope">
                    <a
                        v-if="scope.row.lastPurchase"
                        href="javascript:;"
                        @click="showSubscription(scope.row.id)"
                        class="last-purchase"
                        >{{ scope.row.lastPurchase }}</a
                    >
                    <span v-else class="last-purchase">
                        N/A
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="listingsCount" label="Listings" width="130">
                <template slot-scope="scope">
                    <a
                        href="javascript:;"
                        @click="showListings(scope.row.id)"
                        class="listings-count"
                    >
                        {{ scope.row.listingsCount }}
                        {{ scope.row.listingsCount === 1 ? 'Listing' : 'Listings' }}</a
                    >
                </template>
            </el-table-column>
            <el-table-column prop="isActive" label="Active" width="80">
                <template slot-scope="scope">
                    <el-switch
                        active-color="#551a8b"
                        @change="changeUserStatus(scope.row)"
                        v-model="scope.row.isActive"
                    >
                        {{ scope.row.isActive }}
                    </el-switch>
                </template>
            </el-table-column>
        </el-table>
        <b-modal
            size="xl"
            class="centered"
            return-focus="body"
            :id="`user-subscriptions-modal`"
            :hide-footer="true"
        >
            <template #modal-title>Subscriptions</template>
            <el-table
                class="pt-4"
                :data="userSubscriptions"
                :default-sort="{ prop: 'type' }"
                stripe
            >
                <el-table-column prop="type" label="Type" width="180" />
                <el-table-column
                    prop="createdOn"
                    label="Created on"
                    width="250"
                    sortable
                >
                    <template slot-scope="scope">
                        <span class="date">{{ formatTime(scope.row.createdOn) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="expiresOn" label="Expires on" width="250">
                    <template slot-scope="scope">
                        <span class="date">{{ formatTime(scope.row.expiresOn) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remainingDays"
                    label="Remaining Days"
                    width="200"
                    sortable
                />
                <el-table-column prop="price" label="Price" width="120">
                    <template slot-scope="scope">
                        <span class="price">${{ scope.row.price }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <div class="text-center mt-4" v-if="userTotalSubscriptionsCount > 5">
                <el-pagination
                    small
                    :page-size="pageSize"
                    :pager-count="5"
                    layout="prev, pager, next"
                    @current-change="onCurrentPageChange"
                    :total="userTotalSubscriptionsCount"
                >
                </el-pagination>
            </div>
        </b-modal>
        <b-modal
            size="lg"
            class="centered"
            return-focus="body"
            :id="`user-listings-modal`"
            :hide-footer="true"
        >
            <template #modal-title>Listings</template>
            <div v-if="userListings.length == 0" property-list-container>
                <b-row class="listing-row">
                    <b-col cols="12">
                        No listings to show.
                    </b-col>
                </b-row>
            </div>
            <div
                v-else
                class="property-list-container"
                v-for="(property, idx) in userListings"
                :key="idx"
            >
                <b-row class="listing-row">
                    <b-col cols="4" lg="3">
                        <b-card-img
                            v-if="property.images && property.images.length"
                            :src="'http://' + property.images[0].image"
                            class="rounded listing-image"
                        />
                        <b-card-img
                            v-else
                            :src="require('@assets/images/image-placeholder.jpeg')"
                            class="rounded"
                        />
                    </b-col>
                    <b-col cols="7" lg="8">
                        <div>
                            <h4 class="text-gray-800 mb-0 fw-bold">
                                {{ propertyAddress(property) }}
                            </h4>
                            <span class="text-gray-600">
                                {{ propertyLocation(property) }}
                            </span>
                        </div>
                    </b-col>
                    <b-col cols="1" class="listing-actions">
                        <a
                            href="javascript:;"
                            class="mb-1"
                            title="View Listing"
                            @click="gotoProperty(property.id)"
                        >
                            <b-icon icon="eye" variant="primary"></b-icon>
                        </a>
                        <a
                            href="javascript:;"
                            class="mb-1"
                            @click="callAction('Edit', property, idx)"
                            title="Edit Listing"
                        >
                            <b-icon icon="pencil-square" variant="primary"></b-icon>
                        </a>
                        <a
                            href="javascript:;"
                            class="mb-1"
                            @click="callAction('Delete', property, idx)"
                            title="Delete Listing"
                        >
                            <b-icon icon="trash" variant="primary"></b-icon>
                        </a>
                    </b-col>
                </b-row>
            </div>
            <div class="text-center mt-4" v-if="userTotalListingsCount > 5">
                <el-pagination
                    small
                    :page-size="pageSize"
                    :pager-count="5"
                    layout="prev, pager, next"
                    @current-change="onCurrentPageChange"
                    :total="userTotalListingsCount"
                >
                </el-pagination>
            </div>
        </b-modal>
        <b-modal
            size="md"
            class="centered"
            id="delete-modal"
            return-focus="body"
            @ok="deleteAction()"
            ok-title="Delete"
        >
            <template #modal-title>Delete Listing?</template>
            <div>
                Are you sure you want to delete this listing?<br />
                This action cannot be undone.
            </div>
        </b-modal>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { Table, TableColumn, Switch, Input, Pagination } from 'element-ui'
import moment from 'moment'
import UserService from '@serv/UserService'
import LoadingOverlay from '@comp/LoadingOverlay'
import NotifyService from '@serv/NotifyService'
import PropertyMixin from '@mixins/PropertyMixin'
import PropertyService from '@serv/PropertyService'
export default {
    name: 'List',
    metaInfo: {
        title: 'Users list'
    },
    components: {
        LoadingOverlay,
        [Input.name]: Input,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Switch.name]: Switch,
        [Pagination.name]: Pagination
    },
    data() {
        return {
            loaded: false,
            filteredData: [],
            currentUserId: undefined,
            currentPage: 1,
            pageSize: 5,
            search: '',
            modalObject: undefined
        }
    },
    mixins: [PropertyMixin],
    props: {},
    computed: {
        ...mapGetters(['user', 'plans']),
        userTotalSubscriptionsCount() {
            if (!this.currentUserId) return 0
            var data = this.filteredData
            var userData = data.find(({ id }) => id === this.currentUserId)
            if (!userData || !userData.purchases.length) {
                return 0
            }
            return userData.purchases.length
        },
        userTotalListingsCount() {
            if (!this.currentUserId) return 0
            var data = this.filteredData
            var userData = data.find(({ id }) => id === this.currentUserId)
            if (!userData || !userData.spaces.length) {
                return 0
            }
            return userData.spaces.length
        },
        userSubscriptions() {
            if (!this.currentUserId) {
                return []
            }
            var data = this.filteredData
            var userData = data.find(({ id }) => id === this.currentUserId)
            if (!userData || !userData.purchases.length) {
                return []
            }
            var purchases = this.getPage(this.currentPage, userData.purchases)
            purchases = purchases.map(product => {
                var expiresOn = new Date(product.expiresOn)
                const remainingDays = this.calculateDateDifference(expiresOn)

                product.expiresOn = expiresOn
                product.createdOn = new Date(product.createdOn)
                product.remainingDays =
                    remainingDays >= 0 ? 'Expired' : Math.abs(remainingDays)

                product.price = `${this.plans.find(s => s.name == product.type).price
                    .unitAmount / 100} `
                return product
            })

            return purchases
        },
        userListings() {
            if (!this.currentUserId) {
                return []
            }
            var data = this.filteredData
            var userData = data.find(({ id }) => id === this.currentUserId)
            if (!userData || !userData.spaces.length) {
                return []
            }
            var page = this.getPage(this.currentPage, userData.spaces)
            //When the last item from the current page is deleted,
            //go to the previous page.
            if (page.length === 0 && this.currentPage > 1) {
                page = this.getPage(this.currentPage - 1, userData.spaces)
            }
            var spaces = page
            return spaces
        }
    },
    methods: {
        debounce: _.debounce,
        formatTime(date) {
            return moment(date).format('dddd, MMMM Do YYYY')
        },
        changeUserStatus(row) {
            UserService.changeStatus(row).then(() => {
                NotifyService.notify(this, 'User status updated!', 'success')
            })
        },
        searchFilter() {
            const test = this.tableData.filter(
                data =>
                    data.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
                    data.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
                    data.email.toLowerCase().includes(this.search.toLowerCase())
            )
            this.filteredData = test
        },
        showListings(userId) {
            this.currentUserId = userId
            this.currentPage = 1
            this.$bvModal.show('user-listings-modal')
        },
        showSubscription(userId) {
            this.currentUserId = userId
            this.currentPage = 1
            this.$bvModal.show('user-subscriptions-modal')
        },

        callAction(action, property) {
            this.modalObject = property

            if (action == 'Delete') {
                this.$bvModal.show('delete-modal')
            }
            if (action == 'Edit') {
                const editId = property.id
                this.$router.push({
                    name: 'Edit',
                    params: {
                        id: editId,
                        isListing: false,
                        adminRedirectToUsers: true
                    }
                })
            }
        },
        gotoProperty(id) {
            this.$router.push({
                name: 'Detail',
                params: { id: id, isListing: false, adminRedirectToUsers: true }
            })
        },
        deleteAction() {
            var listingId = this.modalObject.id
            var ownerId = this.modalObject.ownerId
            PropertyService.deleteProperty(listingId).then(() => {
                const index = this.tableData.findIndex(({ id }) => ownerId === id)
                var user = this.tableData[index]
                user.spaces = user.spaces.filter(({ id }) => listingId !== id)
                user.listingsCount = user.spaces.length
                this.tableData[index] = user
                this.filteredData = this.tableData.slice()
                NotifyService.notify(this, 'Listing removed successfully', 'success')
            })
        },
        sortPurchases(purchases) {
            var hasPurchases = purchases && purchases.length
            if (!hasPurchases) {
                return []
            }
            return purchases.sort(
                (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
            )
        },
        onCurrentPageChange(currentPage) {
            this.currentPage = currentPage
        },
        calculateDateDifference(dateEnd) {
            let today = moment()
            let expired = moment(dateEnd)
            return today.diff(expired, 'days')
        },
        getPage(page, data) {
            var offset = (page - 1) * this.pageSize
            var limit = page * this.pageSize
            var pageItems = data.slice(offset, limit)
            this.currentPage = page
            return pageItems
        }
    },
    beforeMount() {
        this.getUsersProcess = UserService.getUsers().then(res => {
            var tableData = res
            for (let row of tableData) {
                var hasPurchases = row.purchases && row.purchases.length
                var sortedPurchases = this.sortPurchases(row.purchases)
                var lastPurchase = hasPurchases ? sortedPurchases[0].type : ''
                var listingsCount = (row.spaces && row.spaces.length) || 0
                row.lastPurchase = lastPurchase
                row.listingsCount = listingsCount
                row.purchases = sortedPurchases
                row.purchasesCount = sortedPurchases.length
            }
            this.tableData = tableData
            this.filteredData = tableData
            this.loaded = true
        })
    },
    mounted() {
        this.getUsersProcess.finally(() => {
            this.loaded = true
        })
    },
    watch: {}
}
</script>

<style lang="scss">
#user-listings-modal .modal-body {
    height: max-content;
    max-height: max-content;
    padding: 24px;
}

.list-container {
    .basic-filter {
        padding: 30px 0 40px;

        .form-control,
        .vs__dropdown-toggle {
            height: 42px !important;
            border: 1px solid #e5e5e5;
        }

        .btn {
            max-width: 130px;
            height: 42px;
        }
    }

    .list {
        padding: 30px 0;

        .container {
            max-width: 1130px;
        }
    }

    @media screen and (min-width: 860px) {
        .list {
            padding: 45px 0 30px;
        }
    }

    @media screen and (min-width: 1200px) {
        .list {
            padding: 65px 0 45px;
        }
    }
}

.listing-actions {
    display: flex;
}
/*

@media  screen and (max-width: 767px) {
    .listing-actions a + a{
        margin-left: 4px;
    }
}
*/
.listing-actions {
    flex-flow: column;
    flex-wrap: nowrap;
    align-self: center;
}
.property-list-container:not(:last-child) {
    padding-bottom: 4px;
}
.property-list-container + .property-list-container {
    padding-top: 4px;
    border-top: 1px solid #ebeef5;
}

@media screen and (min-width: 768px) {
    .property-list-container:not(:last-child) {
        padding-bottom: 5px;
    }
    .property-list-container + .property-list-container {
        padding-top: 5px;
    }
    .listing-image {
        min-height: 90px;
    }
}
.listing-image {
    width: 100%;
    background-color: #e5e5e5;
}
#user-subscriptions-modal .modal-content .el-table__row .el-table__cell:first-child {
    font-weight: bold;
}
#user-subscriptions-modal .modal-content .el-table.pt-4 {
    padding-top: 0 !important;
}
</style>
