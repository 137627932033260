<template>
    <b-container class="listings px-2 px-xl-3 pb-5">
        <template v-if="!!auth">
            <h1 class="page-title mb-3 text-left">My Spaces</h1>
            <div class="mb-3 d-flex">
                <b-button
                    :variant="isPublished == true ? 'primary' : 'outline-primary'"
                    size="sm"
                    class="text-capitalize my-1 me-2"
                    @click="filterToggle(true)"
                    >Published</b-button
                >

                <b-button
                    :variant="isPublished == false ? 'primary' : 'outline-primary'"
                    size="sm"
                    class="text-capitalize my-1 me-2"
                    @click="filterToggle(false)"
                    >Draft</b-button
                >
                <b-button
                    :variant="isPublished == null ? 'primary' : 'outline-primary'"
                    size="sm"
                    class="text-capitalize my-1 me-2"
                    @click="filterToggle(null)"
                    >All</b-button
                >
                <b-button
                    variant="secondary"
                    size="sm"
                    class="text-capitalize my-1 ms-auto"
                    @click="redirect('Create')"
                    >Add Property</b-button
                >
            </div>
            <loading-overlay v-if="!loaded" :state="!loaded"></loading-overlay>
            <b-container v-if="0 < filteredList.length">
                <b-row
                    class="list-row align-items-center"
                    v-for="(property, idx) in filteredList"
                    :key="idx"
                    :class="{ draft: isDraft(property) }"
                >
                    <b-col
                        cols="12"
                        sm="4"
                        xl="3"
                        class="px-2 px-xl-3 mb-3 mb-sm-0"
                        @click="openPreview(property, idx)"
                    >
                        <img
                            :src="imageOrPlaceholder(property)"
                            class="img-fluid internal"
                        />
                    </b-col>
                    <b-col cols="12" sm="6" xl="4" class="px-2 px-xl-3 mb-3 mb-sm-0">
                        <div class="h4 text-gray-800 mb-0 fw-bold">
                            {{ propertyAddress(property) }}
                        </div>

                        <p class="mt-2 property-info__address text-gray-300 lh-1">
                            {{ propertyLocation(property) }}
                        </p>
                    </b-col>
                    <b-col cols="12" sm="2" xl="2" class="px-2 px-xl-3 mb-3 mb-sm-0">
                    </b-col>
                    <b-col cols="12" sm="4" xl="3" class="px-2 px-xl-3 mb-3 mb-sm-0">
                        <v-select
                            :filterable="false"
                            :clearable="false"
                            :searchable="false"
                            placeholder="Actions"
                            :options="actionOptions(property)"
                            @option:selecting="
                                option => callAction(option, property, idx)
                            "
                        ></v-select>
                    </b-col>
                    <img
                        class="draft-tick"
                        v-if="isDraft(property)"
                        :src="require('@assets/images/draft.png')"
                    />
                    <b-modal
                        size="lg"
                        dialog-class=" big-modal"
                        class="centered"
                        return-focus="body"
                        :id="`preview-modal-${idx}`"
                        :hide-footer="true"
                    >
                        <template #modal-title>PREVIEW </template>
                        <detail
                            :idx="idx"
                            :form="property"
                            :view="true"
                            :images="property.images"
                        />
                    </b-modal>
                    <publish-modal
                        :id="idx"
                        :scope="currentScope"
                        :user="user"
                        :property="modalObject"
                    />
                    <runout-modal :id="idx" :scope="currentScope" />
                </b-row>
            </b-container>
            <no-data-placeholder
                v-if="
                    (!filteredList.length &&
                        loaded &&
                        isPublished == null &&
                        noOtherListings) ||
                        (!filteredList.length &&
                            loaded &&
                            isPublished == false &&
                            noOtherListings)
                "
                class="my-5"
            >
                <template #sub
                    >Create your first listing now. <br />Just click on
                    <div
                        class="add-property-cta d-inline-block"
                        @click="redirect('Create')"
                    >
                        "Add Property"
                    </div>
                </template>
            </no-data-placeholder>
            <no-data-placeholder
                v-if="
                    !filteredList.length &&
                        loaded &&
                        isPublished == false &&
                        !noOtherListings
                "
                class="my-5"
            >
                <template #sub>You have published all your listings.</template>
            </no-data-placeholder>
            <no-data-placeholder
                v-if="!filteredList.length && loaded && isPublished"
                class="my-5"
            >
                <template #sub>Check your drafts or create your first listing</template>
            </no-data-placeholder>
        </template>
        <b-modal
            size="md"
            class="centered"
            id="delete-modal"
            return-focus="body"
            @ok="deleteAction()"
            ok-title="Delete"
        >
            <template #modal-title>Delete Listing?</template>
            <div>
                Are you sure you want to delete this listing?<br />
                This action cannot be undone.
            </div>
        </b-modal>
        <b-modal
            size="md"
            class="centered"
            id="unpublish-modal"
            return-focus="body"
            @ok="publishProperty(false, modalObject)"
            ok-title="Unpublish"
        >
            <template #modal-title>Unpublish Listing?</template>
            <div>
                This will remove the listing from active status; it will no longer be
                seen by the public. You may re-publish this listing at any-time.
            </div>
        </b-modal>
    </b-container>
</template>
<script>
import Redirect from '@serv/Redirect'
import { mapGetters } from 'vuex'
import PropertyService from '@serv/PropertyService'
import Detail from '@comp/Detail'
import LoadingOverlay from '@comp/LoadingOverlay'
import NoDataPlaceholder from '@comp/NoDataPlaceholder'
import Vue from 'vue'
import moment from 'moment'
import NotifyService from '@serv/NotifyService'
import RunoutModal from '@comp/modal/RunoutModal'
import PublishModal from '@comp/modal/PublishModal'
import FormMixin from '@mixins/FormMixin'
import PropertyMixin from '@mixins/PropertyMixin'

export default {
    name: 'Listings',
    metaInfo: {
        title: 'My Spaces'
    },
    mixins: [FormMixin, PropertyMixin],
    computed: {
        ...mapGetters(['user', 'auth']),
        currentScope() {
            return this
        }
    },
    components: {
        RunoutModal,
        PublishModal,
        Detail,
        LoadingOverlay,
        NoDataPlaceholder
    },
    data() {
        return {
            list: [],
            filteredList: [],
            modalObject: {
                id: null
            },
            loaded: false,
            isPublished: undefined,
            noOtherListings: true
        }
    },
    methods: {
        formatTime(date) {
            return moment(date).format('dddd, MMMM Do YYYY')
        },
        filterList() {
            if (this.isPublished === false) {
                this.filteredList = this.list.filter(
                    property => property.status == 'Unpublished'
                )
            }
            if (this.isPublished === true) {
                this.filteredList = this.list.filter(
                    property => property.status == 'Published'
                )
            }
            if (this.isPublished === null) {
                this.filteredList = this.list
            }
            this.noOtherListings = this.list.length < 1
        },
        filterToggle(bool) {
            this.isPublished = bool
            this.filterList()
        },
        actionOptions(property) {
            let array = [{ label: 'Delete' }, { label: 'Edit' }, { label: 'Unpublish' }]
            let arrayDraft = [
                { label: 'Delete' },
                { label: 'Edit' },
                { label: 'Publish' }
            ]
            return property.status == 'Published' ? array : arrayDraft
        },
        isDraft(property) {
            return property.status == 'Unpublished'
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        getList() {
            const list = this.user.spaces
            list.forEach(listing => {
                listing.label = ''
            })
            this.list = list
            this.filteredList = this.list
            this.loaded = true
        },
        openPreview(property, idx) {
            this.$bvModal.show(`preview-modal-${idx}`)
            this.modalObject = property
            this.idx = idx
        },
        deleteAction() {
            this.$bvModal.hide(`preview-modal-${this.idx}`)
            PropertyService.deleteProperty(this.modalObject.id).then(() => {
                this.list.splice(
                    this.list.findIndex(i => {
                        return i.id === this.modalObject.id
                    }),
                    1
                )
                this.$store.commit('addListingAllowed', this.user)
            })
            NotifyService.notify(this, 'Listing removed successfully', 'success')
        },
        callAction(option, property, idx) {
            this.modalObject = property

            if (option.label == 'Delete') {
                this.$bvModal.show('delete-modal')
            }
            if (option.label == 'Edit') {
                const isListing = property.listingId ? true : false
                const editId = isListing ? property.listingId : property.id
                this.$router.push({
                    name: 'Edit',
                    params: { id: editId, isListing: isListing }
                })
            }

            if (option.label == 'Publish') {
                if (this.user.listingAllowed >= 1 || this.user.isSuperuser) {
                    this.$bvModal.show(`publish-modal-${idx}`)
                } else {
                    this.$bvModal.show(`runout-modal-${idx}`)
                }
            }
            if (option.label == 'Unpublish') {
                this.$bvModal.show('unpublish-modal')
            }
        },
        publishProperty(toPublish, property) {
            PropertyService.changeListingStatus(toPublish, property).then(res => {
                NotifyService.notify(this, 'Property updated successfully', 'success')

                const index = this.list.findIndex(x => x.id == property.id)
                Vue.set(this.list, index, res)
                if (toPublish) {
                    this.$store.commit('removeListingAllowed', this.user)
                } else {
                    this.$store.commit('addListingAllowed', this.user)
                }
                this.$bvModal.hide(`preview-modal-${this.idx}`)
            })
        }
    },
    beforeMount() {
        if (this.user && this.user.id) {
            this.getList()
        }
    },
    watch: {
        user() {
            this.getList()
        },
        list() {
            this.filterList()
        }
    }
}
</script>
<style lang="scss">
.listings {
    .list-row {
        padding: 20px;
        box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
        margin-bottom: 15px;
        position: relative;

        &.draft {
            border: 2px solid #551a8b;
        }
    }
    img.internal {
        cursor: pointer;
        width: 240px;
    }
}
.draft-tick {
    width: 78px;
    position: absolute;
    top: -12px;
    right: -15px;
}
.big-modal {
    min-width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    height: 100vh !important;
    display: contents !important;
    position: fixed !important;
    overflow-y: scroll !important;
    z-index: 100000 !important;
}
.add-property-cta {
    cursor: pointer;
}
</style>
