<template>
    <b-modal class="centered" id="flagModal" @ok.prevent="handleFlag">
        <template #modal-title>FLAG</template>
        <validation-observer ref="flagValidator">
            <t-input
                alternative
                class="mb-3 mb-lg-4"
                name="reason"
                label="Reason"
                type="text"
                :rules="{ required: true }"
                v-model="reason"
            />
            <b-form-group class="mb-3 mb-lg-4">
                <template #label>
                    Comment
                    <small class="text-danger">*</small>
                </template>

                <validation-provider
                    rules="required"
                    name="description"
                    v-bind="$attrs"
                    v-slot="{ errors, validated }"
                >
                    <b-textarea
                        class="text-area"
                        :class="{
                            'is-invalid': errors.length && validated
                        }"
                        v-model="comment"
                        rows="10"
                        cols="50"
                    ></b-textarea>

                    <div v-if="0 < errors.length" class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </validation-provider>
            </b-form-group>
        </validation-observer>
    </b-modal>
</template>

<script>
export default {
    name: 'FlagModal',
    props: {
        id: String
    },
    data() {
        return {
            reason: '',
            comment: ''
        }
    },
    methods: {
        handleFlag() {
            this.$emit('submit', { name: this.name, comment: this.comment })
        }
    },
    mounted() {}
}
</script>
