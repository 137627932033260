<template>
    <b-modal
        class="centered"
        :id="`publish-modal-${id}`"
        @ok="switchPropertyStatus(true, property)"
        ok-title="Publish"
        return-focus="body"
    >
        <template #modal-title>Publish Listing</template>
        <div v-if="!user.isSuperuser">
            Publishing this listing will use 1 of your allowed listings<br />
            You will have {{ user.listingAllowed - 1 }} remaining
        </div>
        <div v-else>
            As as Administrator you can post as many listings as you desire!
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'PublishModal',
    data() {
        return {}
    },
    props: {
        user: {
            type: Object
        },
        property: {
            type: Object
        },
        scope: {
            required: true
        },
        id: {
            default: 0
        }
    },
    methods: {
        switchPropertyStatus(bool, property) {
            this.scope.publishProperty(bool, property)
        }
    }
}
</script>
