<template>
    <div>
        <stripe-checkout
            ref="stripeIntegration"
            :pk="publishableKey"
            :session-id="userStripeSessionId"
            @loading="v => (loading = v)"
        />
    </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { mapGetters } from 'vuex'
export default {
    components: {
        StripeCheckout
    },
    data() {
        this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
        return {
            loading: false,
            userStripeSessionId: undefined
        }
    },
    computed: {
        ...mapGetters(['productType'])
    },
    methods: {
        submit() {
            this.$refs.stripeIntegration.redirectToCheckout()
        }
    },
    beforeMount() {
        this.$bus.$on('checkoutOpen', userStripeSessionId => {
            this.userStripeSessionId = userStripeSessionId
            this.submit()
        })
    }
}
</script>
