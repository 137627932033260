<template>
    <div class="detail-view py-4 py-lg-3">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <b-link v-if="!form" @click="redirect('JobListing')">
                        <b-icon icon="arrow-left" class="me-1"></b-icon> Back
                    </b-link>
                    <div v-if="form && !!view" class="d-flex justify-content-end">
                        <div>
                            <b-button
                                variant="outline-primary"
                                size="sm"
                                class="text-capitalize m-1"
                                @click="editAction(property, 'EditOpening')"
                                >Edit</b-button
                            >
                            <b-button
                                v-if="property.status === 'Published'"
                                variant="outline-primary"
                                size="sm"
                                class="text-capitalize m-1"
                                @click="callAction('Unpublish', property)"
                                >Unpublish</b-button
                            >
                            <b-button
                                v-else
                                variant="outline-primary"
                                size="sm"
                                class="text-capitalize m-1"
                                @click="callAction('Publish', property)"
                                >Publish</b-button
                            >
                            <b-button
                                variant="outline-primary"
                                size="sm"
                                class="text-capitalize m-1"
                                @click="callAction('Delete', property)"
                                >Delete</b-button
                            >
                        </div>
                    </div>
                    <loading-overlay
                        class="loading-list"
                        v-if="!loaded"
                        :state="!loaded"
                    ></loading-overlay>
                </b-col>
            </b-row>

            <b-row v-if="loaded" class="mt-3">
                <b-col cols="12" class="mb-4 mb-lg-0">
                    <job-detail-basic
                        :id="property.id"
                        :image="property.imageURL"
                        :title="property.title"
                        :companyName="property.companyName"
                        :preferedAreaId="property.preferedArea"
                        :compensation="property.compensation"
                        :country="property.country"
                        :description="property.description"
                        :contactFirstName="property.firstName"
                        :contactLastName="property.lastName"
                        :contactEmail="property.email"
                        :contactPhoneNumber="property.phoneNumber"
                    >
                        <!-- FLAG -->

                        <template v-slot:header-bottom>
                            <div
                                v-if="!form"
                                class="
                                    d-inline-b
                                    actions
                                    my-2
                                    position-absolute
                                    top-0
                                    end-0
                                    property-actions
                                "
                            >
                                <!--
                                <div
                                    v-if="hasFlag"
                                    @click="unflagProperty()"
                                    class="action"
                                >
                                    <b-icon icon="flag-fill" font-scale="1.2"></b-icon>
                                </div>
                                <div
                                    v-else
                                    class="action"
                                    @click="$bvModal.show('flagModal')"
                                >
                                    <b-icon icon="flag" font-scale="1.2"></b-icon>
                                </div>-->
                                <!-- /FLAG -->
                                <!-- FAVORITE -->
                                <!--<div
                                    v-if="isFavorite"
                                    class="action"
                                    @click="favoriteProperty(property, false)"
                                >
                                    <b-icon icon="heart-fill" font-scale="1.2"></b-icon>
                                </div>
                                <div
                                    v-else
                                    class="action"
                                    @click="favoriteProperty(property, true)"
                                >
                                    <b-icon icon="heart" font-scale="1.2"></b-icon>
                                </div>-->
                                <!-- /FAVORITE -->
                            </div>
                        </template>
                    </job-detail-basic>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
//import Schedule from '@comp/Schedule'
//import Redirect from '@serv/Redirect'
import JobService from '@serv/JobService'
import { mapGetters } from 'vuex'
//import NotifyService from '@serv/NotifyService'
import LoadingOverlay from '@comp/LoadingOverlay'
import DetailMixin from '@mixins/DetailMixin'
import JobDetailBasic from '@comp/detail/JobDetailBasic'

export default {
    name: 'JobDetail',
    metaInfo: {
        title: 'Job Detail'
    },
    components: {
        //Schedule,
        LoadingOverlay,
        JobDetailBasic
    },
    mixins: [DetailMixin],
    computed: {
        ...mapGetters(['user', 'auth']),
        ...mapGetters({
            fieldTypes: 'jobFieldTypes'
        })
    },
    methods: {
        flagListing(/*form, userId, listingId*/) {
            return Promise.resolve()
        },
        favoriteListing(/*listingId, isFavorite, user*/) {
            return Promise.resolve()
        },
        getProperty(id) {
            return JobService.getListing(id)
            //return Promise.resolve(this.listingById(id));
        },
        canAccessView() {
            return this.auth && this.user.checkActiveFindJobsProduct()
        }
    }
}
</script>

<style lang="scss">
@import './../../assets/sass/pages/_property-detail.scss';
</style>
