<template>
    <div
        v-if="state"
        class="loading-overlay w-100 h-100 bg-white d-flex align-items-center justify-content-center"
    >
        <img
            :src="require('@assets/images/ufo.png')"
            alt="UFO"
            class="loading-overlay__img"
        />
    </div>
</template>

<script>
export default {
    props: {
        state: {
            type: Boolean,
            description: 'Loading state indicator.',
            default: false
        }
    }
}
</script>
