<template>
    <b-row class="mb-3">
        <b-col cols="12" :lg="hasContactInfo ? 8 : 12">
            <b-row>
                <b-col cols="12" lg="4" class="mb-4 mb-lg-5">
                    <b-card-img
                        v-if="this.innerImage"
                        :src="this.innerImage"
                        class="rounded"
                    />
                    <div v-else class="placeholder-thumbnail"></div>
                </b-col>
                <b-col cols="12" lg="8" class="mb-4 mb-lg-5">
                    <div>
                        <h2 class="text-gray-800 mb-0 fw-bold">{{ title }}</h2>
                        <b class="text-gray-800">{{ companyName }}</b>
                        <b-card-text v-if="compensation" class="text-gray-800"
                            >Compensation: <b>{{ compensation }}</b>
                        </b-card-text>

                        <template v-if="area">
                            <br />
                            <span class="text-gray-600">
                                <span v-if="area.city">{{ area.city }}</span>
                                <span v-if="area.state">, {{ area.state }}</span>
                            </span>
                        </template>
                    </div>
                    <slot name="header-bottom"></slot>
                </b-col>
                <b-col cols="12" class="">
                    <h4 class="property-info__sub-title fw-bold">
                        Job Description
                    </h4>
                    <p class="mb-0 pre-formatted" v-if="description">
                        {{ description }}
                    </p>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12" lg="4" v-if="hasContactInfo" class="mt-lg-0 mt-4">
            <contact-info
                :displayName="[contactFirstName, contactLastName].join(' ')"
                :email="contactEmail"
                :phoneNumber="contactPhoneNumber"
            />
        </b-col>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import JobService from '@serv/JobService'
import ContactInfo from '@comp/detail/ContactInfo'

export default {
    name: 'JobDetailSimple',
    components: { ContactInfo },
    props: {
        id: Number,
        image: String,
        title: String,
        companyName: String,
        preferedAreaId: [Number, Object],
        compensation: String,
        country: String,
        description: String,
        contactFirstName: String,
        contactLastName: String,
        contactEmail: String,
        contactPhoneNumber: String
    },
    computed: {
        ...mapGetters(['preferedAreaById']),
        hasContactInfo() {
            return !!(this.contactEmail || this.contactPhoneNumber)
        }
    },
    data() {
        return {
            innerImage: undefined,
            area: undefined
        }
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt)
        }
    },
    beforeMount() {
        if (this.image) {
            this.innerImage = this.image
        } else if (this.id && !this.innerImage) {
            JobService.getJobOpening(this.id).then(job => {
                this.innerImage = job.images[0]
            })
        }
        if (!this.preferedAreaId) {
            return
        }
        if (typeof this.preferedAreaId === 'object') {
            this.area = this.preferedAreaId
        } else {
            this.area = this.preferedAreaById(this.preferedAreaId)
        }
    }
}
</script>
<style lang="scss">
.placeholder-thumbnail {
    display: block;
    width: 100%;
    height: 170px;
    border-radius: 4px;
    background-color: #e5e5e5;
}
</style>
