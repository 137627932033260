import store from '@store'

export default {
    anonymousRequired: (to, from, next) => {
        if (!store.state.user.auth) {
            next()
            return
        } else {
            if ('true' == to.query.post) {
                next({ name: 'Listings' })
            } else {
                next({ name: 'Home' })
            }
        }
    },
    authRequired: (to, from, next) => {
        if (store.state.user.auth) {
            next()
            return
        } else {
            next({ name: 'Home' })
        }
    },
    superUserRequired: (to, from, next) => {
        if (store.state.user.auth && store.state.user.user.type === 'Superuser') {
            next()
            return
        } else {
            next({ name: 'Home' })
        }
    }
}
