export default {
    props: {
        displayName: String,
        jobType: [String, Number, Object],
        speciality: [String, Number, Object],
        description: String,
        preferedArea: {
            type: Array,
            default() {
                return []
            }
        },
        profilePhoto: [Object, String]
    },
    computed: {
        specialityText() {
            if (!this.speciality) {
                return ''
            }
            if (typeof this.speciality === 'string') {
                return this.speciality
            }
            if (typeof this.speciality === 'object' && this.speciality.name) {
                return this.speciality.name
            }
            return ''
        },
        jobTypeText() {
            if (!this.jobType) {
                return ''
            }
            if (typeof this.jobType === 'string') {
                return this.jobType
            }
            if (typeof this.jobType === 'object' && this.jobType.name) {
                return this.jobType.name
            }
            if (typeof this.jobType === 'number') {
                const type = this.fieldTypeById(this.jobType)
                return type ? type.name : ''
            }
            return ''
        },
        profilePhotoURL() {
            if (typeof this.profilePhoto === 'object') {
                return (
                    (this.profilePhoto &&
                        (this.profilePhoto.url || this.profilePhoto.image)) ||
                    ''
                )
            }
            return this.profilePhoto
        }
    }
}
