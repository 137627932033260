import Job from '@models/Job'
import axios from '@serv/AxiosService'
import CategoryService from '@serv/CategoryService'
//import PreferedAreaService from '@serv/PreferedAreaService'
import { formDataOf } from '@util/helpers'

class JobService {
    getTypes() {
        return CategoryService.getCategories().then(categories => {
            return categories
        })
    }
    getJobImage(id) {
        return axios
            .get(`workforce/job-image/${id}/`)
            .then(res => {
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    deleteJobImage(id) {
        return axios.delete(`workforce/job-image/${id}/`).catch(error => {
            throw error
        })
    }
    getListings() {
        return axios.get('workforce/job-search/').then(res => {
            var data = res.data
            if (data.detail) {
                data = data.data
            }
            var listings = data
                .filter(listing => listing.status === 'Published')
                .map(listing => {
                    return new Job(listing)
                })
            return listings
        })
    }
    getListingsByQuery(filterQuery) {
        return axios
            .get('workforce/job-search/', { params: filterQuery })
            .then(res => {
                var data = res.data
                if (data.detail) {
                    data = data.data
                }
                var listings = data
                    .filter(listing => listing.status === 'Published')
                    .map(listing => {
                        return new Job(listing)
                    })
                return listings
            })
            .catch(error => {
                throw error
            })
    }
    getListing(id) {
        return axios
            .get('workforce/job-opening/' + id + '/')
            .then(res => {
                var data = res.data
                if (data.status !== 'Published') {
                    return
                }
                return new Job(data)
            })
            .catch(error => {
                throw error
            })
    }
    getMyJobOpenings() {
        return axios
            .get('workforce/job-opening/')
            .then(res => {
                var data = res.data
                return data.map(listing => {
                    return new Job(listing)
                })
            })
            .catch(error => {
                throw error
            })
    }
    getJobOpening(id) {
        return axios
            .get('workforce/job-opening/' + id + '/')
            .then(res => {
                var data = res.data
                return new Job(data)
            })
            .catch(error => {
                throw error
            })
    }
    saveJobOpening(data) {
        var method = data.id ? 'patch' : 'post'
        var url =
            method === 'patch'
                ? `workforce/job-opening/${data.id}/`
                : `workforce/job-opening/`

        var formData = formDataOf(data)

        return axios({
            method: method,
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                var data = res.data
                return new Job(data)
            })
            .catch(error => {
                throw error
            })
    }
    updateJobOpeningSimple(data) {
        return axios({
            method: 'patch',
            url: `workforce/job-opening/${data.id}/`,
            data: data
        })
            .then(res => {
                var data = res.data
                return new Job(data)
            })
            .catch(error => {
                throw error
            })
    }
    setJobImages({ id, images }) {
        var imageFiles = images.filter(image => {
            return image.raw && image.raw instanceof File
        })
        return Promise.all(
            imageFiles.map(image => {
                var formData = new FormData()
                formData.append('image', image.raw)
                formData.append('job', id)
                return axios({
                    method: 'post',
                    url: 'workforce/job-image/',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(res => {
                        return res.data
                    })
                    .catch(error => {
                        throw error
                    })
            })
        ).then(freshImages => {
            //Clean up removed images
            return this.getJobOpening(id).then(({ images: remoteImages }) => {
                var imagesToRemove = []
                if (!images.length) {
                    imagesToRemove = remoteImages
                } else {
                    var freshImagesIds = freshImages.map(({ id }) => id)
                    imagesToRemove = remoteImages.filter(
                        ({ id }) => !freshImagesIds.includes(id)
                    )
                    var imagesURLs = images
                        .filter(
                            image =>
                                image.url && !(image.raw && image.raw instanceof File)
                        )
                        .map(({ url }) => url)

                    imagesToRemove = imagesToRemove.filter(
                        ({ image }) => !imagesURLs.includes(image)
                    )
                }
                return Promise.all(
                    imagesToRemove.map(image => this.deleteJobImage(image.id))
                ).then(() => {
                    return freshImages
                })
            })
        })
    }
    deleteJobOpening(id) {
        return axios
            .delete(`workforce/job-opening/${id}/`)
            .then(() => {
                return
            })
            .catch(error => {
                throw error
            })
    }
    changeListingStatus(toPublish, job) {
        var data = {
            id: job.id
        }
        if (toPublish) {
            data.status = 'Published'
        } else {
            data.status = 'Unpublished'
        }
        return this.updateJobOpeningSimple(data)
    }
}

export default new JobService()
