<template>
    <div
        class="min-vh-100"
        :class="[
            'mm4b-page-' + $route.name.toLowerCase(),
            [!user ? '' : 'logged-in'],
            [user && user.isSuperuser ? 'admin' : '']
        ]"
    >
        <loading-overlay
            class="position-fixed top-0 left-0 loading-app"
            :state="!(loaded && listModelsLoaded && !lockAppLoadingOverlay)"
        ></loading-overlay>
        <notifications />
        <div
            v-if="loaded && listModelsLoaded"
            class="h-100 container-fluid px-0 application-wrapper"
        >
            <navigation ref="nav" class="w-100" />
            <div ref="main" class="main-content">
                <router-view :key="$route.path"></router-view>
            </div>
            <content-footer ref="footer" class="footer" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingOverlay from '@comp/LoadingOverlay'
import Navigation from '@comp/Navigation'
import ContentFooter from '@comp/ContentFooter'
import PropertyService from '@serv/PropertyService'
import PreferedAreaService from '@serv/PreferedAreaService'
import CategoryService from '@serv/CategoryService'

const loadModels = () => {
    const listModelsRequests = [
        PropertyService.getSpaceTypes(),
        CategoryService.getCategories(),
        PreferedAreaService.getPreferedArea(),
        PropertyService.getPlans()
    ]
    return Promise.all(listModelsRequests)
}

export default {
    name: 'App',
    metaInfo: {
        title: 'Main Page',
        titleTemplate: '%s | Matchmaker4Business'
    },
    components: {
        LoadingOverlay,
        Navigation,
        ContentFooter
    },
    data() {
        return {
            loaded: false,
            listModelsLoaded: false,
            lockAppLoadingOverlay: false
        }
    },
    computed: {
        ...mapGetters(['user', 'tokens'])
    },
    methods: {
        calculateContentHeight() {
            //this.$refs.main.style.paddingTop = `${this.$refs.nav.$el.clientHeight}px`
            if (this.$refs.main) {
                this.$refs.main.style.minHeight = `calc(100vh - ${this.$refs.footer.$el.clientHeight}px - ${this.$refs.nav.$el.clientHeight}px)`
            }
        },
        loadModels() {
            return loadModels().finally(() => {
                this.listModelsLoaded = true
            })
        }
    },
    watch: {
        loaded: {
            immediate: true,
            handler(newVal) {
                if (newVal) this.calculateContentHeight()
            }
        },
        user(newVal) {
            //After user logs out
            if (!newVal) {
                this.loadModels()
            }
        },
        tokens(newVal) {
            //After refreshing the authorization tokens
            //Invalid or expired tokens
            if (newVal && this.user) {
                this.loadModels()
            }
        }
    },
    created() {
        this.$bus.$on('lock-app-loading-overlay', () => {
            this.lockAppLoadingOverlay = true
        })
        this.$bus.$on('unlock-app-loading-overlay', () => {
            this.lockAppLoadingOverlay = false
        })
        /*
        window.addEventListener("keypress", (e) => {
            this.$bus.$emit('app-keypress', e.keyCode);
        });
        */
    },
    beforeMount() {
        //Let's try to load the models when the application
        // starts first (when user is logged out or properly logged in)
        this.loadModels()
    },
    mounted() {
        window.addEventListener('resize', this.calculateContentHeight)

        // Loader
        'load beforeunload'.split(' ').forEach(name => {
            window.addEventListener(name, () => {
                this.loaded = 'load' == name ? true : false
            })
        })
    }
}
</script>
<style lang="scss">
.main-content {
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    .footer {
        margin-top: auto;
    }
}
.spacer {
    height: 103px;
}
html {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}
</style>
