<template>
    <b-navbar toggleable="lg" class="">
        <b-container class="position-relative main-nav-container">
            <b-navbar-brand
                :href="
                    !(this.auth && this.user) ? 'https://mmforbiz.com/' : '/'
                "
                class="logo-container"
            >
                <img
                    :src="require('@assets/images/logo.svg')"
                    alt="MatchMaker4Business"
                />
            </b-navbar-brand>

            <b-navbar-toggle
                target="nav-collapse"
                class="border-0 shadow-none main-nav-list-toogle-container"
            >
                <b-icon icon="list"></b-icon>
            </b-navbar-toggle>

            <b-collapse
                id="nav-collapse"
                class="mt-5 mt-lg-0 px-3 pb-3 p-lg-0 top-0 start-0"
                is-nav
            >
                <b-navbar-nav class="ms-md-auto main-nav-list-container">
                    <b-nav-item
                        v-for="(route, idx) in routes"
                        :key="idx"
                        :to="{ name: route.link }"
                        :exact="handleExactMatchHighlight(route)"
                    >
                        {{ route.name }}</b-nav-item
                    >
                    <b-nav-item :to="{ name: 'Login' }" v-if="!user">
                        Login
                    </b-nav-item>
                    <b-nav-item v-if="user && user.isSuperuser" @click="openStripe()">
                        Stripe
                    </b-nav-item>
                    <b-nav-item-dropdown v-if="!!auth" right>
                        <template #button-content>My Account</template>
                        <b-dropdown-item @click="redirect('Favorites')" href
                            >Favorites</b-dropdown-item
                        >
                        <b-dropdown-item @click="redirect('Searches')" href
                            >Searches</b-dropdown-item
                        >
                        <div class="dropdown-divider"></div>
                        <b-dropdown-item @click="redirect('Profile')" href
                            >Profile</b-dropdown-item
                        >
                        <b-dropdown-item @click="redirect('Account')">
                            <span>Account</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="redirect('Transactions')" href
                            >Transactions</b-dropdown-item
                        >
                        <b-dropdown-item
                            v-if="user && user.isSuperuser"
                            @click="redirect('FlaggedListings')"
                            href
                            >Flagged Listings</b-dropdown-item
                        >
                        <b-dropdown-item
                            v-if="user && user.isSuperuser"
                            @click="redirect('CategoryImport')"
                            href
                            >Upload Categories</b-dropdown-item
                        >
                        <b-dropdown-item @click="redirect('Logout')">
                            <span>Log out</span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>

            <b-navbar-nav
                class="mt-sm-2 mt-lg-0 me-0 signup-btn-container"
                v-if="!auth"
            >
                <b-button
                    @click="redirect('Register')"
                    type="secondary"
                    size="sm"
                    class="py-2 px-3 px-xl-4 text-uppercase"
                >
                    Sign up
                </b-button>
            </b-navbar-nav>
        </b-container>
    </b-navbar>
</template>
<script>
import AuthService from '@serv/AuthService'
import { mapGetters } from 'vuex'
import Redirect from '@serv/Redirect'
import NavigationService from '@serv/NavigationService'
import NotifyService from '@serv/NotifyService'

export default {
    name: 'Navigation',
    props: {},
    computed: {
        ...mapGetters(['user', 'auth', 'tokens']),
    },
    data() {
        return {
            routes: [],
        }
    },
    methods: {
        openStripe() {
            window.open('https://dashboard.stripe.com/dashboard', '_blank').focus()
        },
        redirect(string) {
            Redirect.gotoName(string)
            if (string == 'Logout') {
                AuthService.logout()
                NotifyService.notify(
                    this,
                    'You have been logged out successfully',
                    'success'
                )
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        },
        setNavigation() {
            const user = this.user ? this.user : { type: 'Anonymous' }
            let array = NavigationService.getNavigation(user).navigation

            if (this.user) {
                array.splice(3, 0, { name: 'My Spaces', link: 'Listings' })
            }
            this.routes = array
        },
        handleExactMatchHighlight(route) {
            let exact =
                route.link === 'Home' &&
                !['Home', 'Listing', 'Detail'].includes(this.$route.name)
            exact =
                exact ||
                (route.link === 'SearchJob' && this.$route.name === 'EditOpening')
            return exact
        },
    },
    beforeMount() {
        this.setNavigation()
    },
    watch: {
        user() {
            this.setNavigation()
        },
    },
}
</script>
