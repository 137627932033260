import Redirect from '@serv/Redirect'
import { mapGetters } from 'vuex'
import NotifyService from '@serv/NotifyService'

export default {
    computed: {
        ...mapGetters(['user']),
        hasFlag() {
            return this.property.flag
        },
        isFavorite() {
            return this.user && this.user.favorites.includes(this.property.id)
        }
    },
    props: {
        form: {
            type: Object
        },
        images: {
            type: Array
        },
        view: {
            type: Boolean
        },
        idx: {
            type: Number
        }
    },
    data() {
        return {
            seeMore: false,
            property: {},
            modal: {},
            loaded: false,
            modalObject: undefined
        }
    },
    mixins: [],
    methods: {
        openWebsite(url) {
            window.open(url, '_blank').focus()
        },
        editAction(property, editRedirect = 'Edit') {
            this.modalObject = property
            if (property.status == 'Unpublished') {
                this.$router.push({ name: editRedirect, params: { id: property.id } })
            } else {
                this.$router.push({
                    name: editRedirect,
                    params: { id: property.listingId, isListing: true }
                })
            }
        },
        callAction(option, property) {
            this.modalObject = property
            if (option == 'Delete') {
                this.$bvModal.show('delete-modal')
            } else if (option == 'Unpublish') {
                this.$bvModal.show('unpublish-modal')
            } else if (option == 'Publish') {
                this.$bvModal.show(`publish-modal`)
            }
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        activateReadMore() {
            this.seeMore = true
        },
        unflagProperty() {
            let currentUserFlag = this.property.flag.find(
                item => item.userId == this.user.id
            )
            const flagId = currentUserFlag
                ? currentUserFlag.id
                : this.property.flag[0].id

            if (!flagId) return false

            if (currentUserFlag) {
                this.unflagListing(flagId).then(() => {
                    this.property.flag = null
                })
            } else {
                this.property.flag.forEach(flag => {
                    this.unflagListing(flag.id).then(() => {
                        this.property.flag = null
                    })
                })
            }

            NotifyService.notify(this, 'Listing unreported successfully!', 'success')
        },
        flagProperty() {
            const isValid = this.$refs.flagValidator.validate()

            if (!isValid) {
                return
            }
            this.flagListing(this.modal, this.user.id, this.property.id).then(
                response => {
                    this.$bvModal.hide(`flagModal`)

                    this.property.flag = response

                    NotifyService.notify(
                        this,
                        'Listing reported successfully!',
                        'success'
                    )
                    setTimeout(() => {
                        this.modal = {}
                    }, 500)
                }
            )
        },
        favoriteProperty(property, bool) {
            this.favoriteListing(property.id, bool, this.user).then(() => {
                NotifyService.notify(
                    this,
                    'Listing wishlist preference saved!',
                    'success'
                )
            })
        },
        sanitizeProperty(property) {
            return property
        }
    },
    beforeMount() {
        if (this.form) {
            var form = this.form
            const listingModel = {}
            Object.keys(form).forEach(item => {
                listingModel[item] = form[item]
            })
            listingModel.images = listingModel.images.map(picture => {
                return { url: picture.image }
            })
            this.property = listingModel
            this.loaded = true
        } else {
            if (!this.canAccessView()) {
                return Redirect.gotoName('Not Found')
            }
            this.getProperty(this.$route.params.id)
                .then(listing => {
                    if (listing) {
                        this.property = listing
                    } else {
                        Redirect.gotoName('Not Found')
                    }
                })
                .catch(() => {
                    Redirect.gotoName('Not Found')
                })
                .finally(() => {
                    this.loaded = true
                })
        }
    }
}
