<template>
    <div class="detail-view py-4 py-lg-3">
        <b-container>
            <b-link v-if="!form" @click="redirect(backLocation())">
                <b-icon icon="arrow-left" class="me-1"></b-icon> Back
            </b-link>
            <div v-if="form && !!view" class="d-flex justify-content-end">
                <div>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="text-capitalize m-1"
                        @click="callAction('Edit', property)"
                        >Edit</b-button
                    >

                    <b-button
                        v-if="property.status === 'Published'"
                        variant="outline-primary"
                        size="sm"
                        class="text-capitalize m-1"
                        @click="callAction('Unpublish', property)"
                        >Unpublish</b-button
                    >
                    <b-button
                        v-else
                        variant="outline-primary"
                        size="sm"
                        class="text-capitalize m-1"
                        @click="callAction('Publish', property)"
                        >Publish</b-button
                    >
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="text-capitalize m-1"
                        @click="callAction('Delete', property)"
                        >Delete</b-button
                    >
                </div>
            </div>
            <loading-overlay
                class="loading-list"
                v-if="!loaded"
                :state="!loaded"
            ></loading-overlay>
            <b-row v-if="loaded" class="mt-3">
                <b-col cols="12" lg="8" class="mb-4 mb-lg-0">
                    <carousel
                        v-if="property.images && property.images.length"
                        :files="property.images"
                    />
                    <b-card-img
                        v-else
                        :src="require('@assets/images/image-placeholder.jpeg')"
                        class="rounded"
                    />

                    <div v-if="property" class="property-info mt-4 mt-lg-5">
                        <div class="property-info__heading mb-3 position-relative">
                            <div
                                v-if="!form"
                                class="
									d-inline-b
									actions
									my-2
									position-absolute
									top-0
									end-0
									property-actions
								"
                            >
                                <!-- FLAG -->
                                <div
                                    v-if="hasFlag"
                                    @click="unflagProperty()"
                                    class="action"
                                >
                                    <b-icon icon="flag-fill" font-scale="1.2"></b-icon>
                                </div>
                                <div
                                    v-else
                                    class="action"
                                    @click="$bvModal.show('flagModal')"
                                >
                                    <b-icon icon="flag" font-scale="1.2"></b-icon>
                                </div>
                                <!-- FAVORITE -->
                                <div
                                    v-if="isSpaceFavorite"
                                    class="action"
                                    @click="favoriteProperty(property, false)"
                                >
                                    <b-icon icon="heart-fill" font-scale="1.2"></b-icon>
                                </div>
                                <div
                                    v-else
                                    class="action"
                                    @click="favoriteProperty(property, true)"
                                >
                                    <b-icon icon="heart" font-scale="1.2"></b-icon>
                                </div>
                            </div>
                            <h1
                                class="
									property-info__title
									h2
									text-gray-800
									mb-0
									fw-bold
								"
                            >
                                {{ propertyAddress(property) }}
                            </h1>
                        </div>

                        <p class="property-info__address lh-1">
                            {{ propertyLocation(property) }}
                        </p>

                        <div class="property-details mb-4 mb-lg-5 5">
                            <span>{{ `${property.description}` }}</span>
                        </div>

                        <div class="mb-4 mb-lg-5">
                            <h4 class="property-info__sub-title fw-bold">
                                More About the Space
                            </h4>

                            <p class="mb-2" v-if="property.type">
                                <strong>Property Type:</strong>
                                {{ typeDisplayName(property.type) }}
                            </p>
                            <p class="mb-2" v-if="property.shareScopeType">
                                <strong>Space:</strong> {{ property.shareScopeType }}
                            </p>

                            <strong>Specialties:</strong>
                            <ul class="mt-3">
                                <li v-if="property.hasBathroom">
                                    Bathroom available for use in space
                                </li>
                                <li v-if="property.hasOfficeKitchen">Office Kitchen</li>
                                <li v-if="property.hasSharedStaff">Staff Sharing</li>
                                <li v-if="property.hasHandicapAccess">
                                    Handicap Accessible
                                </li>
                                <li v-if="property.hasLockers">
                                    Locker available for use in space
                                </li>
                            </ul>

                            <p
                                class="mb-2 website-link"
                                v-if="property.website"
                                @click="openWebsite(property.website)"
                            >
                                <strong>Website:</strong>

                                {{ property.website }}
                            </p>
                        </div>

                        <div class="mb-5">
                            <h4 class="property-info__sub-title fw-bold">
                                Sharing Information
                            </h4>

                            <p class="mb-2" v-if="property.minHours">
                                <strong
                                    >Minimum # of Hours Required to Share the
                                    Space:</strong
                                >
                                {{ property.minHours }}
                            </p>
                            <p class="mb-2" v-if="property.occupants">
                                <strong
                                    >Practitioners Already Practicing in the
                                    Space:</strong
                                >
                                {{ property.occupants }}
                            </p>
                            <p class="mb-2" v-if="property.waitingRoomSeats">
                                <strong>Seats in Waiting Room:</strong>
                                {{ property.waitingRoomSeats }}
                            </p>
                            <p class="mb-2" v-if="!!property.hasBathroom">
                                <strong>Bathroom available for use:</strong>
                                Yes
                            </p>
                            <p class="mb-2" v-if="!!property.hasBathroomHandicap">
                                <strong>Bathroom handicap accessible:</strong>
                                Yes
                            </p>
                            <p class="mb-2" v-if="!!property.hasBathroomBabyChange">
                                <strong
                                    >Bathroom available with baby changing
                                    station:</strong
                                >
                                Yes
                            </p>
                            <p class="mb-2" v-if="!!property.hasBathroomPublic">
                                <strong>Public bathroom in common area:</strong>
                                Yes
                            </p>
                            <p class="mb-2" v-if="!!property.hasParking">
                                <strong>Parking available</strong>
                                Yes
                            </p>
                            <p class="mb-2" v-if="property.availableInternetType">
                                <strong>Internet available</strong>
                                {{ property.availableInternetType }}
                            </p>
                        </div>

                        <div class="mt-4 mb-5" v-if="property.additionalInfo">
                            <h4 class="property-info__sub-title fw-bold">
                                Additional Information
                            </h4>

                            <p class="mb-2">
                                {{ property.additionalInfo }}
                            </p>
                        </div>

                        <div
                            v-if="property.extra && extraInformations"
                            class="mt-4 mb-5"
                        >
                            <h4 class="property-info__sub-title fw-bold">
                                Extra Information
                            </h4>

                            <div
                                v-for="extraInformation in extraInformations"
                                :key="extraInformation.label"
                                class="mb-2"
                            >
                                <strong>{{ extraInformation.label }}:</strong>

                                <ul
                                    class="mt-3"
                                    v-if="Array.isArray(extraInformation.value)"
                                >
                                    <li
                                        v-for="(value, idx) in extraInformation.value"
                                        :key="idx"
                                    >
                                        {{ value }}
                                    </li>
                                </ul>

                                <span v-else>
                                    {{ extraInformation.value }}
                                </span>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" lg="4">
                    <GmapMap
                        v-if="property.coords"
                        :center="{
                            lat: property.coords.lat,
                            lng: property.coords.long
                        }"
                        :zoom="16"
                        map-type-id="terrain"
                        style="height: 230px"
                        class="mb-4"
                    >
                        <GmapMarker
                            ref="marker"
                            :key="1"
                            :position="
                                google &&
                                    new google.maps.LatLng(
                                        property.coords.lat,
                                        property.coords.long
                                    )
                            "
                            :clickable="true"
                            :draggable="true"
                            @click="center = m.position"
                        />
                    </GmapMap>

                    <b-row class="property-widget">
                        <b-col cols="12" sm="6" lg="12">
                            <b-list-group class="px-1 mb-4 shadow">
                                <b-list-group-item
                                    class="border-top-0 border-end-0 border-start-0"
                                >
                                    <p class="property-widget__title fw-bold">
                                        Contact
                                    </p>
                                    <p class="h3 mb-2 text-primary fw-bold">
                                        {{ property.contactName }}
                                    </p>
                                    <p v-if="property.contactPhone" class="mb-2 fs-5">
                                        {{ property.contactPhone }}
                                    </p>
                                    <p v-if="property.contactEmail" class="mb-0 fs-5">
                                        {{ property.contactEmail }}
                                    </p>
                                </b-list-group-item>
                                <b-list-group-item
                                    class="border-end-0 border-bottom-0 border-start-0"
                                >
                                    <p class="property-widget__title fw-bold d-block">
                                        Rate
                                    </p>
                                    <p
                                        :class="{
                                            h2: 'Negotiable' !== property.compRateType
                                        }"
                                        class="
											text-gray-300
											mb-0
											text-primary
											fw-bold
										"
                                    >
                                        <template
                                            v-if="
                                                'Negotiable' === property.compRateType
                                            "
                                            >Please Contact Us to Discuss the
                                            Rate</template
                                        >
                                        <template
                                            v-if="'Own' === property.compRateType"
                                        >
                                            {{ propertyCurrency(property) }}
                                            {{ property.compRateFree }}</template
                                        >
                                        <template
                                            v-if="'Fill' === property.compRateType"
                                        >
                                            {{ propertyCurrency(property) }}
                                            {{ property.compRatePrice }}</template
                                        >
                                    </p>
                                    <p
                                        v-if="
                                            property.compRatePeriodType &&
                                                property.compRateType == 'Fill'
                                        "
                                        class="mt-2 mb-0"
                                    >
                                        {{ `   /  ${property.compRatePeriodType}` }}
                                    </p>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                        <b-col cols="12" sm="6" lg="12">
                            <schedule
                                label="Availability"
                                :view="true"
                                class="property-widget property-widget--availability"
                                v-model="property.timeslots"
                            />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-modal class="centered" id="flagModal" @ok.prevent="flagProperty()">
                <template #modal-title>FLAG</template>
                <validation-observer ref="flagValidator">
                    <t-input
                        alternative
                        class="mb-3 mb-lg-4"
                        name="reason"
                        label="Reason"
                        type="text"
                        :rules="{ required: true }"
                        v-model="modal.reason"
                    />
                    <b-form-group class="mb-3 mb-lg-4">
                        <template #label>
                            Comment
                            <small class="text-danger">*</small>
                        </template>

                        <validation-provider
                            rules="required"
                            name="description"
                            v-bind="$attrs"
                            v-slot="{ errors, validated }"
                        >
                            <b-textarea
                                class="text-area"
                                :class="{
                                    'is-invalid': errors.length && validated
                                }"
                                v-model="modal.comment"
                                rows="10"
                                cols="50"
                            ></b-textarea>

                            <div v-if="0 < errors.length" class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import Schedule from '@comp/Schedule'
import Carousel from '@comp/Carousel'
import { gmapApi } from 'vue2-google-maps'
import Redirect from '@serv/Redirect'
import PropertyService from '@serv/PropertyService'
import { mapGetters } from 'vuex'
import NotifyService from '@serv/NotifyService'
import LoadingOverlay from '@comp/LoadingOverlay'
import PropertyMixin from '@mixins/PropertyMixin'

export default {
    name: 'AccountType',
    metaInfo: {
        title: 'Property Detail'
    },
    components: {
        Schedule,
        Carousel,
        LoadingOverlay
    },
    computed: {
        ...mapGetters(['user', 'fieldTypes', 'typeDisplayName']),
        google: gmapApi,
        hasFlag() {
            return this.property.flag
        },
        isSpaceFavorite() {
            return this.user && this.user.favorites.includes(this.property.id)
        },
        extraInformations() {
            if (!this.fieldTypes) return false

            const typeIdx = this.fieldTypes.findIndex(
                item => item.name == this.property.type
            )

            if (false === typeIdx) return false

            const type = this.fieldTypes[typeIdx]

            return Object.keys(type.extraFieldsDefinition.properties)
                .filter(item => this.property.extra[item])
                .map(item => {
                    return {
                        label: type.extraFieldsDefinition.properties[item].label,
                        value: this.property.extra[item]
                    }
                })
        }
    },
    props: {
        form: {
            type: Object
        },
        images: {
            type: Array
        },
        view: {
            type: Boolean
        },
        idx: {
            type: Number
        }
    },
    data() {
        return {
            seeMore: false,
            property: {},
            modal: {},
            loaded: false,
            modalObject: undefined
        }
    },
    mixins: [PropertyMixin],
    methods: {
        openWebsite(url) {
            window.open(url, '_blank').focus()
        },
        callAction(option, property) {
            this.modalObject = property
            if (option == 'Delete') {
                this.$bvModal.show('delete-modal')
            }
            if (option == 'Edit') {
                if (property.status == 'Unpublished') {
                    this.$router.push({ name: 'Edit', params: { id: property.id } })
                } else {
                    this.$router.push({
                        name: 'Edit',
                        params: { id: property.listingId, isListing: true }
                    })
                }
            }
            if (option == 'Unpublish') {
                this.$bvModal.show('unpublish-modal')
            }
            if (option == 'Publish') {
                if (this.user.listingAllowed >= 1 || this.user.isSuperuser) {
                    this.$bvModal.show(`publish-modal-${this.idx}`)
                } else {
                    this.$bvModal.show(`runout-modal-${this.idx}`)
                }
            }
        },
        redirect(location) {
            Redirect.gotoName(location)
        },
        backLocation() {
            if (this.$route.params.adminRedirectToUsers && this.user.isSuperuser) {
                return 'ListUser'
            } else {
                return 'Listing'
            }
        },
        activateReadMore() {
            this.seeMore = true
        },
        unflagProperty() {
            let currentUserFlag = this.property.flag.find(
                item => item.userId == this.user.id
            )
            const flagId = currentUserFlag
                ? currentUserFlag.id
                : this.property.flag[0].id

            if (!flagId) return false

            if (currentUserFlag) {
                PropertyService.unflagProperty(flagId).then(() => {
                    this.property.flag = null
                })
            } else {
                this.property.flag.forEach(flag => {
                    PropertyService.unflagProperty(flag.id).then(() => {
                        this.property.flag = null
                    })
                })
            }

            NotifyService.notify(this, 'Listing unreported successfully!', 'success')
        },
        flagProperty() {
            const isValid = this.$refs.flagValidator.validate()

            if (!isValid) {
                return
            }
            PropertyService.flagProperty(
                this.modal,
                this.user.id,
                this.property.id
            ).then(response => {
                this.$bvModal.hide(`flagModal`)

                this.property.flag = response

                NotifyService.notify(this, 'Listing reported successfully!', 'success')
                setTimeout(() => {
                    this.modal = {}
                }, 500)
            })
        },
        favoriteProperty(property, bool) {
            PropertyService.favoriteProperty(property.id, bool, this.user).then(() => {
                NotifyService.notify(
                    this,
                    'Listing wishlist preference saved!',
                    'success'
                )
            })
        },
        sanitizeProperty(property) {
            property.timeslots.forEach(day => {
                if (day.startTime && day.startTime.length >= 7) {
                    day.startTime = day.startTime.slice(0, -3)
                }

                if (day.endTime && day.endTime.length >= 7) {
                    day.endTime = day.endTime.slice(0, -3)
                }
            })
            return property
        }
    },
    beforeMount() {
        if (this.form) {
            this.property = this.sanitizeProperty(this.form)
            if (this.form.lat && this.form.long) {
                this.property.coordinate = {
                    latitude: this.form.lat,
                    longitude: this.form.long
                }
            }
            if (!this.property.images) {
                this.property.images = this.images
            }
            this.loaded = true
        } else {
            PropertyService.getProperty(
                this.$route.params.id,
                this.$route.params.isListing
            ).then(listing => {
                this.property = this.sanitizeProperty(listing)
                this.loaded = true
            })
        }
    }
}
</script>

<style lang="scss">
@import './../assets/sass/pages/_property-detail.scss';
</style>
