import axios from '@serv/AxiosService'
import Redirect from '@serv/Redirect'
import Space from '@models/Space'
import User from '@models/User'
import store from '@store'
class PropertyService {
    getCountries() {
        return axios
            .get('countries/')
            .then(res => {
                return res.data
            })

            .catch(error => {
                throw error
            })
    }
    getSpaceTypes() {
        return axios
            .get('spaces/types/')
            .then(res => {
                store.commit('setSpaceTypes', res.data)
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    getPlans() {
        return axios
            .get('plans')
            .then(res => {
                store.commit('setPlans', res.data)
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    getProperties() {
        return axios
            .get('spaces/listings/')
            .then(res => {
                const data = res.data.data.map(listing => {
                    return new Space(listing)
                })
                return {
                    data: data,
                    expandedSearch: res.data.expandedSearch
                }
            })

            .catch(error => {
                throw error
            })
    }
    getProperty(id, isListing) {
        if (isListing) {
            return axios
                .get(`spaces/listings/${id}/`)
                .then(res => {
                    return new Space(res.data)
                })

                .catch(error => {
                    throw error
                })
        } else {
            return axios
                .get(`spaces/spaces/${id}/`)
                .then(res => {
                    return new Space(res.data.data)
                })

                .catch(error => {
                    throw error
                })
        }
    }
    getPropertiesByQuery(query) {
        return axios
            .get('spaces/listings/', { params: query })
            .then(res => {
                const data = res.data.data.map(listing => {
                    return new Space(listing)
                })
                return {
                    data: data,
                    expandedSearch: res.data.expandedSearch
                }
            })
            .catch(error => {
                throw error
            })
    }
    deleteProperty(id) {
        return axios
            .delete(`spaces/spaces/${id}/`)
            .then(() => {
                return
            })

            .catch(error => {
                throw error
            })
    }
    deleteFlag(id) {
        return axios
            .delete(`spaces/flags/${id}/`)
            .then(() => {
                return
            })

            .catch(error => {
                throw error
            })
    }
    createProperty(property, sessionRequest, draft) {
        return axios
            .post('spaces/spaces/', property)
            .then(propertyResponse => {
                if (!draft) {
                    axios
                        .post('spaces/listings/', {
                            spaceId: propertyResponse.data.data.id
                        })
                        .then(() => {
                            if (!sessionRequest) {
                                Redirect.gotoName('Listing')
                            }
                            return propertyResponse.data.detail
                        })
                }
                if (sessionRequest) {
                    Redirect.gotoName('Listings')
                }
            })

            .catch(error => {
                throw error
            })
    }
    updateProperty(property) {
        return axios
            .patch(`spaces/spaces/${property.id}/`, property)
            .then(res => {
                return res.data
            })

            .catch(error => {
                throw error
            })
    }
    flagProperty(form, userId, listingId) {
        return axios
            .post('spaces/flags/', {
                title: form.reason,
                body: form.comment,
                userId: userId,
                spaceId: listingId
            })
            .then(res => {
                return res.data.data
            })

            .catch(error => {
                throw error
            })
    }
    unflagProperty(flagId) {
        return axios
            .delete(`spaces/flags/${flagId}/`)
            .then(() => {
                return
            })

            .catch(error => {
                throw error
            })
    }
    changeListingStatus(toPublish, property) {
        if (property.listingId) {
            if (toPublish) {
                return axios
                    .patch(`spaces/spaces/${property.id}/`, property)
                    .then(() => {
                        return axios
                            .post('spaces/listings/', {
                                spaceId: property.id
                            })
                            .then(res => {
                                return res.data.detail
                            })
                    })
            } else {
                return axios
                    .delete(`spaces/listings/${property.listingId}/`)
                    .then(() => {
                        Redirect.gotoName('Listings')
                        property.status = 'Unpublished'
                        property.listingId = null
                        return property
                    })

                    .catch(error => {
                        throw error
                    })
            }
        } else {
            return axios
                .post('spaces/listings/', {
                    spaceId: property.id
                })
                .then(res => {
                    Redirect.gotoName('Listings')
                    return res.data.space
                })

                .catch(error => {
                    throw error
                })
        }
    }
    favoriteProperty(propertyId, bool, user) {
        let array = user.favorites
        bool ? array.push(propertyId) : (array = array.filter(id => id !== propertyId))

        return axios
            .patch('auth/user/', { favoriteSpacesId: array })
            .then(res => {
                const user = new User(res.data)
                store.commit('setUser', user)
            })

            .catch(error => {
                throw error
            })
    }
    postSearch(payload) {
        return axios
            .post(`spaces/search-queries/`, payload)
            .then(res => {
                return res.data
            })

            .catch(error => {
                throw error
            })
    }
    getSavedSearch(id) {
        return axios
            .get(`spaces/search-queries/?owner_id=${id}`)
            .then(res => {
                return res.data
            })

            .catch(error => {
                throw error
            })
    }
    deleteSavedSearch(id) {
        return axios
            .delete(`spaces/search-queries/${id}`)
            .then(() => {
                return
            })

            .catch(error => {
                throw error
            })
    }
    getFavoriteProperties() {
        return axios
            .get('auth/user/favorites')
            .then(res => {
                return res.data
            })

            .catch(error => {
                throw error
            })
    }
    noResultNotify(payload) {
        return axios
            .post('/notifications/city-availability/', payload)
            .then(res => {
                return res.data
            })
            .catch(error => {
                throw error
            })
    }
    getFlaggedListings() {
        return axios
            .get('spaces/listings/?flagged=true')
            .then(res => {
                const data = res.data.data.map(listing => {
                    return new Space(listing)
                })
                return data
            })

            .catch(error => {
                throw error
            })
    }
}

export default new PropertyService()
