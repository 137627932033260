<template>
    <b-modal
        id="filterModal"
        size="lg"
        dialog-class="my-0 me-0"
        modal-class="right"
        hide-backdrop
    >
        <template #modal-title>Additional Filters</template>
        <form>
            <div class="top-actions modal-footer">
                <b-button
                    @click="$bvModal.hide('filterModal')"
                    variant="link"
                    class="w-99 text-dark px-0 m-0 shadow-none"
                    >Close</b-button
                >
                <b-button
                    @click="resetForm()"
                    variant="link"
                    class="w-100 text-info px-0 m-0 shadow-none"
                    >Clear</b-button
                >
                <b-button
                    @click="postForm()"
                    variant="link"
                    class="w-100 text-info px-0 m-0 shadow-none"
                    >Save Changes</b-button
                >
            </div>

            <t-input
                alternative
                label="Estimated Sharing Rate (Per Month)"
                class="mb-3"
                type="number"
                placeholder="Enter Desired Rate"
                v-model="form.compRatePrice__gte"
                mode="lazy"
            />

            <t-checkbox class="mt-3 mt-lg-4" v-model="form.isRateNegotiable">
                Is Rate Negotiable</t-checkbox
            >

            <t-checkbox class="mt-3 mt-lg-4 " v-model="form.hasSharedStaff">
                Shared Office Staff</t-checkbox
            >

            <t-slider
                label="Occupants in the Space"
                class="mt-3 mt-lg-4 d-block"
                v-model="form.occupants__lte"
                :min="0"
                :max="50"
            />

            <t-slider
                label="Minimum Hours to Share"
                class="mt-3 mt-lg-4 d-block"
                v-model="form.minHours__lte"
                :min="0"
                :max="100"
            />

            <template v-if="formFields">
                <h4 class="mt-4 mt-md-5 mb-2 text-gray-800 ff-primary fw-bold">
                    Administrative
                </h4>

                <div v-for="formField in formFields" :key="`field-${formField.name}`">
                    <t-slider
                        v-if="'slider' === formField.widget"
                        v-model="form[`${formField.name}__gte`]"
                        :label="formField.label"
                        class="mt-3 mt-lg-4 d-block"
                        :min="formField.minimum || 0"
                        :max="50"
                    />

                    <t-input
                        v-if="
                            ![
                                'textarea',
                                'dropdown',
                                'slider',
                                'single-select-dropdown'
                            ].includes(formField.widget)
                        "
                        alternative
                        :label="formField.label"
                        class="mt-3"
                        type="number"
                        v-model="
                            form[
                                formField.filter
                                    ? `${formField.name}__${formField.filter}`
                                    : formField.name
                            ]
                        "
                        mode="lazy"
                    />
                    <b-form-group
                        class="mt-3"
                        v-if="
                            ['single-select-dropdown', 'dropdown'].includes(
                                formField.widget
                            )
                        "
                    >
                        <template #label> {{ formField.label }} </template>
                        <v-select
                            v-model="form[formField.name]"
                            :options="formField.choices"
                        >
                        </v-select>
                    </b-form-group>
                    <!-- 
                        Text area widgets are no used.
                        <b-form-group class="mt-3" v-if="'textarea' === formField.widget">
                        <template #label> {{ formField.label }} </template>
                        <b-textarea
                            class="text-area"
                            v-model="form[formField.name]"
                            rows="5"
                            cols="50"
                        >
                        </b-textarea>
                    </b-form-group>-->
                </div>
            </template>
            <p class="mt-4 mb-0" v-if="!formFields">
                <i
                    >For additional filters please select an office type on top of the
                    list page</i
                >
            </p>
        </form>

        <template #modal-footer>
            <b-button
                @click="$bvModal.hide('filterModal')"
                variant="link"
                class="w-100 text-dark px-0 m-0 shadow-none"
                >Close</b-button
            >
            <b-button
                @click="resetForm()"
                variant="link"
                class="w-100 text-info px-0 m-0 shadow-none"
                >Clear</b-button
            >
            <b-button
                @click="postForm()"
                variant="link"
                class="w-100 text-info px-0 m-0 shadow-none"
                >Save Changes</b-button
            >
        </template>
    </b-modal>
</template>

<script>
import ResourceService from '@serv/ResourceService'
import { mapGetters } from 'vuex'

export default {
    name: 'ListFiltersModal',
    components: {},
    data() {
        return {
            form: {},
            filtersSelected: {}
        }
    },
    props: {
        activeFilters: {
            type: Array
        }
    },
    computed: {
        ...mapGetters(['user', 'auth', 'filters', 'fieldTypes']),
        selectedFieldType() {
            const typeIndex = this.filters.findIndex(item => 'type' === item.name)

            if (typeIndex < 0) return null

            const type = this.filters[typeIndex]
            const fieldTypeIdx = this.fieldTypes.findIndex(
                item => type.value === item.name
            )

            if (0 <= fieldTypeIdx) return this.fieldTypes[fieldTypeIdx]

            return null
        },
        formFields() {
            if (!this.selectedFieldType) return null

            const formFields = this.selectedFieldType.extraFieldsDefinition.properties
            return _.orderBy(formFields, ['order'])
        }
    },
    beforeMount() {
        let object = {}
        if (this.activeFilters.length > 0) {
            this.activeFilters.forEach(filter => {
                object[filter.name] = filter.value
            })
        }
        this.form = object
    },
    methods: {
        getExtraFieldModelKey(extraField) {
            return 1 < extraField.filters.length
                ? `${extraField.name}__gte`
                : extraField.name
        },
        resetForm() {
            this.form = {}
        },
        postForm() {
            let array = []

            Object.keys(this.form)
                .filter(field => {
                    return this.form[field]
                })
                .forEach(field => {
                    array.push({
                        name: field,
                        value: this.form[field]
                    })
                })

            ResourceService.multiFilterPost(array)
            this.$bvModal.hide('filterModal')
        }
    },
    watch: {
        filters() {
            if (!this.filters.length) {
                this.form = {}
            }
        }
    }
}
</script>
<style lang="scss">
.top-actions {
    border: none;
    border-top-width: 2px;
    border-top-color: #e9ecef;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    button {
        text-decoration: none;
    }
}
</style>
