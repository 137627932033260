<template>
    <div>
        <div id="get-in-touch" :style="{backgroundImage: `url(${require('@assets/images/Waiting-Room-Outline-1.svg')})`}">
            <b-container class="p-0">
                <h1>Contact Us</h1>
                <b-row class="mx-0">
                    <b-col md="6" lg="5">
                        <h2>Get In Touch</h2>
                        <p class="mb-0">Having trouble? Or just looking for more information? Please reach out to us at
                            <b>
                                <a :href="`mailto:${getEnv('VUE_APP_SUPPORT_EMAIL')}`">{{
                                    getEnv('VUE_APP_SUPPORT_EMAIL')
                                }}</a>
                            </b>
                            <template v-if="getEnv('VUE_APP_SUPPORT_PHONE')">
                                or call
                                <b
                                    ><a
                                        :href="`tel:${getEnv('VUE_APP_SUPPORT_PHONE')}`"
                                        >{{ getEnv("VUE_APP_SUPPORT_PHONE") }}</a
                                    ></b
                                ></template
                            >
                        </p>
                    </b-col>
                    <b-col md="6" lg="7" class="p-0">
                        <b-card body-class="p-0" class="contact-card mx-auto border-0">
                            <contact-form></contact-form>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import ContactForm from '@comp/form/ContactForm'
import EnvMixin from '@mixins/EnvMixin'

export default {
    name: 'Contact',
    metaInfo: {
        title: 'Contact'
    },
    components: {
        [ContactForm.name]: ContactForm
    },
    mixins: [EnvMixin],
}
</script>

<style lang="scss" scoped>
@import './../assets/sass/pages/_contact.scss';
</style>
