<template>
    <div
        class="password-reset-form d-flex align-items-center justify-content-center mb-4 mt-4"
    >
        <b-container>
            <h4 class="mb-4 ff-secondary fs-title text-center">
                Reset Password
            </h4>
            <b-card no-body class="py-2 px-2 px-lg-0 mb-0 border-0">
                <b-card-body class="p-4 p-lg-5">
                    <div class="text-muted mb-4">
                        <small>
                            To recover your password, please fill this form with a new
                            one.
                        </small>
                    </div>

                    <validation-observer
                        ref="formValidator"
                        v-slot="{ invalid }"
                        tag="form"
                        @submit.prevent="onSubmit()"
                    >
                        <t-input
                            alternative
                            class="mb-3"
                            name="newPassword1"
                            :rules="{ required: true, min: 8 }"
                            type="password"
                            label="New Password"
                            placeholder="New Password"
                            v-model="form.password1"
                            vid="new-password"
                            autocomplete="new-password"
                        />

                        <t-input
                            alternative
                            class="mb-3"
                            name="newPassword2"
                            :rules="{
                                required: true,
                                min: 8,
                                confirmed: 'new-password'
                            }"
                            type="password"
                            label="Confirm Password"
                            placeholder="Confirm Password"
                            v-model="form.password2"
                            autocomplete="new-password"
                        />

                        <div class="text-center">
                            <t-button
                                type="secondary"
                                native-type="submit"
                                size="sm"
                                class="w-75 text-white mx-auto text-uppercase"
                                :loading="loading"
                                :disabled="invalid"
                                >Submit</t-button
                            >
                        </div>
                    </validation-observer>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import AuthService from '@serv/AuthService'
import Redirect from '@serv/Redirect'
import NotifyService from '@serv/NotifyService'

export default {
    metaInfo: {
        title: 'Password Reset'
    },
    data() {
        return {
            loading: false,
            form: {
                password1: '',
                password2: ''
            }
        }
    },
    methods: {
        async onSubmit() {
            this.loading = true
            const isValid = await this.$refs.formValidator.validate()
            if (!isValid) {
                this.loading = false
                return
            }

            if (this.form.password1 && this.form.password2) {
                const credentials = {
                    newPassword1: this.form.password1,
                    newPassword2: this.form.password2,
                    uid: this.$route.params.uid,
                    token: this.$route.params.token
                }
                AuthService.passwordReset(credentials)
                    .then(() => {
                        NotifyService.notify(
                            this,
                            'You can now login with your new password.',
                            'success'
                        )
                        Redirect.gotoName('Login')
                    })
                    .catch(err => {
                        if (!NotifyService.notify400Error(this, err)) {
                            NotifyService.notify(
                                this,
                                'Your password could not be updated.',
                                'danger'
                            )
                        }
                    })
                this.loading = false
            }
        }
    }
}
</script>
