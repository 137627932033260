<template>
    <div class="create-form">
        <multi-step :create="true" />
    </div>
</template>

<script>
import MultiStep from '@comp/MultiStep'

export default {
    name: 'Create',
    metaInfo: {
        title: 'New Listing'
    },
    components: { MultiStep },
    data() {
        return {}
    },
    props: {
        crumbs: {
            type: Array
        }
    }
}
</script>
