import Vue from 'vue'
import axios from '@serv/AxiosService'
import AuthService from '@serv/AuthService'
import NotifyService from '@serv/NotifyService'
import store from '@store'

class TokenService {
    getLocalTokens() {
        const userTokens = JSON.parse(localStorage.getItem('userTokens'))

        return userTokens && userTokens.refreshToken
    }

    getLocalAccessToken() {
        if (localStorage.getItem('userTokens')) {
            return JSON.parse(localStorage.getItem('userTokens')).accessToken
        } else {
            return false
        }
    }

    updateLocalAccessToken(token, refresh = null) {
        let userTokens = JSON.parse(localStorage.getItem('userTokens'))

        userTokens.accessToken = token
        userTokens.refreshToken = refresh || userTokens.refreshToken
        localStorage.setItem('userTokens', JSON.stringify(userTokens))

        store.commit('setTokens', userTokens)
    }

    setLocalUserTokens(userTokens) {
        localStorage.setItem('userTokens', JSON.stringify(userTokens))

        store.commit('setTokens', userTokens)
    }

    removeLocalUserTokens() {
        localStorage.removeItem('userTokens')

        store.commit('setTokens', null)
    }

    /**
     * Revoke auth token.
     *
     * @params {object} args
     */
    revokeToken(args = {}) {
        if ('undefined' == typeof args.notify || args.notify)
            NotifyService.notify(Vue.prototype, 'User logged out.', 'danger')

        if (store.getters.user) AuthService.logout()
    }

    /**
     * Refresh auth token.
     *
     * @param {string} refreshToken The refresh token.
     * @returns Promise
     */
    async refreshToken(refreshToken) {
        if (!refreshToken) {
            this.revokeToken({ notify: false })

            return
        }

        const rs = await axios
            .post('/auth/token/refresh/', {
                refresh: refreshToken
            })
            .catch(() => {
                this.revokeToken()
            })

        if (rs && rs.data) {
            this.updateLocalAccessToken(rs.data.access, rs.data.refresh)
        }
        return rs
    }
}

export default new TokenService()
