<template>
    <div>
        <b-modal
            size="xl"
            body-class="pt-0 px-0"
            class="centered"
            :id="`runout-modal-${id}`"
            @ok="goCheckout()"
            ok-title="Checkout"
            return-focus="body"
        >
            <template #modal-title>Product Purchase</template>
            <CouponBanner />
            <div class="pt-4 px-4 pt-lg-5 px-lg-5">
                <div class="mb-4 text-center">
                    You have run out of listings to publish on your account. Please
                    purchase one of the choices below to publish more listings.
                </div>
                <product-type :viewOn="false" size="sm" />

                <t-input
                    alternative
                    class="mt-3 coupon-field"
                    rules="coupon"
                    name="couponCode"
                    label="Coupon (Optional)"
                    type="text"
                    v-model="couponCode"
                />
            </div>
        </b-modal>
        <checkout />
    </div>
</template>

<script>
import CouponBanner from '@comp/CouponBanner'
import ProductType from '@comp/ProductType'
import FormMixin from '@mixins/FormMixin'
import Checkout from '@comp/Checkout'
import UserService from '@serv/UserService'
import NotifyService from '@serv/NotifyService'
import { mapGetters } from 'vuex'

export default {
    name: 'RunoutModal',
    components: { CouponBanner, ProductType, Checkout },
    mixins: [FormMixin],
    props: {
        scope: {
            required: true
        },
        id: {
            default: 0
        },
        multi: {
            type: Boolean
        },
        list: {
            type: Boolean
        }
    },
    data() {
        return {
            couponCode: null
        }
    },
    computed: {
        ...mapGetters(['user', 'productType', 'plans'])
    },
    methods: {
        goCheckout() {
            let productValue = this.plans.find(plan => plan.name === this.productType)
                .price.id

            if (!!this.multi && !this.couponCode && !this.list) {
                this.scope.saveProperty()
            }

            UserService.getStripeSession(productValue, this.couponCode)
                .then(stripeSessionId => {
                    if (!this.couponCode) {
                        this.$bus.$emit('checkoutOpen', stripeSessionId)
                    }
                    if (!this.list) {
                        this.scope.publishProperty()
                    } else {
                        UserService.getUser()
                    }
                    NotifyService.notify(
                        this,
                        'Product purchased successfully!',
                        'success'
                    )
                })
                .catch(() => {
                    NotifyService.notify(
                        this,
                        'An error has occurred during your purchase , please contact our team',
                        'danger'
                    )
                })
        }
    }
}
</script>
