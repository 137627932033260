<template>
    <b-button
        :type="nativeType"
        :disabled="disabled || loading || isProcessing"
        @click="handleClick"
        class="base-button position-relative"
        :variant="!outline ? type : `outline-${type}`"
        :size="size"
        :block="block"
        :to="to"
        :class="[
            { 'rounded-circle': round },
            { 'btn-wd': wide },
            { 'btn-icon btn-fab': icon },
            { 'btn-link': link },
            { disabled: disabled }
        ]"
    >
        <slot name="loading">
            <b-overlay
                :show="loading || isProcessing"
                :opacity="0.15"
                rounded="sm"
                spinner-type="grow"
                spinner-small
                class="w-100 h-100 position-absolute"
            >
            </b-overlay>
        </slot>

        <slot></slot>
    </b-button>
</template>
<script>
export default {
    name: 'TButton',
    props: {
        round: Boolean,
        icon: Boolean,
        block: Boolean,
        loading: Boolean,
        wide: Boolean,
        disabled: Boolean,
        to: {
            type: Object,
            default: null,
            description: 'Vue Router route property'
        },
        type: {
            type: String,
            default: 'default',
            description: 'Button type (primary|secondary|danger etc)'
        },
        nativeType: {
            type: String,
            default: 'button',
            description: 'Button native type (e.g button, input etc)'
        },
        size: {
            type: String,
            default: '',
            description: 'Button size (sm|lg)'
        },
        outline: {
            type: Boolean,
            description: 'Whether button is outlined (only border has color)'
        },
        link: {
            type: Boolean,
            description: 'Whether button is a link (no borders or background)'
        }
    },
    data() {
        return {
            isProcessing: false
        }
    },
    methods: {
        handleClick(evt) {
            this.$emit('click', evt)
        }
    },
    mounted() {
        this.$bus.$on('api-request', payload => {
            this.isProcessing = payload
        })
    }
}
</script>
<style lang="scss">
.base-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i {
        padding: 0 3px;
    }
}
</style>
