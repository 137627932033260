<template>
    <validation-provider :name="lowerCasedLabel" v-bind="$attrs">
        <b-form-group class="t-range" :label="label">
            <div
                :class="[{ focused: focused }, { 'has-label': label || $slots.label }]"
            >
                <slot v-bind="slotData">
                    <vue-slider
                        @change="updateValue"
                        :min="min"
                        :max="max"
                        :value="value"
                    ></vue-slider>
                </slot>
            </div>
        </b-form-group>
    </validation-provider>
</template>
<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
    inheritAttrs: false,
    name: 'TSlider',
    components: {
        VueSlider
    },
    props: {
        min: {
            type: Number
        },
        max: {
            type: Number
        },
        label: {
            type: String,
            description: 'Input label (text before input)'
        },
        value: {
            type: [Array, Number],
            description: 'Input value'
        },
        type: {
            type: String,
            description: 'Input type',
            default: 'text'
        },
        name: {
            type: String,
            description: 'Input name (used for validation)',
            default: ''
        }
    },
    data() {
        return {
            focused: false
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: this.updateValue,
                focus: this.onFocus,
                blur: this.onBlur
            }
        },
        slotData() {
            return {
                focused: this.focused,
                error: this.error,
                ...this.listeners
            }
        },
        lowerCasedLabel() {
            return this.label.toLowerCase()
        },
        fieldErrors() {
            const fieldName = this.name

            return this.$errorService.errors.filter(
                item => 'field' == item.type && fieldName == item.field
            )
        }
    },
    methods: {
        updateValue(evt) {
            this.$emit('input', evt)
        },
        onFocus(evt) {
            this.focused = true
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false
            this.$emit('blur', evt)
        }
    },
    mounted() {},
    watch: {
        fieldErrors(newVal) {
            if (!newVal.length) return false

            this.$refs.input.setErrors(newVal.map(item => item.message))
        }
    }
}
</script>
<style lang="scss">
.t-range {
    .vue-slider {
        padding: 7px !important;
    }
    .vue-slider-process {
        background-color: #10377a;
    }
    .vue-slider-dot-tooltip-inner {
        background-color: #5ca6cc;
    }
}
</style>
