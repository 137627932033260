class Workforce {
    constructor(object) {
        const workforce = object.workforce || object

        Object.keys(workforce).forEach(key => {
            this[key] = workforce[key]
        })
        this.listingId = workforce.id
        this.category = {
            id: workforce.category,
            name: workforce.categoryName ? workforce.categoryName : ''
        }
        this.subcategory = {
            id: workforce.subcategory,
            name: workforce.subcategoryName ? workforce.subcategoryName : ''
        }
    }
}

export default Workforce
